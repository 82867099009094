export const SIMILAR_SEARCH_CRITERIA = {
    SKILLS: 'preferred_skills',
    LOCATION: 'current_location',
    EDUCATION: 'education_history',
    EMPLOYMENT: 'employment_history'
}

export const CANDIDATE_SEARCH_FILTERS = {
    SKILLS: 'skills',
    LOCATIONS: 'locations',
    JOB_TYPES: 'job_types',
    PREFERENCES: 'preferences',
    SALARY_ID: 'salary_id',
    MIN_SALARY: 'min_salary',
    MAX_SALARY: 'max_salary',
    MIN_EXP: 'min_exp',
    MAX_EXP: 'max_exp',
    START_DATE: 'start_date',
    END_DATE: 'end_date',
    LAYOFF: 'layoff',
    REBOOT: 'herCareerReboot',
    DIFF_ABLED: 'differentlyAbled',
    FORCES: 'armedForces',
    EEO: 'eeo',
    EDUCATION: 'education',
    EMPLOYMENT: 'employment',
    GENDER: 'gender',
    PASSING_YEAR: 'passing_year',
}

export const ORDER_BY = {
    NEWEST: "MOST_RECENT",
    OLDEST: "POST_DATE",
    RELEVANT: "MOST_RELEVANT"
}

export const ORDER_BY_TEXT = {
    NEWEST: "Newest First",
    OLDEST: "Oldest First",
    RELEVANT: "Most Relevant"
}

export const JOB_SEARCH_FILTERS = {
    SKILLS: 'skills',
    LOCATIONS: 'locations',
    JOB_TYPES: 'job_types',
    PREFERENCES: 'preferences',
    SALARY_ID: 'salary_id',
    MIN_SALARY: 'min_salary',
    MAX_SALARY: 'max_salary',
    DURATIONS: 'durations',
    SALARY_TYPES: 'salary_types',
    DOMAINS: 'domains',
    MIN_EXP: 'min_exp',
    MAX_EXP: 'max_exp',
    TRAVEL: 'travel_percent',
    EEO: 'eeo',
    START_DATE: 'start_date',
    END_DATE: 'end_date',
}

export const GIG_SEARCH_FILTERS = {
    SKILLS: 'skills',
    LOCATIONS: 'locations',
    GIG_TYPES: 'gig_types',
    MIN_BUDGET: 'min_budget',
    MAX_BUDGET: 'max_budget',
    START_DATE: 'start_date',
    END_DATE: 'end_date',
}

export const SEARCH_FILTERS = {
    SEARCH_TYPE: 'searchType',
    SMART: 'smart',
    KEYWORD: 'keyword'
}