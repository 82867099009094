import React, { useState, useCallback, useMemo, useEffect } from 'react'

import { Calendar, Views, momentLocalizer, DateLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import moment from 'moment'
import Text from "../../_widgets/Text/Text"
import * as toast from "@/wrapper/toast";
import { getEvaluationSlots, getEvaluatorCalendar, getEvaluatorSlots } from '@/services/apiHelper'
import { dateTimeFormatForApi } from '@/utils/dateUtil'
import { formatAndAddDate, momentResult, timeFormat } from '../../../utils/dateUtil'
import Loader from '../../_widgets/Loader/Loader'

const localizer = momentLocalizer(moment)

const events = [
    {
        id: 0,
        title: 'Board meeting',
        start: new Date(2018, 0, 29, 9, 0, 0),
        end: new Date(2018, 0, 29, 13, 0, 0),
        resourceId: 1,
    },
    {
        id: 1,
        title: 'MS training',
        allDay: true,
        start: new Date(2018, 0, 29, 14, 0, 0),
        end: new Date(2018, 0, 29, 16, 30, 0),
        resourceId: 2,
    },
    {
        id: 2,
        title: 'Team lead meeting',
        start: new Date(2018, 0, 29, 8, 30, 0),
        end: new Date(2018, 0, 29, 12, 30, 0),
        resourceId: [2, 3],
    },
    {
        id: 11,
        title: 'Birthday Party',
        start: new Date(2018, 0, 30, 7, 0, 0),
        end: new Date(2018, 0, 30, 10, 30, 0),
        resourceId: 4,
    },
]



const MyCalendar = () => {
    const [myEvents, setEvents] = useState(events)
    const [loading, setLoading] = useState(false)

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            const title = window.prompt('New Event name')
            if (title) {
                setEvents((prev) => [...prev, { start, end, title }])
            }
        },
        [setEvents]
    )

    const handleSelectEvent = useCallback(
        (event) => window.alert(event.title),
        []
    )

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
            defaultDate: new Date(),
            scrollToTime: new Date("2023-09-06T7:00:00"),
        }),
        []
    )

    const [date, setDate] = useState(new Date())

    const getCalendarData = async () => {

        const startDate = momentResult(date).subtract(1, 'days')

        const endDate = formatAndAddDate(view === "day" ? 1 : 6, "DD-MM-YYYY", date)

        try {
            setLoading(true)

            const res = await getEvaluatorCalendar(`?from_date=${dateTimeFormatForApi(startDate)}&to_date=${dateTimeFormatForApi(endDate)}`);

            if (res.isSuccess) {
                console.log(res.data)
                setEvents(res.data.calendar?.map((item) => ({
                    id: item.id,
                    title: item?.meeting_title,
                    start: new Date(item?.meeting_from_time),
                    end: new Date(item?.meeting_to_time),
                    resourceId: [2, 3],
                })))
            }

        } catch (err) {
            console.log(err)
            const errMsg = err?.response?.data?.message || err?.message || err
            toast.warn(errMsg)
        } finally {
            setLoading(false)
        }


    }

    useEffect(() => {
        getCalendarData()
        getSlots()
    }, [date])

    const [slotData, setSlotData] = useState([])



    const mergeTimeSlots = (timeSlots) => {



        const sortedTimeSlots = timeSlots
            .map((slot) => {
                let [start, end] = slot.split(' - ');
                start = start.trim();
                end = end.trim();
                return { start, end };
            })
            .sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });

        const mergedTimeSlots = [sortedTimeSlots[0]];


        for (let i = 1; i < sortedTimeSlots.length; i++) {
            const currentSlot = mergedTimeSlots[mergedTimeSlots.length - 1];
            const nextSlot = sortedTimeSlots[i];
            const currentEndTime = currentSlot.end;
            const nextStartTime = nextSlot.start;

            if (currentEndTime === nextStartTime) {
                currentSlot.end = nextSlot.end;
            } else {
                mergedTimeSlots.push(nextSlot);
            }
        }
        const csvRows = mergedTimeSlots.map((slot) => { if (slot !== undefined) { return `${slot.start} - ${slot.end}` } })

        return csvRows;
    };



    const getSlots = async () => {
        try {
            setLoading(true)
            const res = await getEvaluatorSlots()

            if (res.isSuccess) {
                if (res?.data?.slots !== null) {

                    let slotsData = res?.data?.slots?.slots

                    const newArr = []

                    var curr = new Date(date); // get current date
                    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                    var last = first + 6; // last day is the first day + 6

                    var firstday = new Date(curr.setDate(first))
                    var lastday = new Date(curr.setDate(last))

                    const sorter = {
                        "sunday": 0,
                        "monday": 1,
                        "tuesday": 2,
                        "wednesday": 3,
                        "thursday": 4,
                        "friday": 5,
                        "saturday": 6,
                        // "sunday": 7
                    }

                    const slotKeys = Object.keys(slotsData)

                    slotKeys.sort(function sortByDay(a, b) {
                        let day1 = a.toLowerCase();
                        let day2 = b.toLowerCase();
                        return sorter[day1] - sorter[day2];
                    });



                    slotKeys?.forEach((key) => {
                        slotData[key] = mergeTimeSlots(slotsData[key])
                    })

                    slotKeys.map((key, index) => {

                        slotData[key]?.map((item) => {


                            let startTime = item?.split("-")[0]?.trim()?.toLowerCase()
                            let endTime = item?.split("-")[1]?.trim()?.toLowerCase()

                            startTime = timeFormat(startTime, "hh:mm A").format("HH:mm:ss")
                            endTime = timeFormat(endTime, "hh:mm A").format("HH:mm:ss")

                            const startDateTime = momentResult(firstday).add(index, 'days').format("YYYY-MM-DD") + "T" + startTime

                            const endDateTime = momentResult(firstday).add(index, 'days').format("YYYY-MM-DD") + "T" + endTime

                            newArr.push({
                                id: index,
                                title: "Available Time",
                                start: new Date(startDateTime),
                                end: new Date(endDateTime)
                            })
                        })
                    })


                    setSlotData(newArr)



                }
            }
        } catch (err) {
            console.log(err)
            const errMsg = err?.response?.data?.message || "Something went wrong"
            toast.warn(errMsg)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSlots()
    }, [])


    const [view, setView] = useState(Views.WEEK)

    return (
        <>
            {
                loading && <Loader />
            }
            <div style={{ minHeight: "70vh" }}>

                <Calendar
                    className='calendar__wrapper mt-3'
                    step={60}
                    timeslots={1}
                    defaultDate={defaultDate}
                    min={new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 7)}
                    defaultView={Views.WEEK}
                    events={[...myEvents, ...slotData]}
                    localizer={localizer}
                    views={{
                        day: true,
                        week: true,
                        month: false,
                        agenda: false,
                        // year: Year
                    }}
                    formats={{
                        dayHeaderFormat: (date, culture, localizer) =>
                            localizer.format(date, 'ddd, DD MMM', culture),
                        dayFormat: (date, culture, localizer) =>
                            localizer.format(date, 'ddd, DD MMM', culture)
                    }}
                    getDrilldownView={(targetDate, currentViewName, configuredViewNames) => {
                        setView(currentViewName)
                    }}
                    // onSelectEvent={handleSelectEvent}
                    // onSelectSlot={handleSelectSlot}
                    // selectable
                    onNavigate={date => {
                        setDate(date)
                    }}
                    scrollToTime={scrollToTime}
                    components={{
                        event: EventComponent
                    }}
                />
            </div>
        </>
    )
}

const EventComponent = ({ event }) => {

    const [isAvailable, setIsAvailable] = useState(event?.title === "Available Time")

    return (
        <div
            style={{
                padding: "10px",
                borderRadius: "8px",
                padding: "10px 5px",
                width: "100%",
                overflowX: "scroll",
                height: "100%",
                overflowY: "scroll",
                background: isAvailable ? "#4287f51A" : "#14bc9a1A",
                scrollbarWidth: "none", // Hide scrollbar in Firefox
                '-ms-overflow-style': 'none', // Hide scrollbar in Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide scrollbar in Webkit browsers
                }
            }}
            className="event"
        >

            <div
                style={{
                    height: "2px",
                    width: "100%",
                    marginBottom: "10px",
                    background: isAvailable ? "#4287f5" : "#14bc9a"
                }}
            >

            </div>
            {/* {
                !isAvailable && <Text whiteSpace={"nowrap"} fontSize={"1em"} color={isAvailable ? "#FFC82C" : "#14bc9a"}>
                    {
                        timeFormat(event.start, "hh:mm A") - timeFormat(event.end, "hh:mm A")}
                </Text>
            } */}
            <Text
                type="h2"
                style={{
                    marginTop: "10px",
                    // paddingBottom: "30px",
                    fontSize: "0.75em",
                    fontWeight: 500,
                    color: "#000000",
                    // textTransform: "capitalize"
                }}
                text={event.title}
            />


            {/* <strong>{event.start}</strong> */}

        </div>
    )
}

export default MyCalendar