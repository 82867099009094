

import React, { useState, useEffect, useRef } from "react";
import "./Steps.scss";
import * as commonServices from '../../../../services/common';
import defaultAvatar from '../../../../Assets/svgs/default_avatar.svg';
import PhoneInput, {
    isValidPhoneNumber
} from 'react-phone-number-input';
import { COUNTRY } from '../../../../constants/storageConstants';
import Editor from '../../../Common/Editor';

import { LOCATION_THRESHOLD } from '../../../../constants/storageConstants';

import { KEYBOARD_SHORTCUT_TEXT, LOCATION_SUGGESTION } from '../../../../constants/stringConstants';

import * as ApiHelper from "../../../../services/apiHelper";
import * as toast from "../../../../wrapper/toast"
import * as errors from '../../../../constants/stringConstants';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import * as storageConstants from '../../../../constants/storageConstants';
import AddSkill from "./AddSkill";
import mapPinImg from "../../../../Assets/svgs/map_pin.svg";
import { addDataOnFocusOut } from '../../../../services/common';
import { ClearFileValues } from "../../../../utils/fileUtils";
import { jsonToFormData } from "../../../../wrapper/formData";
import { trackCandidateOnboarding, trackEvaluatorOnboarding } from "../../../../helpers/analytics";
import { analyticsConstant } from "../../../../constants/analyticsConstant";
import xSvg from "../../../../Assets/svgs/x.svg"
import Button from "../../../_widgets/Button/Button";
import Input from "../../../_widgets/Input/Input";
import { isNullOrEmpty, stripHTMLTags } from "../../../../utils/stringUtils";
import Text from "../../../_widgets/Text/Text";
import Loader from "../../../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

export default function PersonalDetails({ setUserId, setTncIsOpen, currentStep, setCurrentStep, profile, setProfile, selectedProfile, referralCode, setReferralCode, isHeaderUpdate, setIsHeaderUpdate, setFinal, dataFromResume }) {

    const skillChildRef = useRef();

    useEffect(() => {
        if (dataFromResume !== undefined) {
            fillDataFromResume();
        }

    }, [dataFromResume])

    const fillDataFromResume = () => {
        const { first_name, last_name, email, phone, objective, totalExp } = dataFromResume;

        let tempProfile = { ...profile };

        if (!isNullOrEmpty(first_name)) {
            tempProfile.first_name = first_name;
            setProfile(tempProfile);
        }
        if (!isNullOrEmpty(last_name)) {
            tempProfile.last_name = last_name;
            setProfile(tempProfile);
        }
        if (!isNullOrEmpty(email)) {
            tempProfile.email = email;
            setProfile(tempProfile);
        }
        if (!isNullOrEmpty(phone)) {
            tempProfile.contact = '+91' + phone;
            setProfile(tempProfile);
        }
        if (!isNullOrEmpty(objective)) {
            tempProfile.summary = objective;
            setProfile(tempProfile);
        }
    }

    const [currentLocation, setCurrentLocation] = useState('');
    const [locationSuggestion, setLocationSuggestion] = useState([]);
    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    const [skill, setSkill] = useState([]);
    const [preferred_location, setPreferred_location] = useState([]);
    const [preferredLocationName, setPreferredLocationName] = useState([]);
    const [locSuggestion, setLocSuggestion] = useState([]);
    const [locSuggestionText, setLocSuggestionText] = useState(LOCATION_SUGGESTION);

    let locationRef = useRef();

    useEffect(() => {
        let handler = (event) => {
            if (!locationRef?.current?.contains(event.target)) {
                if (locationSuggestion.length > 0) {
                    onLocationClick(locationSuggestion[0]);
                    document.getElementById('search_location').value = '';
                    setLocationSuggestion([]);
                } else if (profile?.location_id === undefined || profile?.location_id === null || profile?.location_id === "") {
                    setCurrentLocation('');
                }


            }
        }

        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler)
        }

    });

    const isResumeExists = () => {
        return profile?.resume_file && profile?.resume_file_name;
    }

    const isEmailDisabled = () => {
        return profile.is_verified !== undefined && profile.is_verified;
    }
    const onResumeChange = (e) => {
        let file = e.target.files[0];
        let fileName = e.target.files[0].name;
        let tempProfile = { ...profile };
        tempProfile.resume_file = file;
        tempProfile.resume_file_name = fileName;
        setProfile(tempProfile);
    }

    const onAvatarChange = (e) => {
        let file = e.target.files[0];
        let fileUrl = URL.createObjectURL(file);
        let tempProfile = { ...profile };
        tempProfile.avatar = file;
        tempProfile.avatar_location = fileUrl;
        setProfile(tempProfile);
    }

    const resumeDelete = () => {
        ClearFileValues();
        let file = null;
        let fileName = null;
        let tempProfile = { ...profile };
        tempProfile.resume_file = file;
        tempProfile.resume_file_name = fileName;
        setProfile(tempProfile);
    }

    const onChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value,
        });

    }

    const updateSummary = (summary) => {
        // setSummary(summary);
        var tempProfile = { ...profile };
        tempProfile['summary'] = summary;
        setProfile(tempProfile);
    }

    const locationHandler = (text) => {
        setCurrentLocation(text);
        if (text.length > LOCATION_THRESHOLD) {
            commonServices.getLocations(text).then((locations) => {
                setLocationSuggestion(locations);
                if (locations.length < 1) {
                    setLocSuggestionText(errors.NO_LOCATION_FOUND_SUGGESTION);
                }
            });
        } else {
            setLocationSuggestion([]);
            setLocSuggestionText(LOCATION_SUGGESTION);
        }
    }

    const onLocationClick = (location) => {
        setCurrentLocation(location.name + ', ' + location.description);
        var tempProfile = { ...profile };
        tempProfile.location_name = location.name;
        tempProfile.location_id = location.id;
        setProfile(tempProfile);
        setLocSuggestionText('');

        if (![...preferred_location].includes(location.id) && [...preferred_location].length < 1) {
            setPreferred_location([...preferred_location, location.id]);
            setPreferredLocationName([...preferredLocationName, location]);
        }

    }

    const onChangeHandler_location = (text) => {

        if (text.length > LOCATION_THRESHOLD) {
            commonServices.getLocations(text).then((locations) => {
                setLocSuggestion(locations);
            });
        } else {
            setLocSuggestion([]);
        }
    }

    const onPreferredLocationClick = (location, e) => {
        e.preventDefault();
        if (![...preferred_location].includes(location.id)) {
            setPreferred_location([...preferred_location, location.id]);
            setPreferredLocationName([...preferredLocationName, location]);
        }
        document.getElementById('preferred_location').value = '';
        onChangeHandler_location('');

    }

    const del_location = async (e, data) => {
        e.preventDefault();
        var arrow = [...preferred_location];
        var tempPreferredLocationName = [...preferredLocationName];
        for (var i = 0; i < arrow.length; i++) {

            if (parseInt(arrow[i]) === data) {
                arrow.splice(i, 1);
            }
        }
        for (var i = 0; i < tempPreferredLocationName.length; i++) {

            if (parseInt(tempPreferredLocationName[i]?.id) === data) {
                tempPreferredLocationName.splice(i, 1);
            }
        }
        setPreferred_location(arrow);
        setPreferredLocationName(tempPreferredLocationName);
    }

    const isInvalidData = () => {
        let isError = 0;
        // if (profile.avatar === undefined) {
        //     isError = 1;
        //     toast.warn(errors.PHOTO_REQUIRED);
        // } else 
        if (!profile.first_name) {
            isError = 1;
            toast.warn(errors.FIRST_NAME_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.FIRST_NAME_REQUIRED);

        } else if (!profile.last_name) {
            isError = 1;
            toast.warn(errors.LAST_NAME_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.LAST_NAME_REQUIRED);
        } else if (!profile.email) {
            isError = 1;
            toast.warn(errors.EMAIL_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.EMAIL_REQUIRED);
        }
        else if (!profile.contact) {
            isError = 1;
            toast.warn(errors.PHONE_NUMBER_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.PHONE_NUMBER_REQUIRED);
        }
        else if (profile.contact && !isValidPhoneNumber(profile.contact)) {
            isError = 1;
            toast.warn(errors.PHONE_NUMBER_INCORRECT);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.PHONE_NUMBER_INCORRECT);
        } else if (!profile.password) {
            isError = 1;
            toast.warn(errors.PASSWORD_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.PASSWORD_REQUIRED);
        } else if (!commonServices.isValidPassword(profile.password)) {
            isError = 1;
            toast.warn(errors.INVALID_PASSWORD);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.INVALID_PASSWORD);
        } else if (!profile.confirm_password) {
            isError = 1;
            toast.warn(errors.CONFIRM_PASSWORD_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.CONFIRM_PASSWORD_REQUIRED);
        } else if (profile.password !== profile.confirm_password) {
            isError = 1;
            toast.warn(errors.PASSWORD_CONFIRM_PASSWORD_MATCH);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.PASSWORD_CONFIRM_PASSWORD_MATCH);
        } else if (selectedProfile === "candidate" && !profile.location_name) {
            isError = 1;
            toast.warn(errors.LOCATION_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.LOCATION_REQUIRED);
        } else if (!profile.summary || stripHTMLTags(profile.summary).length <= 0) {
            isError = 1;
            toast.warn(errors.PROFESSIONAL_SUMMARY_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.PROFESSIONAL_SUMMARY_REQUIRED);
        } else if (skill.length < 1) {
            isError = 1;
            toast.warn(errors.SKILLS_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_ERROR, errors.SKILLS_REQUIRED);
        } else if (skillChildRef.current.isInvalidSkills()) {
            isError = 1;
            logEvent(analyticsConstant.VALIDATION_ERROR, "Invalid Skills");
        }

        return isError;
    }

    const register = async () => {
        if (!isInvalidData()) {

            const obj = {
                selectedProfile: selectedProfile,
                first_name: profile.first_name,
                last_name: profile.last_name,
                email: profile.email,
                password: profile.password,
                confirm_password: profile.confirm_password,
                contact: profile.contact,
                contact_number: profile.contact,
                summary: profile.summary,
                location_id: profile.location_id,
                location_name: profile.location_name,
                resume_file: profile.resume_file !== undefined ? profile.resume_file : '',
                avatar: profile.avatar !== undefined ? profile.avatar : '',
                skills: skill,
                preferred_location: JSON.stringify(preferred_location),
                referralCode: referralCode,
            }

            // let formData = new FormData();
            // formData.append('selectedProfile', selectedProfile);
            // formData.append('first_name', profile.first_name);
            // formData.append('last_name', profile.last_name);
            // formData.append('email', profile.email);
            // formData.append('password', profile.password);
            // formData.append('confirm_password', profile.confirm_password);
            // formData.append('contact', profile.contact);
            // formData.append('contact_number', profile.contact);
            // formData.append('summary', profile.summary);
            if (profile.location_id !== undefined) {
                obj['location_id'] = profile.location_id;
            }
            if (profile.location_name !== undefined) {
                obj['location_name'] = profile.location_name;
            }
            // formData.append('resume_file', profile.resume_file !== undefined ? profile.resume_file : '');
            // formData.append('avatar', profile.avatar !== undefined ? profile.avatar : '');
            // formData.append('skills', JSON.stringify(skill));
            // formData.append('preferred_location', JSON.stringify(preferred_location));
            // formData.append("referralCode", referralCode);
            if (profile.id !== undefined) {
                obj["id"] = profile.id;
            }
            setLoading(true);



            const formDataSeralize = jsonToFormData(obj);

            await ApiHelper.candidateRegister(formDataSeralize).then((response) => {
                if (response.isSuccess === true) {
                    // toast.success(response.message);
                    setLoading(false);
                    setProfile(response.data);
                    setUserId(response?.data?.id)
                    if (response.data.is_verified && response.data.token !== undefined) {
                        commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
                        commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.role_type);
                        commonServices.storeLocalData(storageConstants.PROFILE, response.data.profile);
                        setIsHeaderUpdate(!isHeaderUpdate);
                        logEvent(analyticsConstant.STEP4)
                        setCurrentStep(4);
                    } else if (response.data.is_verified) {
                        logEvent(analyticsConstant.STEP3)
                        setCurrentStep(3);
                    } else {
                        logEvent(analyticsConstant.STEP2)
                        setCurrentStep(2);
                    }

                } else {
                    setLoading(false);
                    toast.warn(response.message);
                }
            });
        }
    };


    const triggerClickDown = (event) => {

        if (event.which === 27 || event.keyCode === 27) {

            setLocSuggestion([]);
        } else if (event.which === 9 || event.keyCode === 9) {
            if (event.target.name === 'locations') {
                addDataOnFocusOut(locSuggestion, setLocSuggestion, setPreferred_location, setPreferredLocationName);
                event.target.value = '';
            }
        }
    }

    const triggerSelect = (event) => {
        // const list = [...document.querySelectorAll('.sugg_p')];
        const active = document.querySelector('.sugg_p.active');
        // let i = list.indexOf(active);

        if (event.which === 13 || event.keyCode === 13) {
            // const c = document.getElementsByClassName('sugg_p hand-hover');

            if (active) {
                active.click();
            }
        }

    }

    const handleKeyPress = (event, key) => {

        if (key === "location") {
            if (event.key === 'Enter' || event.key === 'Tab') {
                if (locationSuggestion && locationSuggestion.length > 0) {
                    onLocationClick(locationSuggestion[0]);
                    document.getElementById('search_location').value = '';
                    setLocationSuggestion([]);

                } else if (profile?.location_id === undefined || profile?.location_id === null || profile?.location_id === "") {
                    setCurrentLocation('');
                }
            }

            if (event.key === "Escape") {
                setLocationSuggestion([]);
            }

        }

        if (key === "preferred_location") {
            if (event.key === 'Enter' || event.key === 'Tab') {
                if (locSuggestion && locSuggestion.length > 0) {
                    onPreferredLocationClick(locSuggestion[0]);

                    setLocSuggestion([]);
                }
            }

            if (event.key === "Escape") {
                setLocSuggestion([]);
            }
        }


    }


    const logEvent = (step, data) => {
        switch (selectedProfile) {
            case "candidate":
                trackCandidateOnboarding(step, data);
                break;
            case "evaluator":
                trackEvaluatorOnboarding(step, data);
                break;
            default:
                break;
        }
    }


    return (

        <div className="personal-details reigstration-section steps">
            {loading ? (
                <Loader />
            ) : null}

            <div className="registration-section-header">
                <Text type="h2" text={`${selectedProfile.toUpperCase()} - Personal Details`}>
                </Text>
                <Button buttonType="primary" text="Next" onClick={(e) => register()} />
            </div>

            {/* Resume upload component/section start */}
            <div className="resume-upload-section">
                <div className="row">
                    <div className="col-8 col-md-10 d-flex flex-md-column flex-row justify-content-md-center justify-content-start">
                        <div className="row gy-2">
                            <div className="col-12 col-md-6">
                                {isResumeExists() ?
                                    <div className='resume-filename' style={{ display: 'flex', gap: "8px" }}>
                                        <img onError={commonServices.imgError} src="./Assets/images/file_text.png?1" alt="" />
                                        <div className='file-name-upload'>
                                            <a href={undefined}>{profile?.resume_file_name}</a>
                                        </div>
                                    </div> : null}
                            </div>
                            <div className="col-12 col-md-6">

                                <div className='file-name-upload'>
                                    <input className="upload-input" type='file' accept="image/*, .doc, .pdf, .docx, .ppt, .pptx" onChange={(e) => onResumeChange(e)} />
                                    <img onError={commonServices.imgError} src="./Assets/svgs/upload.svg" alt="" />{isResumeExists() ? "Update resume" : "Upload resume"}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-4 col-md-2 text-end">
                        {isResumeExists() ?
                            <a className='delete-button' onClick={(e) => resumeDelete()}>
                                <img className='' src='../Assets/svgs/trash-white-bg.svg'>
                                </img></a> : null}
                    </div>

                </div>


            </div>

            {/* Resume upload component/section ends */}

            {/* <div className="upload_cv mt-3">
                {isResumeExists() ? <div className='upload_r utxt'>
                    <img onError={commonServices.imgError} src="./Assets/images/file_text.png?1" alt="" />
                    <div className='upload_resume'>
                        <a href={undefined}>{profile?.resume_file_name}</a>
                    </div>
                </div> : null}

                <div className='upload_r'>
                    <div className='upload_resume '>
                        <input type='file' accept="image/*, .doc, .pdf, .docx, .ppt, .pptx" onChange={(e) => onResumeChange(e)} />
                        <img onError={commonServices.imgError} src="./Assets/svgs/upload.svg" alt="" />{isResumeExists() ? "Update resume" : "Upload resume"}
                    </div>
                </div>
                <div className='upload_r'>
                    <div className='upload_resume '>
                        {isResumeExists() ? <a className='deleteIcon hand-hover' onClick={(e) => resumeDelete()}> <img className='' src='../Assets/svgs/trash-white-bg.svg'></img></a> : null}
                    </div>
                </div>
            </div> */}

            <div className="profile-upload-section">
                <div className="profile-upload-img">
                    <img
                        onError={commonServices.imgError}
                        src={profile.avatar_location ?? defaultAvatar} alt="" />
                </div>
                <div className="profile-upload-input">
                    <input type='file' accept='image/*' onChange={(e) => onAvatarChange(e)} />
                    <p>Upload photo</p>

                </div>

            </div>

            {/* <div className="d-flex avatar">
                <div className="profile_img img-border">
                    <img onError={commonServices.imgError} src={profile.avatar_location ?? defaultAvatar} alt="" />
                </div>
                <div className='upload_picture'>
                    <input type='file' accept='image/*' onChange={(e) => onAvatarChange(e)} />
                    <p>Upload photo</p>
                </div>
            </div> */}

            <div className="mt-3">
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <Input
                            label="First Name"
                            placeholder="First Name"
                            value={profile.first_name ?? ''}
                            name="first_name"
                            onChange={(e) => onChange(e)}
                            isRequired={true}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Input
                            label="Last Name"
                            placeholder="Last Name"
                            value={profile.last_name ?? ''}
                            name="last_name"
                            onChange={(e) => onChange(e)}
                            isRequired={true}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <Input
                            label="Email"
                            placeholder="Email"
                            value={profile.email ?? ''}
                            name="email"
                            onChange={(e) => onChange(e)}
                            disabled={isEmailDisabled()}
                            readOnly={isEmailDisabled()}
                            isRequired={true}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <Input
                            type="phone"
                            label="Phone Number"
                            placeholder="Phone Number"
                            name="contact"
                            defaultCountry={COUNTRY}
                            value={profile?.contact ?? ''}
                            onChange={(phoneNumber) => {
                                var tempProfile = { ...profile };
                                tempProfile['contact'] = phoneNumber;
                                setProfile(tempProfile);
                            }}
                            isRequired={true}
                        />

                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <Input
                            type="password"
                            placeholder="Password" value={profile.password ? profile.password : ''}
                            name="password" onChange={(e) => onChange(e)} isRequired={true}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={profile.confirm_password ? profile.confirm_password : ''}
                            name="confirm_password"
                            onChange={(e) => onChange(e)}
                            isRequired={true}
                        />
                    </div>
                </div>



                {selectedProfile === 'candidate' ?
                    <div className="row mb-3" ref={locationRef}>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <label className='required' htmlFor="">Current Location </label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><img className={"input-icon"} onError={commonServices.imgError} src={mapPinImg} alt="" /></span>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder="Type to search for location" id="search_location"
                                    value={currentLocation} autoComplete='off' name="location_id"
                                    onChange={(e) => {
                                        locationHandler(e.target.value)
                                        if (e.target.value.length < 1) {
                                            var tempProfile = { ...profile };
                                            tempProfile['location_id'] = '';
                                            tempProfile['location_name'] = '';
                                            setProfile(tempProfile);
                                        }

                                    }}
                                    onKeyDown={(e) => handleKeyPress(e, 'location')}
                                />

                            </div>
                            <div style={{ height: "auto", position: "relative" }}>
                                <div className={locationSuggestion?.length > 0 ? ' suggestion_box active' : "suggestion_box"}>
                                    <div >
                                        {locationSuggestion && locationSuggestion.map((suggestion, i) =>
                                            <p style={{ cursor: 'pointer' }} key={i} onClick={() => { onLocationClick(suggestion); document.getElementById('search_location').value = ''; setLocationSuggestion([]) }}>{suggestion.name}, {suggestion.description}</p>)}
                                    </div>

                                    {
                                        locationSuggestion?.length > 0 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div> : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <label htmlFor="">Preferred Location </label>
                            <div className="input-group mb-1">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><img className={"input-icon"} onError={commonServices.imgError} src={mapPinImg} alt="" /></span>
                                </div>
                                <input type="text" className="form-control" placeholder="Type to search for location" id="preferred_location" autoComplete='off' onChange={(e) => {
                                    onChangeHandler_location(e.target.value)
                                    if (e.target.value.length < 1) {
                                        var tempProfile = { ...profile };
                                        tempProfile['location_id'] = '';
                                        tempProfile['location_name'] = '';
                                        setProfile(tempProfile);
                                        setLocSuggestion([])
                                    }
                                }

                                } onKeyPress={(e) => { triggerSelect(e) }} onKeyDown={(e) => {
                                    triggerClickDown(e)
                                    handleKeyPress(e, 'preferred_location')
                                }} />

                            </div>
                            <div style={{ height: "auto", position: "relative" }}>
                                <div className={locSuggestion?.length > 0 ? ' suggestion_box active' : "suggestion_box"}>
                                    <div >
                                        {locSuggestion && locSuggestion.map((suggestion, i) =>
                                            <p key={i} onClick={(e) => { onPreferredLocationClick(suggestion, e); }}>{suggestion.name}, {suggestion.description}</p>)}
                                    </div>

                                    {
                                        locSuggestion?.length > 0 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div> : null
                                    }
                                </div>
                            </div>
                            <div className="d-flex f_t_box">
                                {preferredLocationName ? <> {preferredLocationName.map((data, k) => {
                                    if (preferred_location.includes(data.id))
                                        return <div className="f_t" key={k}>
                                            <p>{data.name}, {data.description}</p>
                                            <button onClick={(e) => { del_location(e, data.id) }} className="del_jt"><img src={xSvg} alt="" /></button>
                                        </div>

                                })}</> : null}
                            </div>
                        </div>

                    </div> : null}


                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <Input
                            label="Referral Code (Optional)"
                            placeholder="Referral Code"
                            onChange={(e) => {
                                setReferralCode(e.target.value);
                            }}
                            value={referralCode}
                        />
                    </div>
                </div>
                <div className="row mb-3 summary">
                    <div className="col">
                        <Text type="h2" text="Professional Summary" className='required mb-2'></Text>
                        <Text className="p-gray" text="Write 2-4 short sentences that best showcases your experience, knowledge, skills and competencies."></Text>
                        <Editor desc={profile?.summary ?? ''} setDesc={updateSummary} placeholder="Enter Professional Summary" />
                    </div>
                </div>

                <AddSkill skill={skill} setSkill={setSkill} selectedProfile={selectedProfile} ref={skillChildRef} />
            </div>

            <div className="float-end mb-5">
                <Button buttonType="primary" text="Next" onClick={(e) => register()} />

                {/* <div className="col-lg-3 col-md-4 col-sm-9 header-btns"> */}
                {/* <button className="back" onClick={() => setCurrentStep(0)}>Back</button> */}
                {/* <button className="next right" 
                    onClick={(e) => register()}
                    >Next</button> */}
                {/* </div> */}
            </div>

        </div>
    )
}