/* eslint-disable */
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import './ShowJobs.scss';
import Tabs from '../_widgets/Tabs/Tabs';
import AppliedJobs from './TabContent/AppliedJobs';
import SavedJobs from './TabContent/SavedJobs';
import FilterBar from '../_widgets/FIlterBar/FilterBar';
import Text from '../_widgets/Text/Text';
import Loader from "../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ShowJobsLayout({ loading, color, currentTab, APPLIED, SAVED, appliedJobs, savedJobs, activePage, setActivePage, handlePaginationChange, tabsData, FindWork, keywordSeachFilters, setKeywordSeachFilters, searchedLocations, setSearchedLocations, searchedSkills, setSearchedSkills, searchType, setSearchType, keyword, setKeyword }) {

    return (
        <div className="show_jobs main-body">
            <div className="body_section tt-container">
                <div className="search mb-3">
                    <Text text="My Jobs" type="h2" />

                    <FilterBar searchedSkills={searchedSkills} setSearchedSkills={setSearchedSkills} searchedLocations={searchedLocations} setSearchedLocations={setSearchedLocations} FindWork={FindWork} searchType={searchType} setSearchType={setSearchType} keyword={keyword} setKeyword={setKeyword} searchBar={"normal"} module={"Jobs"} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />
                </div>

                <div className="result_section">
                    <div>
                        <div className="filter_tab ">
                            <Tabs tabs={tabsData} />
                        </div>
                        <div className="col">
                            <div className="tab-content" id="nav-tabContent">
                                {loading ?
                                    <Loader /> : null}
                                <div
                                    className="d-flex align-items-start justify-content-start"

                                >
                                    {
                                        (currentTab === APPLIED) && <div className={"tab-pane fade show active"} id="list-applied-jobs" role="tabpanel" aria-labelledby="list-applied-jobs-list">
                                            <AppliedJobs appliedJobs={appliedJobs} activePage={activePage} setActivePage={setActivePage} handlePaginationChange={handlePaginationChange} />
                                        </div>
                                    }

                                    {
                                        currentTab === SAVED && <div className={"tab-pane fade show active"} id="list-saved-jobs" role="tabpanel" aria-labelledby="list-saved-jobs-list">
                                            <SavedJobs savedJobs={savedJobs} activePage={activePage} setActivePage={setActivePage} handlePaginationChange={handlePaginationChange} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowJobsLayout;
