/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import './UserProfile.scss';
import * as storageConstants from '@/constants/storageConstants';

import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import viewResume from '@/Assets/svgs/view_resume.svg';
import * as ApiHelper from '@/services/apiHelper';
import { APP_NAME } from '@/constants/storageConstants';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as commonServices from '@/services/common';
import * as toast from "@/wrapper/toast";
import { isNullOrEmpty, textCapitalise } from "@/utils/stringUtils";
import ReadMoreText from '../Common/ReadMoreText';
import Text from '../_widgets/Text/Text';
import Loader from '../_widgets/Loader/Loader';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function UserProfile() {

  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");
  const [profile, setProfile] = useState(commonServices.getLocalData(storageConstants.PROFILE));
  const [WorkProfile, setWorkProfile] = useState(profile.user_work_profile);
  const [workProfileDetails, setworkProfileDetails] = useState(profile.user_work_profile_detail);
  const [skillData, setSkillData] = useState([]);
  // const masterData = commonServices.getLocalData(storageConstants.DATA);

  const [experience, setExperience] = useState([]);
  const [degree, setDegree] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [award, setAward] = useState([]);
  const [skills, setSkills] = useState([]);




  const setProfileDetails = async () => {

    setWorkProfile(profile.user_work_profile);
    setworkProfileDetails(profile.user_work_profile_detail);
    for (var i = 0; i < workProfileDetails.length; i++) {

      var isExperienceExist = 0;
      var isDegreeExist = 0;
      var isCertificateExist = 0;
      var isAwardExist = 0;
      var isSkillExist = 0;
      if (workProfileDetails[i].type.localeCompare('experience') === 0) {
        if (experience.some(exp => exp.id === workProfileDetails[i].id)) {
          isExperienceExist = 1;
          break;
        }
        if (isExperienceExist === 0) {
          var arr = experience;
          await setExperience([]);
          arr.unshift(workProfileDetails[i]);
          setExperience(arr);
        }

      } else if (workProfileDetails[i].type.localeCompare('degree') === 0) {
        if (degree.some(deg => deg.id === workProfileDetails[i].id)) {
          isDegreeExist = 1;
          break;
        }
        if (isDegreeExist === 0) {
          var arr1 = degree;
          await setDegree([]);
          arr1.unshift(workProfileDetails[i]);
          setDegree(arr1);
        }

      } else if (workProfileDetails[i].type.localeCompare('certificate') === 0) {
        if (certificate.some(certi => certi.id === workProfileDetails[i].id)) {
          isCertificateExist = 1;
          break;
        }
        if (isCertificateExist === 0) {
          var arr2 = certificate;
          await setCertificate([]);
          arr2.unshift(workProfileDetails[i]);
          setCertificate(arr2);
        }

      } else if (workProfileDetails[i].type.localeCompare('award') === 0) {
        if (award.some(a => a.id === workProfileDetails[i].id)) {
          isAwardExist = 1;
          break;
        }
        if (isAwardExist === 0) {
          var arr3 = award;
          await setAward([]);
          arr3.unshift(workProfileDetails[i]);
          setAward(arr3);
        }

      } else if (workProfileDetails[i].type.localeCompare('skill') === 0) {
        if (skills.some(skill => skill.id === workProfileDetails[i].id)) {
          isSkillExist = 1;
          break;
        }
        if (isSkillExist === 0) {
          var arr4 = skills;
          await setSkills([]);
          arr4.unshift(workProfileDetails[i]);
          setSkills(arr4);
        }

      }

    }

  }

  const getLatestProfile = async () => {
    setLoading(true);
    await ApiHelper.getProfile().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        // if(response.data.userWorkProfileDetail && response.data.userWorkProfileDetail.skill){
        //     var skillNotFound = commonServices.syncSkillData(response.data.userWorkProfileDetail.skill);
        //     if(typeof skillNotFound !== 'undefined'){
        //         commonServices.getSkills().then((skills) => {
        //             setSkillData(skills);
        //             setSkills(response.data.userWorkProfileDetail.skill);
        //         });      
        //     }
        // }
        setProfile(response.data);
        commonServices.storeLocalData(storageConstants.PROFILE, response.data);

      } else {
        toast.warn(response.message);
      }
    });
  }

  const getSkills = () => {
    ApiHelper.getSkills().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setSkillData(response.data);
      }
      else {
        console.log('error');
      }
    });
  }

  useEffect(() => {
    getLatestProfile();
    getSkills();
  }, []);

  useEffect(() => {
    setProfileDetails();
  }, [profile]);

  const componentRef = useRef();

  // const printPage = () => {
  //   var content = document.getElementById("viewPage");

  //   var pri = document.getElementById("ifmcontentstoprint").contentWindow;
  //   pri.document.open();
  //   pri.document.write(content.innerHTML);
  //   pri.document.close();
  //   pri.focus();
  //   pri.print();
  // }

  const sortArray = (array) => {

    return array.sort(function (a, b) {
      var keyA = new Date(a.from_date),
        keyB = new Date(b.from_date);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

  }
  useEffect(() => {
    if (Array.isArray(degree) && degree.length > 0) {
      var arr = degree;
      setDegree([]);
      sortArray(arr);
      setDegree(arr);
    }
  }, [degree])

  useEffect(() => {
    if (Array.isArray(certificate) && certificate.length > 0) {
      var arr = certificate;
      setCertificate([]);
      sortArray(arr);
      setCertificate(arr);
    }
  }, [certificate])

  useEffect(() => {
    if (Array.isArray(experience) && experience.length > 0) {
      var arr = experience;
      setExperience([]);
      sortArray(arr);
      setExperience(arr);
    }
  }, [experience])

  useEffect(() => {
    if (Array.isArray(award) && award.length > 0) {
      var arr = award;
      setAward([]);
      sortArray(arr);
      setAward(arr);
    }
  }, [award])

  return (
    <>
      <div id="viewPage" ref={componentRef}>
        {loading ?
          <Loader /> : null}
        <div className='user_profile'>
          <div className="body_content">
            <div className="left">


              <div className="profile_img_div">
                <div className="profile_img">
                  <img src={profile.avatar_location ?? defaultAvatar} alt="" />
                </div>
                <div className="profile_txt">
                  <p>{APP_NAME} ID: {storageConstants.PREFIX_CANDIDATE_ID}{profile.id}</p>
                  <h2>{profile?.full_name ? textCapitalise(profile?.full_name) : ''}</h2>
                </div>
                {(profile.added_from !== "csv" || profile.created_at !== profile.updated_at) && WorkProfile && typeof WorkProfile.cv_link !== 'undefined' ?
                  <div className='view-resume'>


                    {/* <ReactToPrint
                      trigger={() => <button className='hide-btn' style={{ background: 'transparent', border: '0px' }}><img src={printResume} alt="viewResume" /></button>}
                      content={() => componentRef.current}
                    /> */}
                    {/* <a title='Download Resume' href={urlConstants.BASE_URL + urlConstants.DOWNLOAD_WORKPROFILE + profile.id}> <img src={printResume} alt="viewResume" /> </a> */}
                    {!isNullOrEmpty(WorkProfile.cv_link) ? <a title='View Resume' className='hide-btn d-flex ' style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", textDecoration: "none", color: "black" }} href={WorkProfile.cv_link} target='_blank'> <img src={viewResume} alt="viewResume" /> <p>View Resume</p> </a> : null}


                  </div>
                  : null}

              </div>

              <div className="details">

                <ul>
                  <li><img src="../Assets/svgs/email.svg" alt="" /><p>{profile.email}</p></li>
                  <li><img src="../Assets/svgs/phone.svg" alt="" /><p>{profile.user_work_profile ? profile.user_work_profile.contact_number : ""}</p></li>


                  <li title='Current Location'><img src="../Assets/svgs/marker.svg" alt="" /><p>


                    {(profile.user_work_profile && profile.user_work_profile.location_name) ? profile.user_work_profile.location_name : ''}


                  </p></li>

                  <li title='Preferred Locations'><img src="../Assets/svgs/marker.svg" alt="" /><p>

                    {profile.preferred_location_data ? profile.preferred_location_data : 'N/A'}


                  </p></li>
                </ul>
                <p>
                  <ReadMoreText text={WorkProfile ? WorkProfile.summary : ""} />
                </p>

              </div>

              <div className="work_details">
                <div className="w_sec">
                  <Text text="Work Experience" type="h2"></Text>
                  <hr />

                  {experience && sortArray(experience).map((element, key) => {
                    return <div key={key} className='w_det'>

                      <h2>{(element.from_date).split('/')[2]} – {(element.to_date !== null) ? (element.to_date).split('/')[2] : 'Present'} — {element.title}</h2>
                      <p>
                        {element.awarded_by}
                      </p>
                      <p dangerouslySetInnerHTML={{ __html: element.description }}>
                      </p>
                    </div>
                  })}


                </div>



                <div className="w_sec">
                  <Text type="h2" text="Education"></Text>
                  <hr />

                  {degree && sortArray(degree).map((element, key) => {
                    return <div key={key} className='w_det'>

                      <h2>{element.from_date !== null ? (element.from_date).split('/')[2] : ''} {(element.to_date !== null) ? ' - ' + (element.to_date).split('/')[2] : 'Now'} — {element.title}</h2>
                      <p>
                        {element.awarded_by}
                      </p>
                      <p dangerouslySetInnerHTML={{ __html: element.description }}>
                      </p>
                    </div>
                  })}


                </div>

                <div className="work_details">
                  <div className="w_sec">
                    <Text type="h2" text="Certifications"></Text>
                    <hr />

                    {certificate && sortArray(certificate).map((element, key) => {
                      return <div key={key} className='w_det'>

                        <h2>{(element.from_date).split('/')[2]}  — {element.title}</h2>
                        <p>
                          {element.awarded_by}
                        </p>
                        <p dangerouslySetInnerHTML={{ __html: element.description }}>
                        </p>
                      </div>
                    })}


                  </div>
                </div>

                <div className="w_sec">
                  <Text type="h2" text="Awards and Accomplishments"></Text>
                  <hr />

                  {award && sortArray(award).map((element, key) => {
                    return <div key={key} className='w_det'>

                      <h2>{(element.from_date).split('/')[2]}  — {element.title}</h2>
                      <p>
                        {element.awarded_by}
                      </p>
                      <p dangerouslySetInnerHTML={{ __html: element.description }}>
                      </p>
                    </div>
                  })}

                </div>
              </div>






            </div>
            <div className="right">

              <div className="skills">
                <Text type="h2" text="Skill Summary"></Text>
                <hr />
                <div className="skill_det">
                  {skills && skillData && skillData !== null && skills.map((element, key) => {
                    for (var i = 0; i < skillData.length; i++) {
                      if ((skillData)[i].id === element.skill_id) {
                        return <div key={key} className='progress-div'>
                          <div className="progress-name">
                            <p>{skillData[i].name}</p>
                            <span>{((profile.added_from !== "csv" || profile.created_at !== profile.updated_at) && element.experience > 0) ? <>({element.experience <= 0 ? 'Fresher' : ''}{Math.floor(element.experience / 12) > 0 ? Math.floor(element.experience / 12) + ' years' : ''}{element.experience % 12 > 0 ? ' ' + element.experience % 12 + ' months' : ''})</> : ''}</span>
                          </div>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      }
                    }
                  })
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;