import React from 'react'
import DatePicker from "react-datepicker";
import * as commonServices from "../../services/common";
import IconResource from '../_widgets/IconResource/IconResource';
import * as storageConstants from '@/constants/storageConstants';

const YearPicker = (props) => {

    const {
        dateRef,
        selected,
        onChange } = props

    const currentDate = new Date();
    const sixtyYearsAgo = new Date();
    sixtyYearsAgo.setFullYear(currentDate.getFullYear() - 60);
    return (
        <div className='form-input-group w-100'>
            
            <div className="input-group">
                <span className="input-group-text" id="basic-addon1"><IconResource color="var(--green)" fontSize={"20px"} onError={commonServices.imgError} type="calendar" /></span>
                <DatePicker
                    ref={dateRef}
                    selected={selected}
                    onChange={onChange}
                    dateFormat={"yyyy"}
                    placeholderText={"YYYY"}
                    showYearPicker
                    yearDropdownItemNumber={storageConstants.YEARS_TOTAL} // Optional: Limit dropdown years
                    scrollableYearDropdown={true} // Optional: Make the dropdown scrollable
                    minDate={sixtyYearsAgo} // Earliest date: 60 years ago
                    maxDate={currentDate} // Latest date: Current date
                />
            </div>
        </div>

    )
}

export default YearPicker