import { locations } from "@/utils/arrayUtils";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import ModalJobApply from "@Components/Modals/ModalJobApply";
import ModalJobReport from "@Components/Modals/ModalJobReport";
import ModalShare from "@Components/Modals/ModalShare";
import ModalJobClose from "@Components/Modals/ModalJobClose";
import emptyResult from "@/Assets/svgs/empty_result.svg";
import { CURRENCY, REMOTE } from "@/constants/storageConstants";
import { redirectBack } from "@/utils/redirectUtil";
import arrowLeft from "@/Assets/svgs/arrow-left.svg";
import { createSlugFromString, valueOrDefault } from "@/utils/stringUtils";
import { isMyJob, isClosedJob, isDraftJob, isAppliedJob, isSavedJob, isReportedJob, maskEmailPhone, isOpenJob } from "@/utils/job";
import { isCandidate } from "@/utils/authUtil";
import { getSkillsString, highlightText, textCapitalise } from "../../utils/stringUtils";
import Text from "../_widgets/Text/Text";
import Button from "../_widgets/Button/Button";
import AppModal from "../Modals/AppModal";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import Link from "../_widgets/Link/Link";
import { isFeaturedJob } from "../../utils/job";
import { GIG_ALREADY_FEATURED } from "../../constants/stringConstants";
import * as toast from "../../wrapper/toast";
import { useRef } from "react";
import { addCandidateToSaveLater, getCandidateExperienceText } from "../../services/common";
import ModalShowSimilarCandidates from "../Modals/ModalShowSimilarCandidates";
import Loader from "../_widgets/Loader/Loader";

function GigDetailsLayout({ loading, color, keyword, commonServices, changeApplicantStatus, duplicateGig, showModalApply, setShowModalApply, gigDetail, showModalReport, setShowModalReport, showModalClose, setShowModalClose, showModalShare, setShowModalShare, navigate, gigDetails, currentUser, openReportModal, saveGig, openApplyModal, reNewGig, openShareModal, closeGig, showFeatureGigModal, featureGigDuration, setShowFeatureGigModal, setFeatureGigDuration, buttonLoading, submitFeatureGig, disableFeatureGigButton, masterData, setLoading, selectedCriterias, setSelectedCriterias, showSimilarCandidatesModal, setShowSimilarCandidatesModal, isSimilarCriteriaChecked, showSimilarSearch,
}) {

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;


  const featureGigOptions = masterData?.featured_days?.map(featuredDay => ({
    name: featuredDay.name,
    id: parseInt(featuredDay.value),
  })) || [];

  const ref = useRef(null);

  const handleClick = (tab = "") => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    if (tab !== "") {
      document.getElementById("list-" + tab + "-list").click();
    }
  };

  return gigDetails ? (
    <div
      className="apply_page tt-container"
      style={{
        minHeight: "100vh",
      }}>
      {loading ? (
        <Loader />
      ) : null}

      <div className="body_content">
        <div className="sec_one">
          <ModalJobApply
            showModal={showModalApply}
            setShowModal={setShowModalApply}
            jobDetail={gigDetail}
            job_name={gigDetails.title}
            job_id={gigDetails.eid}
            type={"gig"}
          />
          <ModalJobReport
            showModal={showModalReport}
            setShowModal={setShowModalReport}
            jobDetail={gigDetail}
            job_name={gigDetails.title}
            job_id={gigDetails.eid}
            type={"gig"}
          />
          <ModalJobClose
            showModal={showModalClose}
            setShowModal={setShowModalClose}
            jobDetail={gigDetail}
            job_name={gigDetails.title}
            job_id={gigDetails.eid}
            type={"gig"}
          />
          <ModalShare
            showModal={showModalShare}
            setShowModal={setShowModalShare}
            title={gigDetails.title}
            url={window.location.href}
          />

          {/* Feature Gig Modal */}
          <AppModal
            title='Feature Gig on Search Pages'
            isOpen={showFeatureGigModal}
            isFooter={true}
            loading={buttonLoading}
            submitText='Feature'
            submit={() => submitFeatureGig(gigDetails.eid, featureGigDuration)}
            closeModal={() => {
              setShowFeatureGigModal(false);
              setFeatureGigDuration('');
            }}
            disableSubmit={disableFeatureGigButton()}
          >
            <Dropdown
              label='Feature Gig Duration'
              name="featureGigDuration"
              id="featureGigDuration"
              isRequired={true}
              showDefaultOption={true}
              onChange={e => setFeatureGigDuration(e.target.value)}
              select={featureGigOptions}
            />

            <div className='cash-summary my-4'>
              {disableFeatureGigButton() ?
                <div>
                  <div className="col-12">
                    <h6 className='available-cash'>Total Available TT Cash: {commonServices.currencyFormatter(gigDetails?.user_remaining_amount)}</h6>
                  </div>

                  <div className='d-flex align-items-center gap-2'>
                    <div className='w-auto unavailable-cash'>
                      <h6>You need {commonServices.currencyFormatter(featureGigDuration * masterData.system_config.searchpage_feature_gig_slot_price.value)} TT cash for this Referral Boost</h6>
                    </div>

                    <div>
                      <a href="?action=buy" style={{ fontSize: "var(--link)", fontWeight: "500", color: "var(--green)", textDecoration: "underline", marginLeft: "10px" }}>Buy/Allocate</a>
                      <a href={window.location.href} style={{ fontSize: "var(--link)", fontWeight: "500", color: "var(--green)", textDecoration: "underline", marginLeft: "10px" }}>Refresh</a>
                    </div>
                  </div>
                  <br /><br />
                  <h6>For buying Featured Home Page Slots, please <a href="/company/users?tab=featured-gigs" target="_blank">Click Here</a></h6>
                </div>
                :
                <>
                  <div className='row'>
                    <div className="col-12">
                      <h6 className='available-cash'>Total Available TT Cash: {gigDetails?.user_remaining_amount}</h6>
                    </div>
                    <div className='col-12'>
                      {featureGigDuration ? <h6 className='available-cash'>TT Cash you will spend in this Referral Boost: {featureGigDuration * masterData.system_config.searchpage_feature_gig_slot_price.value}</h6> : null
                      }
                    </div>
                  </div>
                  <br /><br />
                  <h6>For buying Featured Home Page Slots, please <a href="/company/users?tab=featured-gigs" target="_blank">Click Here</a></h6>
                </>
              }
            </div>
          </AppModal>

          {/* View-similar Candidates Modal */}
          <ModalShowSimilarCandidates
            showSimilarCandidatesModal={showSimilarCandidatesModal}
            setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
            showSimilarSearch={showSimilarSearch}
            setSelectedCriterias={setSelectedCriterias}
            selectedCriterias={selectedCriterias}
            isSimilarCriteriaChecked={isSimilarCriteriaChecked}
          />

          <div className="d-flex align-items-center mb-2 justify-content-start"
          >
            <a className="hand-hover" onClick={() => redirectBack()}>

              <img className="back-arrow" src={arrowLeft}></img>
            </a>
            <img
              style={{
                width: "auto",
                maxWidth: "200px",
                height: "3.5em",
                objectFit: "contain",
              }}
              className="logo-microsoft"
              src={gigDetails.company?.logo}
              alt=""
            />
          </div>
          <div
            className="d-flex w-100 align-items-start justify-content-between mb-2 flex-wrap"
            style={{
              paddingLeft: "0px",
            }}>
            <div className="sec_one_one">
              <Text
                type={"h2"}
                className="logo-content truncate-3"
                text={`${gigDetails?.title}`} />
              {isMyJob(gigDetails) && !isDraftJob(gigDetails) ? (
                <>

                  <p className="logo-para">
                    <Link
                      type={"a"}
                      className={"hand-hover green-text"}
                      style={{ color: "#000", textDecoration: "none" }}
                      aHref={undefined}
                      onClick={() => handleClick("applications")}
                      text={`Applicants (${gigDetails.total_applicants})`}
                    />

                    <i class="bi bi-dot"></i>
                    <Link
                      type={"a"}
                      className={"hand-hover green-text"}
                      style={{ color: "#000", textDecoration: "none" }}
                      aHref={undefined}
                      onClick={() => handleClick("shortlisted")}
                      text={`Shortlisted (${gigDetails?.applicants?.shortlisted.length})`}
                    />
                    <i class="bi bi-dot"></i>
                    <Link
                      type={"a"}
                      className={"hand-hover green-text"}
                      style={{ color: "#000", textDecoration: "none" }}
                      href={undefined}
                      onClick={() => handleClick("rejected")}
                      text={`Rejected (${gigDetails?.applicants?.rejected.length})`}
                    />
                    <i class="bi bi-dot"></i>
                    <Link
                      type={"a"}
                      className={"hand-hover green-text"}
                      style={{ color: "#000", textDecoration: "none" }}
                      href={undefined}
                      onClick={() => handleClick("applications")}
                      text={`New (${gigDetails?.applicants?.applications.length})`}
                    />
                  </p>
                </>
              ) : null}
              <p className="logo-para">
                {gigDetails?.company?.name}
                {gigDetails.company_location ? (
                  <i class="bi bi-dot"></i>
                ) : (
                  ""
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightText(
                      keyword,
                      gigDetails.company_location
                        ? gigDetails.company_location
                        : ""
                    ),
                  }}></span>
                <i class="bi bi-dot"></i>
                {isDraftJob(gigDetails) ? "Drafted" : "Posted"} &nbsp;
                {gigDetails.posted_date}
                <i class="bi bi-dot"></i> Posted By &nbsp;
                {gigDetails?.user?.full_name}
                <i class="bi bi-dot"></i> Updated &nbsp;{gigDetails?.updated_date}

              </p>
            </div>

            <div className="sec_one_two">
              {isMyJob(gigDetails) && !isClosedJob(gigDetails) ? (
                <>
                  <Button
                    buttonType={"primary"}
                    style={{ fontSize: "1.2em", width: "100px" }}
                    onClick={() => {
                      navigate("/gig/edit/" + gigDetails.eid);
                    }}
                    className="btn btn-apply"
                    buttonIcon={
                      <img
                        onError={commonServices.imgError}
                        className="edit"
                        src="../../.././Assets/svgs/edit-job.svg"
                        alt=""
                      />
                    }
                    text={"Edit"}
                  />
                </>
              ) : isCandidate() ? (
                isClosedJob(gigDetails) ? (
                  <Button
                    buttonType={"secondary"}
                    disabled
                    style={{ marginLeft: "10px" }}
                    text={"Closed"}
                  />
                ) : isAppliedJob(gigDetails) ? (
                  <Button buttonType={"secondary"} disabled text={"Applied"} />
                ) : (
                  <>
                    <Button
                      buttonType={"primary"}
                      onClick={() => {
                        openApplyModal();
                      }}
                      text={"Apply Now"}
                    />
                    {/* <button type="button" style={{ marginLeft: '10px' }} className="btn btn-default">Refer a friend</button> */}
                  </>
                )
              ) : !currentUser ? (
                <Button
                  buttonType={"primary"}
                  onClick={() => {
                    navigate(
                      "/?auth=login&redirect=/job/details/" +
                      gigDetails.eid +
                      "/" +
                      createSlugFromString(gigDetails.title)
                    );
                  }}
                  text={"Login to Apply"}
                />
              ) : (
                ""
              )}

              {isMyJob(gigDetails) || isOpenJob(gigDetails) ? (
                <div className="dropdown">
                  <Button
                    style={{
                      border: "solid 1px var(--gray5)",
                      height: "40px",
                      width: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "transparent",
                      borderRadius: "8px",
                      marginLeft: "10px",
                      paddingLeft: "0px",
                    }}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    buttonType={"dropdown"}
                    id="dropdownMenuLink"
                    buttonIcon={<i class="bi bi-three-dots"></i>}
                  />

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink">
                    {!isClosedJob(gigDetails) ? (
                      <>
                        {!isClosedJob(gigDetails) && !isDraftJob(gigDetails) ? (
                          <li
                            onClick={() => {
                              openShareModal();
                            }}>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="collapse"
                              data-bs-target=".navbar-collapse.show"
                              aria-controls="navbarSupportedContent"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              aria-current="page">
                              Share
                            </a>
                          </li>
                        ) : null}
                        {isMyJob(gigDetails) && !isClosedJob(gigDetails) ? (
                          <>
                            {isDraftJob(gigDetails) ? (
                              <li
                                onClick={() => {
                                  closeGig("published");
                                }}>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show"
                                  aria-controls="navbarSupportedContent"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  aria-current="page">
                                  Publish Gig
                                </a>
                              </li>
                            ) : (
                              <>
                                <li
                                  onClick={() => {
                                    reNewGig();
                                  }}>
                                  <a
                                    className="dropdown-item"
                                    data-bs-toggle="collapse"
                                    data-bs-target=".navbar-collapse.show"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    aria-current="page">
                                    Renew Gig
                                  </a>
                                </li>

                                {
                                  !isDraftJob(gigDetails) && !isClosedJob(gigDetails)
                                    ? <li
                                      onClick={() => {
                                        if (isFeaturedJob(gigDetails)) {
                                          toast.warn(GIG_ALREADY_FEATURED);
                                        } else {
                                          setShowFeatureGigModal(true);
                                        }
                                      }}>
                                      <Link
                                        type={"a"}
                                        className={"dropdown-item"}
                                        dataBsToggle={"collapse"}
                                        dataBsTtarget={".navbar-collapse.show"}
                                        ariaControls={"navbarSupportedContent"}
                                        ariaExpanded={"false"}
                                        ariaLabel={"Toggle navigation"}
                                        ariaCurrent={"page"}
                                        text={"Feature Gig"}
                                      />
                                    </li>
                                    : null}

                                <li
                                  onClick={() => {
                                    setShowModalClose(true);
                                  }}>
                                  <a
                                    className="dropdown-item"
                                    style={{ color: "#EA3E3E" }}
                                    data-bs-toggle="collapse"
                                    data-bs-target=".navbar-collapse.show"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    aria-current="page">
                                    Close Gig
                                  </a>
                                </li>
                              </>
                            )}
                          </>
                        ) : null}

                        {isCandidate() && !isClosedJob(gigDetails) ? (
                          <>
                            <li onClick={saveGig}>
                              <a
                                className="dropdown-item"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                aria-current="page">
                                {isSavedJob(gigDetails)
                                  ? "Remove from Favorite"
                                  : "Add to Favorite"}
                              </a>
                            </li>
                            <li
                              onClick={() => {
                                !isReportedJob(gigDetails) && openReportModal();
                              }}>
                              <a
                                className={
                                  isReportedJob(gigDetails)
                                    ? "dropdown-item fade-btn"
                                    : "dropdown-item"
                                }
                                style={{ color: "#EA3E3E" }}
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                aria-current="page">
                                {isReportedJob(gigDetails)
                                  ? "Reported Gig"
                                  : "Report Gig"}
                              </a>
                            </li>
                          </>
                        ) : null}
                      </>
                    ) : null}

                    {isMyJob(gigDetails) ? (
                      <li
                        onClick={() => {
                          duplicateGig();
                        }}>
                        <a
                          className="dropdown-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          aria-current="page">
                          Duplicate Gig
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>

        </div>

        <div className="second-section">
          <div className="left">
            <div className="bgBorderShadow">
              <div className="work-upper">
                <div className="work_exp">
                  <p>Type</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: highlightText(keyword, gigDetails?.type?.name),
                    }}></p>
                </div>

                <div className="work_job">
                  <p>Engagement Mode</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: highlightText(
                        keyword,
                        gigDetails?.engagement_mode_id !== null
                          ? gigDetails?.engagement_mode?.name
                          : "N/A"
                      ),
                    }}></p>
                </div>

                <div className="work_loc">
                  <p>Location</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: highlightText(
                        keyword,
                        gigDetails?.engagement_mode_id !== null &&
                          gigDetails?.engagement_mode?.name === REMOTE
                          ? gigDetails?.engagement_mode?.name
                          : gigDetails?.locations
                            ? locations(gigDetails.locations)
                            : "India"
                      ),
                    }}></p>
                </div>

                <div className="work_auth">
                  <p>Industry Domain</p>
                  <p dangerouslySetInnerHTML={{
                      __html: highlightText(
                        keyword,
                        gigDetails?.industry_domain_id !== null
                          ? gigDetails?.industry_domain?.name
                          : "N/A"
                      ),
                    }}></p>
                </div>
              </div>
            </div>
            <div className="bgBorderShadow">
              <div className="third-section">
                <div className="respon">
                  <Text type={"h2"} text='Skills'></Text>
                  <ul>
                    {gigDetails?.skills &&
                      gigDetails?.skills.map((skill, i) => {
                        return skill !== "" ? (
                          <li key={i}>
                            <span className="circle"></span>
                            <p
                              style={{ paddingBottom: "0px" }}
                              dangerouslySetInnerHTML={{
                                __html: highlightText(keyword, skill?.name),
                              }}></p>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                  </ul>

                  {gigDetails.job_additional_skills &&
                    gigDetails.job_additional_skills.length > 0 &&
                    gigDetails.job_additional_skills[0] !== "" ? (
                    <p>
                      Additional Skills <br />
                    </p>
                  ) : (
                    ""
                  )}
                  <ul>
                    {gigDetails.job_additional_skills &&
                      gigDetails.job_additional_skills.map((skill, i) => {
                        return skill !== "" ? (
                          <li key={i}>
                            <span className="circle"></span>
                            <p
                              style={{ paddingBottom: "0px" }}
                              dangerouslySetInnerHTML={{
                                __html: highlightText(keyword, skill),
                              }}></p>
                          </li>
                        ) : (
                          "All"
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div className="overview">
                <Text type={"h2"} text='No. of positions'></Text>
                <p>{valueOrDefault(gigDetails?.total_positions, 1)}</p>
              </div>

              <div className="overview">
                <Text type={"h2"} text='Description'></Text>
                <p
                  dangerouslySetInnerHTML={{
                    __html: highlightText(keyword, gigDetails.description),
                  }}></p>
              </div>

              {gigDetails.min_budget && gigDetails.max_budget ? (
                <div className="overview">
                  <Text type={"h2"} text='Gig Budget'></Text>

                  <p>
                    {CURRENCY +
                      " " +
                      commonServices.currencyFormatter(gigDetails.min_budget) +
                      " - " +
                      CURRENCY +
                      " " +
                      commonServices.currencyFormatter(gigDetails.max_budget)}
                  </p>
                </div>
              ) : (
                ""
              )}

              <div ref={ref}></div>

              {currentUser && currentUser.id === gigDetails.user_id ? (
                <div className="result_section" id="result_section">
                  <div>
                    <div className="filter_tab ">
                      <div className="list-group" id="list-tab" role="tablist">
                        <Link
                          type={"a"}
                          className={
                            "list-group-item list-group-item-action active"
                          }
                          id="list-applications-list"
                          dataBsToggle={"list"}
                          aHref="#applications-list"
                          role={"tab"}
                          ariaControls={"list-applications"}
                          text={`Applicants
                        (${gigDetails &&
                              gigDetails.applicants &&
                              gigDetails.applicants.applications
                              ? gigDetails.applicants.applications.length
                              : 0})`}
                        />
                        <Link type={"a"}
                          className={"list-group-item list-group-item-action"}
                          id="list-shortlisted-list"
                          dataBsToggle={"list"}
                          aHref={"#shortlisted-list"}
                          role={"tab"}
                          ariaControls={"shortlisted-list"}
                          text={`Shortlisted 
                        (${gigDetails &&
                              gigDetails.applicants &&
                              gigDetails.applicants.shortlisted
                              ? gigDetails.applicants.shortlisted.length
                              : 0})`}

                        />
                        <Link type={"a"}
                          className={"list-group-item list-group-item-action"}
                          id="list-rejected-list"
                          dataBsToggle={"list"}
                          aHref={"#rejected-list"}
                          role={"tab"}
                          ariaControls={"rejected-list"}
                          text={`Rejected 
                        (${gigDetails &&
                              gigDetails.applicants &&
                              gigDetails.applicants.rejected
                              ? gigDetails.applicants.rejected.length
                              : 0})`}
                        />

                      </div>
                    </div>
                    <div className="col">
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active common_items"
                          id="applications-list"
                          role="tabpanel"
                          aria-labelledby="list-applications-list ">
                          {gigDetails &&
                            gigDetails.applicants &&
                            gigDetails.applicants.applications.length > 0 ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Candidate Name</th>
                                  <th scope="col">Total Exp</th>
                                  <th scope="col" colSpan={2}>Skills</th>
                                  <th scope="col">Current Location</th>
                                  {/* <th scope="col">Message to Recruiter</th> */}
                                  <th scope="col">Match %age</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gigDetails.applicants.applications &&
                                  gigDetails.applicants.applications.map(
                                    (applicant, i) => {
                                      return (
                                        <tr key={i} className="results">
                                          <td className="bold">
                                            {applicant.first_name +
                                              " " +
                                              applicant.last_name}
                                          </td>
                                          <td>
                                            {getCandidateExperienceText(
                                              applicant.total_experience
                                            )}
                                          </td>
                                          <td colSpan={2}>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: highlightText(
                                                  keyword,
                                                  textCapitalise(
                                                    applicant.user_skills !== null
                                                      ? getSkillsString(applicant.user_skills)
                                                      : "N/A"
                                                  )
                                                ),
                                              }}
                                            ></p>
                                          </td>
                                          <td className="bold">
                                            {applicant.location_name}
                                          </td>

                                          {/* <td>
                                            {applicant.recruiter_msg !== null
                                              ? applicant.recruiter_msg
                                              : "None"}
                                          </td> */}
                                          {/* <td>
                                            <a
                                              className="view-candidate"
                                              href={`/workprofile/view/${applicant?.user_id}?isApplied=1&gig_id=${gigDetails?.eid}`}
                                              target="_blank"
                                              referrerPolicy="no-referrer"
                                            >
                                              View
                                            </a>
                                          </td> */}
                                          <td>
                                            {parseInt(
                                              applicant?.matchPercentage
                                            ).toFixed(2)}
                                            %
                                          </td>
                                          <td>
                                            <div className="d-flex gap-3">
                                              <Link type={"icon"}
                                                title="View Resume"
                                                onClick={() => {
                                                  window.open(`/workprofile/view/${applicant?.user_id}?isApplied=1&gig_id=${gigDetails?.eid}`);
                                                }}
                                                iconType={"eye"}
                                              />
                                              <Link type={"icon"}
                                                title="Shortlisted"
                                                onClick={() =>
                                                  changeApplicantStatus(
                                                    applicant.user_id,
                                                    "shortlisted"
                                                  )
                                                }
                                                iconType={"thumbUp"}
                                              />
                                              <Link type={"icon"}
                                                title="Rejected"
                                                onClick={() =>
                                                  changeApplicantStatus(
                                                    applicant.user_id,
                                                    "rejected"
                                                  )
                                                }
                                                iconType={"thumbDown"}
                                              />
                                              <Link type={"icon"}
                                                title="Save Candidate"
                                                onClick={() => addCandidateToSaveLater(applicant.user_id, setLoading)}
                                                iconType={"heart"} />
                                              <Link type={"icon"}
                                                title="View Similar Candidate"
                                                onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                iconType={"similar"} />
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          ) : (
                            <div className="no_jobs">
                              <img
                                onError={commonServices.imgError}
                                src={emptyResult}
                                alt=""
                              />
                              <Text type={"h2"} text='No applicants found!'></Text>
                            </div>
                          )}
                        </div>

                        <div
                          className="tab-pane fade common_items"
                          id="shortlisted-list"
                          role="tabpanel"
                          aria-labelledby="list-shortlisted-list ">
                          {gigDetails &&
                            gigDetails.applicants &&
                            gigDetails.applicants.shortlisted.length > 0 ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Candidate Name</th>
                                  <th scope="col">Total Exp</th>
                                  <th scope="col" colSpan={2}>Skills</th>
                                  <th scope="col">Current Location</th>
                                  {/* <th scope="col">Message to Recruiter</th> */}
                                  <th scope="col">Match %age</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gigDetails.applicants.shortlisted &&
                                  gigDetails.applicants.shortlisted.map(
                                    (applicant, i) => {
                                      return (
                                        <tr key={i} className="results">
                                          <td className="bold">
                                            {applicant.first_name +
                                              " " +
                                              applicant.last_name}
                                          </td>
                                          <td>
                                            {getCandidateExperienceText(
                                              applicant.total_experience
                                            )}
                                          </td>
                                          <td colSpan={2}>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: highlightText(
                                                  keyword,
                                                  textCapitalise(
                                                    applicant.user_skills !== null
                                                      ? getSkillsString(applicant.user_skills)
                                                      : "N/A"
                                                  )
                                                ),
                                              }}
                                            ></p>
                                          </td>
                                          <td className="bold">
                                            {applicant.location_name}
                                          </td>
                                          {/* <td>
                                            {applicant.recruiter_msg !== null
                                              ? applicant.recruiter_msg
                                              : "None"}
                                          </td> */}
                                          <td>
                                            {parseInt(
                                              applicant?.matchPercentage
                                            ).toFixed(2)}
                                            %
                                          </td>
                                          <td >
                                            <div className="d-flex gap-3">
                                              <Link type={"icon"}
                                                title="View Resume"
                                                onClick={() => {
                                                  window.open(`/workprofile/view/${applicant?.user_id}?isApplied=1&gig_id=${gigDetails?.eid}`);
                                                }}
                                                iconType={"eye"}
                                              />
                                              <Link type={"icon"}
                                                title="Rejected"
                                                onClick={() =>
                                                  changeApplicantStatus(
                                                    applicant.user_id,
                                                    "rejected"
                                                  )
                                                }
                                                iconType={"thumbDown"}
                                              />
                                              <Link type={"icon"}
                                                title="Save Candidate"
                                                onClick={() => addCandidateToSaveLater(applicant.user_id, setLoading)}
                                                iconType={"heart"} />
                                              <Link type={"icon"}
                                                title="View Similar Candidate"
                                                onClick={() => setShowSimilarCandidatesModal(applicant)}
                                                iconType={"similar"} />
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          ) : (
                            <div className="no_jobs">
                              <img
                                onError={commonServices.imgError}
                                src={emptyResult}
                                alt=""
                              />
                              <Text type={"h2"} text='No shortlisted applicant found!'>
                              </Text>
                            </div>
                          )}
                        </div>

                        <div
                          className="tab-pane fade common_items"
                          id="rejected-list"
                          role="tabpanel"
                          aria-labelledby="list-rejected-list ">
                          {gigDetails &&
                            gigDetails.applicants &&
                            gigDetails.applicants.rejected.length > 0 ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Candidate Name</th>
                                  <th scope="col">Total Exp</th>
                                  <th scope="col">Skills</th>
                                  <th scope="col">Current Location</th>
                                  {/* <th scope="col">Message to Recruiter</th> */}
                                  <th scope="col">Match %age</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gigDetails.applicants.rejected &&
                                  gigDetails.applicants.rejected.map(
                                    (applicant, i) => {
                                      return (
                                        <tr key={i} className="results">
                                          <td className="bold">
                                            {applicant.first_name +
                                              " " +
                                              applicant.last_name}
                                          </td>
                                          <td>
                                            {getCandidateExperienceText(
                                              applicant.total_experience
                                            )}
                                          </td>
                                          <td>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: highlightText(
                                                  keyword,
                                                  textCapitalise(
                                                    applicant.user_skills !== null
                                                      ? getSkillsString(applicant.user_skills)
                                                      : "N/A"
                                                  )
                                                ),
                                              }}
                                            ></p>
                                          </td>
                                          <td className="bold">
                                            {applicant.location_name}
                                          </td>
                                          {/* <td>
                                            {applicant.recruiter_msg !== null
                                              ? applicant.recruiter_msg
                                              : "None"}
                                          </td> */}
                                          <td>
                                            {parseInt(
                                              applicant?.matchPercentage
                                            ).toFixed(2)}
                                            %
                                          </td>
                                          <td>
                                          <div className="d-flex gap-3">
                                            <Link type={"icon"}
                                              title="View Resume"
                                              onClick={() => {
                                                window.open(`/workprofile/view/${applicant?.user_id}?isApplied=1&gig_id=${gigDetails?.eid}`);
                                              }}
                                              iconType={"eye"}
                                            />
                                            <Link type={"icon"}
                                              title="Shortlisted"
                                              onClick={() =>
                                                changeApplicantStatus(
                                                  applicant.user_id,
                                                  "shortlisted"
                                                )
                                              }
                                              iconType={"thumbUp"}
                                            />
                                            <Link type={"icon"}
                                              title="Save Candidate"
                                              onClick={() => addCandidateToSaveLater(applicant.user_id, setLoading)}
                                              iconType={"heart"} />
                                            <Link type={"icon"}
                                              title="View Similar Candidate"
                                              onClick={() => setShowSimilarCandidatesModal(applicant)}
                                              iconType={"similar"} />
                                          </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          ) : (
                            <div className="no_jobs">
                              <img
                                onError={commonServices.imgError}
                                src={emptyResult}
                                alt=""
                              />
                              <Text type={"h2"} text='No rejected applicant found!'>
                              </Text>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* <div className="right mb-2"> */}

          <div className="right mb-2">
            <div className="about-company mb-3">
              <Text type={"h2"} text='About the company'></Text>
              <p>
                <a
                  className="green-text hand-hover"
                  style={{ textDecoration: "none" }}
                  href={undefined}
                  onClick={() =>
                    window.open(
                      "/company-profile/" + gigDetails?.company?.eid,
                      "_blank"
                    )
                  }
                  target="_blank">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlightText(keyword, gigDetails?.company?.name),
                    }}></span>
                  <i
                    class="bi bi-box-arrow-up-right"
                    style={{ marginLeft: "10px" }}></i>
                </a>
              </p>
              <br />
              <p className="para_2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightText(
                      keyword,
                      gigDetails?.company?.description
                    ),
                  }}></span>
                <br />
              </p>
              <br />
              <p className="para_3">
                Website:
                <a
                  target="_blank"
                  href={gigDetails?.company?.website}
                  className="green-txt comp-website">

                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlightText(
                        keyword,
                        gigDetails?.company?.website
                      ),
                    }}></span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="apply_page">
      {loading ? (
        <Loader />
      ) : null}
    </div>
  );
}

export default GigDetailsLayout;