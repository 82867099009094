import React, { useState, useEffect, useRef, useCallback } from 'react'
import './Home.scss'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import * as storageConstants from '@/constants/storageConstants';
import * as commonServices from '@/services/common';
import * as ApiHelper from '@/services/apiHelper';
import ModalApplyJobReferralInfo from '../Modals/ModalApplyJobReferralInfo';

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

import * as toast from "@/wrapper/toast";

import { isIncompleteProfile, isCandidate, isCompany, isCompanyAdmin, getCandidateProfileProgress, getCompanyProfileProgress, isLoggedIn, isPartner } from '@/utils/authUtil';

import { isNotEmpty, shuffleArray } from '@/utils/arrayUtils';
import { consoleLogE } from '@/utils/console';
import Card from '../Common/Card';
import ButtonOutlined from '../Common/ButtonOutlined';

import imgfallback from '@/Assets/images/img_fallback.png'
import SearchFilter from "../Common/SearchFilter"
import StickySearchFilter from "../Common/StickySearchFilter"
import { createSlugFromString } from '@/utils/stringUtils';
import ModalHomeImagePopup from '../Modals/ModalHomeImagePopup';
import { addNavShadow } from '@/utils/styleUtils';
import { MaxWidthContainer } from '../Common/MaxWidthContainer';

import ModalFreeTTCash from '../Modals/ModalFreeTTCash';
import ModalInformation from '../Modals/ModalInformation';
import Confetti from 'react-confetti';
import Text from '../_widgets/Text/Text';
import Link from '../_widgets/Link/Link';
import { isNullOrEmpty } from '../../utils/stringUtils';
import Loader from '../_widgets/Loader/Loader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Home({ setJobSearch, setLocationSearch, setLocationSearchName, type, keywordSearch, setKeywordSearch, searchType, setSearchType, setShowStickySearchBar, isStickySearch, setIsStickySearch, setSearchFilterState, keywordSeachFilters, setKeywordSeachFilters }) {
    const navigate = useNavigate();

    const masterData = commonServices.getLocalData(storageConstants.DATA);

    const user_role = commonServices.getLocalData(storageConstants.USER_ROLE);
    const [selectedSearch, setSelectedSearch] = useState('job');

    const [job_type, setJob_type] = useState([]);
    const [location, setLocation] = useState([]);
    const [locationName, setLocationName] = useState([]);
    const [preferredLocationName, setPreferredLocationName] = useState([]);
    const [preferredSkillName, setPreferredSkillName] = useState([]);
    const [jobLocations, setJobLocations] = useState([]);
    const [featuredJobs, setFeaturedJobs] = useState([]);
    const [homepageLogos, setHomepageLogos] = useState([]);
    const [partnerLogos, setPartnerLogos] = useState([]);
    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    let [showImagePopupModal, setShowImagePopupModal] = useState(false);

    const [popupData, setPopupData] = useState(null);

    const [featuredGigs, setFeaturedGigs] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [showModalApply, setShowModalApply] = useState(false);
    const [referralData, setReferralData] = useState();
    const { state } = useLocation();

    const getData = async () => {

        await Promise.all([
            ApiHelper.getFeaturedJobs().then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    setFeaturedJobs(response.data);
                    let ids = response.data.map(job => {
                        return job?.eid;
                    });
                    addFeatureJobImpression(ids, "impression");
                }
                else {
                    consoleLogE('getFeaturedJobs', response.message);
                    toast.warn(response.message);
                }
            }),

            ApiHelper.getHomepageLogos().then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    setHomepageLogos(response?.data?.companies);
                    setPartnerLogos(response?.data?.partners);
                }
                else {
                    consoleLogE('getHomepageLogos', response.message);
                }
            }),

            ApiHelper.getLocations().then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    setJobLocations(response.data);
                }
                else {
                    consoleLogE('getLocations', response.message);
                }
            }),

            ApiHelper.getFeaturedGigs().then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    setFeaturedGigs(response.data);
                    let ids = response.data.map(gig => {
                        return gig?.eid;
                    });
                    addFeatureGigImpression(ids, "impression");
                }
                else {
                    consoleLogE('getFeaturedGigs', response.message);
                    toast.warn(response.message);
                }
            })
        ])

    }

    useEffect(() => {
        setKeywordSearch("");
        setLoading(false);
        getData();
    }, []);

    useEffect(() => {
        if (state !== null) {
            if (!isNullOrEmpty(state.isreferred?.referral_id)) {
                setReferralData(state.isreferred);
                setShowModalApply(true);
            }
        }
    }, [searchParams])

    const [y, setY] = useState(document.scrollingElement.scrollHeight);

    const handleNavigation = useCallback((e) => {

        if (y > window.scrollY) {
            filterScrollTop();
        } else if (y < window.scrollY) {
            filterScrollDown();
        }
        setY(window.scrollY)
    }, [y]);


    useEffect(() => {


        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };

    }, [handleNavigation]);

    const filterScrollDown = () => {

        const searchElement = document.getElementById('banner_d');
        if (searchElement !== null && searchElement.getBoundingClientRect().top < 0) {
            setShowStickySearchBar(true);
            setIsStickySearch(false);
            addNavShadow();
        }

    }

    const filterScrollTop = () => {
        const searchElement = document.getElementById('banner_d');
        if (searchElement !== null && searchElement.getBoundingClientRect().top > 0) {
            addNavShadow();
            setShowStickySearchBar(false);
            setIsStickySearch(false);
        }

    }

    const [showConfeti, setShowConfeti] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState('');
    const [infoModalHeading, setInfoModalHeading] = useState('');
    const [infoModalText, setInfoModalText] = useState('');
    const [infoModalButtonOneText, setInfoModalButtonOneText] = useState('');
    const [infoModalButtonTwoText, setInfoModalButtontwoText] = useState('');
    const [infoModalButtonOneAction, setInfoModalButtonOneAction] = useState("");
    const [infoModalButtonTwoAction, setInfoModalButtonTwoAction] = useState("");

    const addFeatureJobImpression = (job_id, type = "impression") => {
        if (job_id.length > 0) {
            let formData = new FormData();
            formData.append('job_id', job_id);
            formData.append('page', "homepage");
            formData.append('type', type);
            ApiHelper.addJobImpression(formData).then((response) => {

                if (response.isSuccess === true) {
                    console.log('addJobImpression success');
                }
                else {
                    console.error('addJobImpression error');
                }
            });
        }

    }

    const addFeatureGigImpression = (gig_id, type = "impression") => {
        if (gig_id.length > 0) {
            let formData = new FormData();
            formData.append('gig_id', gig_id);
            formData.append('page', "homepage");
            formData.append('type', type);
            ApiHelper.addGigImpression(formData).then((response) => {

                if (response.isSuccess === true) {
                    console.log('addGigImpression success');
                }
                else {
                    console.error('addGigImpression error');
                }
            });
        }
    }

    let popupTimerRef = useRef(null);

    useEffect(() => {
        if (!searchParams.get('auth')) {
            getPopups();
        }
    }, [state?.auth]);

    const getPopups = async () => {
        if (!searchParams.values.length) {
            const resp = await ApiHelper.getPopup();
            if (resp.isSuccess) {
                if (!popupTimerRef.current) {
                    const { key, duration, text, title, button1Text, button1Action, button2Text, button2Action } = resp?.data?.popup || {};

                    if (key === "HOME") {
                        setShowImagePopupModal(true);
                    } else if (['FREE_TT_CASH_CREDITED', 'NEW_MESSAGE', 'SHOW_ENABLE_AUTO_APPLY', 'SHOW_LOW_BALANCE','FREE_CANDIDATE_VIEW'].includes(key)) {
                        setInfoModalTitle(title);
                        setInfoModalText(text);
                        console.log("button1Text--",typeof button1Text);
                        console.log("button2Text--",typeof button2Text);
                        
                        button1Text && button1Text !== "" && setInfoModalButtonOneText(button1Text);
                        button2Text && button2Text !== "" && setInfoModalButtontwoText(button2Text);
                        setInfoModalButtonOneAction(button1Action);
                        setInfoModalButtonTwoAction(button2Action);

                        setShowInfoModal(true);
                    }

                    // There are three types of popups:
                    // 1. Image with buttons => ModalHomeImagePopup
                    // 2. Job Referral (controls from register) => ModalApplyJobReferralInfo
                    // 3. All other popups are controlled by ModalInformation

                    setPopupData(resp?.data?.popup);
                    popupTimerRef.current = parseInt(duration || 0) * 1000;

                    setTimeout(() => {
                        ['FREE_TT_CASH_CREDITED', 'NEW_MESSAGE', 'SHOW_ENABLE_AUTO_APPLY', 'SHOW_LOW_BALANCE'].includes(key)
                            ? setShowInfoModal(false)
                            : setShowImagePopupModal(false);
                        setPopupData(null);
                        popupTimerRef.current = null;
                    }, (popupTimerRef.current));
                }
            }
        }
    }

    return (
        <>
            <div className="home" >
                {isStickySearch ?
                    <div className='navbar navbar-expand-lg navbar-light sticky-search tt'>
                        <StickySearchFilter setJobSearch={setJobSearch} setKeywordSearch={setKeywordSearch} keywordSearch={keywordSearch} searchType={searchType} setSearchType={setSearchType} setLocationSearch={setLocationSearch} setLocationSearchName={setLocationSearchName} job_type={job_type} setJob_type={setJob_type} location={location} setLocation={setLocation} preferredSkillName={preferredSkillName} setPreferredSkillName={setPreferredSkillName} preferredLocationName={preferredLocationName} setPreferredLocationName={setPreferredLocationName} type={type} setLoading={setLoading} setSearchFilterState={setSearchFilterState} selectedSearch={selectedSearch} setSelectedSearch={setSelectedSearch} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />
                    </div>
                    : null}

                {/* Job Refereral */}
                <ModalApplyJobReferralInfo showModal={showModalApply} setShowModal={setShowModalApply} referredData={referralData} />

                {/* Info Modal */}
                <ModalInformation
                    showModal={showInfoModal}
                    setShowModal={setShowInfoModal}
                    title={infoModalTitle}
                    text={infoModalText}
                    buttonOneText={infoModalButtonOneText}
                    buttonTwoText={infoModalButtonTwoText}
                    buttonOneAction={() => navigate(infoModalButtonOneAction)}
                    buttonTwoAction={() => navigate(infoModalButtonTwoAction)}
                    heading={infoModalHeading}
                    setShowConfetti={setShowConfeti} />

                <div className="bg-color">
                    {isIncompleteProfile() ? <div className="missing-info">
                        {isCandidate() ? <div>
                            <Text type="span" className='text-white' text={`Your work profile is  <span class='text-orange'>${getCandidateProfileProgress()}%</span> complete. A complete profile attracts more companies. &nbsp; `}></Text> <span className='text-white'><a href={undefined} onClick={() => navigate("/workprofile")} className='text-orange hand-hover'>Click here</a> to complete.</span>
                        </div> : isCompanyAdmin()

                            ? (<div>
                                <Text type="span" className='text-white' text={`Your company profile is <span class='text-orange'>${getCompanyProfileProgress()}%</span> complete. A complete profile attracts more candidates. &nbsp;`}></Text> <span className='text-white'>
                                    <a href={undefined} className='text-orange hand-hover' onClick={() => navigate("/company/edit")}>Click here</a> to complete.
                                </span>
                            </div>) : null}
                    </div> : null}

                    <MaxWidthContainer pageKey={type === "gig" ? "gig" : "home"}>
                        <ModalHomeImagePopup showModal={showImagePopupModal} setShowModal={setShowImagePopupModal} popupData={popupData} setPopupData={setPopupData} />
                        <div className="bg-color">
                            {
                                (showConfeti) ? <Confetti
                                    width={window.innerWidth}
                                    height={window.innerHeight}
                                /> : null
                            }
                            {loading ?
                                <Loader /> : null}

                            <div className="bg_green">
                                {/* <div className='bg_green_absolute'></div> */}
                                <div className="hero-header tt-container">



                                    <div className="container__box ">
                                        <div className="banner">

                                            <div className="banner_left">
                                                <h2 className='heading_1' >
                                                    {isCompany() ?
                                                        type === "gig" ?
                                                            (selectedSearch === "jobseeker" ?
                                                                <>Get the<span className='highlighted-text' style={{ color: 'var(--green)' }}> Right Talent</span> with TrueTalent</>
                                                                : <>Experience <span className='highlighted-text' style={{ color: 'var(--green)' }}> TrueTalent</span> in Every Gig</>)
                                                            : <>Get the <span className='highlighted-text' style={{ color: 'var(--green)' }}>Right Talent</span> with TrueTalent</>
                                                        :
                                                        type === "job" ?
                                                            (selectedSearch === "jobseeker" ?
                                                                <>Get the<span className='highlighted-text' style={{ color: 'var(--green)' }}> Right Talent</span> with TrueTalent</>
                                                                : <>Get the <span className='highlighted-text' style={{ color: 'var(--green)' }}>Right Job</span> You Deserve</>)
                                                            : (selectedSearch === "jobseeker" ?
                                                                <>Get the<span className='highlighted-text' style={{ color: 'var(--green)' }}> Right Talent</span> with TrueTalent</>
                                                                : <>Unleash Your <span className='highlighted-text' style={{ color: 'var(--green)' }}>TrueTalent</span> with Every Gig</>)
                                                    }
                                                </h2>
                                                {isCompany() ?
                                                    <Text text="Best Talent Across Skills" className='heading_3'></Text>
                                                    :
                                                    <Text className='heading_3' text="Elevating Careers with <span class='highlighted-text txt_green'> AI Talent Matching</span>"></Text>
                                                }

                                                <div className="banner_d" id="banner_d">
                                                    {!isStickySearch ?
                                                        <SearchFilter setJobSearch={setJobSearch} setKeywordSearch={setKeywordSearch} keywordSearch={keywordSearch} searchType={searchType} setSearchType={setSearchType} setLocationSearch={setLocationSearch} setLocationSearchName={setLocationSearchName} job_type={job_type} setJob_type={setJob_type} location={location} setLocation={setLocation} preferredSkillName={preferredSkillName} setPreferredSkillName={setPreferredSkillName} preferredLocationName={preferredLocationName} setPreferredLocationName={setPreferredLocationName} type={type} setLoading={setLoading} setSearchFilterState={setSearchFilterState} selectedSearch={selectedSearch} setSelectedSearch={setSelectedSearch} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />
                                                        : null}
                                                </div>

                                            </div>

                                            {/* <div className="banner_right" style={{ display: 'flex' }}>
                                <img style={{ width: "93%", marginLeft: '3%' }} src={homeBanner} alt="" />
                            </div> */}
                                        </div>
                                    </div>
                                </div >
                            </div>


                            <div className="main-div">
                                {/* Section 1 */}
                                <div className='section_color' >
                                    <div className="container__box">
                                        <div className="section tt-container px-0" style={{ position: 'relative' }}>

                                            <div className="div" >
                                                <Text type="h1" className='heading_2 mb-4 text-center' text={`Top <span class="txt_green">Hiring</span> Companies`}></Text>
                                                <div className="brand_images justify-content-center m-auto-center m-auto row" style={{ marginTop: '24px' }}>
                                                    {homepageLogos && (homepageLogos.map((logo, i) => {
                                                        return <div
                                                            key={"hiring_companies_" + i}
                                                            className='brand-image__card'> <div className='branding_logo box-hover'>
                                                                <Link type="a" href={logo?.company?.website} target='_blank' text={`<img onError=${commonServices.imgError} src=${logo?.company?.logo} alt="" />`}>

                                                                </Link>  </div>
                                                        </div>
                                                    }

                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Section 2 */}
                                <div className='section_color' >
                                    <div className="container__box bg_green">
                                        <div className="section tt-container px-0" style={{ position: 'relative' }}>

                                            <div className="div" >
                                                <Text type="h1" className='heading_2 mb-4 text-center' text='Trusted <span class="txt_green">Academia</span> Partners'></Text>
                                                <div className="brand_images justify-content-center m-auto row" style={{ marginTop: '24px' }}>
                                                    {partnerLogos && (partnerLogos.map((logo, i) => {
                                                        return <div
                                                            key={"academia_partners_" + i}
                                                            className='brand-image__card'> <div className='branding_logo box-hover'>
                                                                <Link type="a" href={logo?.company?.website} target='_blank' text={`<img onError=${commonServices.imgError} src=${logo?.company?.logo} alt="" />`}></Link>  </div>
                                                        </div>
                                                    }

                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* SECTION 3 */}
                                {type === 'gig' ? (
                                    <>
                                        <div className="container__box">
                                            <div className="section tt-container">
                                                <Text type="h1" title='These are hot freelance jobs in the market' className='heading_2 text-center' text='Featured <span class="txt_green">Gigs</span> for You'></Text>
                                                <div className="job__card__section row" style={{ marginTop: "24px" }}>
                                                    {featuredGigs ?
                                                        featuredGigs.map((gigData) => {
                                                            return (<div
                                                                key={"featured_gig" + gigData.id}
                                                                onClick={() => { addFeatureGigImpression(gigData?.eid, "click"); window.open('/gig/details/' + gigData.eid + '/' + createSlugFromString(gigData?.title), '_blank') }} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }}>
                                                                <Card data={gigData} type={'gig'} />
                                                            </div>)
                                                        }) : ""
                                                    }
                                                </div>

                                                <div style={{ marginTop: '30px' }}>
                                                    <ButtonOutlined text="View All Gigs" onClick={() => { navigate('/gig/search'); }} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='section_color' >
                                            <div className="container__box bg_green">
                                                <div className="section tt-container">
                                                    <Text type="h1" title='These are hot jobs in the market' className='heading_2 text-center' text='Featured <span class="txt_green">Jobs</span> for you '></Text>

                                                    <div className="job__card__section row" style={{ marginTop: "24px" }}>
                                                        {featuredJobs && featuredJobs.length > 0 ?
                                                            featuredJobs.map((jobData) => {

                                                                return (<div
                                                                    key={"job" + jobData.id}
                                                                    onClick={() => { addFeatureJobImpression(jobData?.eid, "click"); window.open('/job/details/' + jobData.eid + '/' + createSlugFromString(jobData.title), '_blank') }} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }}>
                                                                    <Card data={jobData} type={'job'} />
                                                                </div>)
                                                            }) :
                                                            ""
                                                        }

                                                    </div>

                                                    <div style={{ marginTop: '30px' }}>
                                                        <ButtonOutlined text="View All Jobs" onClick={() => { navigate('/job/search'); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) :
                                    <>
                                        <div className="container__box">
                                            <div className="section tt-container">
                                                <Text type="h1" title='These are hot jobs in the market' className='heading_2 text-center' text='Featured <span class="txt_green">Jobs</span> for You'></Text>

                                                <div className="job__card__section row mt-4">
                                                    {featuredJobs && featuredJobs.length > 0 ?
                                                        featuredJobs.map((jobData, index) => {
                                                            return (<div
                                                                key={"jobs_2" + index}
                                                                onClick={() => { addFeatureJobImpression(jobData?.eid, "click"); window.open('/job/details/' + jobData.eid + '/' + createSlugFromString(jobData.title), '_blank') }} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }} >
                                                                <Card data={jobData} type={'job'} />
                                                            </div>)
                                                        }) :
                                                        ""
                                                    }

                                                </div>

                                                <div style={{ marginTop: '30px' }}>
                                                    <ButtonOutlined text="View All Jobs" onClick={() => { navigate('/job/search'); }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='section_color' >
                                            <div className='section_color' >
                                                <div className="container__box bg_green">
                                                    <div className="section tt-container">
                                                        <Text type="h1" title='These are hot freelance jobs in the market' className='heading_2 text-center' text='Featured <span class="txt_green" >Gigs</span> for You'></Text>

                                                        <div className="job__card__section row" style={{ marginTop: "24px" }}>
                                                            {featuredGigs && featuredGigs.length > 0 ?
                                                                featuredGigs.map((value, index) => {
                                                                    return (<div
                                                                        key={"gigs_2" + index}
                                                                        onClick={() => { addFeatureGigImpression(value?.eid, "click"); window.open('/gig/details/' + value.eid + '/' + createSlugFromString(value?.title), '_blank') }} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }}>
                                                                        <Card data={value} type={'gig'} />
                                                                    </div>)
                                                                }) :
                                                                ""
                                                            }

                                                        </div>

                                                        <div style={{ marginTop: '30px' }}>
                                                            <ButtonOutlined text="View All Gigs" onClick={() => { navigate('/gig/search'); }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* SECTION 4 */}
                                <div className='section_color' >
                                    <div className="container__box">
                                        <div className="section tt-container">
                                            <Text type="h1" className='heading_2 text-center' text='Popular <span class="txt_green">Industries</span>'></Text>

                                            <div className="job__card__section row" style={{ marginTop: "24px" }}>
                                                {masterData?.industry_domains ?
                                                    masterData?.industry_domains.slice(0, 12).map((inData, i) =>
                                                        <div
                                                            key={"popular_industries" + i}
                                                            className="col-xl-2 col-lg-3 col-sm-4 col-6 pointer" onClick={() => navigate('/job/search?domains=' + inData.id)} style={{ padding: '10px', cursor: 'pointer' }}>
                                                            <div className='gigs__card box-hover' style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                                                <div className="featured__gig__icon" style={{ padding: '6px' }}>
                                                                    <img style={{ width: '22px', height: '22px' }} src={inData.value ? inData.value : imgfallback} alt="" />
                                                                </div>

                                                                <div className="content truncate-2" style={{ paddingLeft: '10px' }}>
                                                                    <Text type="h3" style={{ fontSize: '.9em', alignItems: "center|left", marginBottom: "0" }} title={inData.name} text={inData.name}>
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </MaxWidthContainer>
                </div>
            </div>
        </>


    )
}

export default Home
