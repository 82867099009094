import React from "react";
import "./CompanyUsers.scss";

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import ModalAddCompanyUser from "../Modals/ModalAddEditCompanyUser";
import ModalBuyCash from "../Modals/ModalBuyCash";
import { PuffLoader } from "react-spinners";
import { isCompanyAdmin, isPartnerAdmin } from "@/utils/authUtil";
import FeaturedJobs from "./TabContent/FeaturedJobs";
import FeaturedGigs from "./TabContent/FeaturedGigs";
import { isCompany } from "../../utils/authUtil";
import Confetti from "react-confetti";
import ModalInformation from "../Modals/ModalInformation";
import Tabs from "../_widgets/Tabs/Tabs";
import ResumeDashboard from "./ResumeDashboard";
import Recruiters from "./TabContent/Recruiters";
import FeaturedJobSlotsHistory from "./TabContent/FeaturedJobSlotsHistory";
import FeaturedGigSlotsHistory from "./TabContent/FeaturedGigSlotsHistory";
import Text from "../_widgets/Text/Text";
import ModalViewTransactionsPagination from "../Modals/ModalViewTransactionsPagination";
import ModalDeactivateCompanyUser from "../Modals/ModalDeactivateCompanyUser";
import Loader from "../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;
const overrideabsolute = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
`;

function CompanyUsersLayout({
    setlastTab,
    showConfetti,
    setShowConfetti,
    activeTab,
    downloadXLSLoading,
    exportTransactions,
    showModalAddCompanyUser,
    setShowModalAddCompanyUser,
    editUserId,
    editUserFirstName,
    editUserLastName,
    editUserEmail,
    editUserAllowedCash,
    getUsers,
    availableCash,
    btClientToken,
    companyId,
    ttCashDollar,
    setShowModalBuyCash,
    showModalBuyCash,
    showModalViewTransactions,
    setShowModalViewTransactions,
    currentTransactions,
    isCompanyTransactions,
    headingText,
    showInformationModal,
    setShowInformationModal,
    informationModalTitle,
    informationModalText,
    loading,
    setActiveTab,
    openTransactionModal,
    companyName,
    companyTransactions,
    isPaymentMethodEnabled,
    openBuyCashModal,
    openAddEditUserModal,
    users,
    allUsers,
    profile,
    updateUserStatus,
    updateUserRole,
    chart1Data,
    Chart1Ref,
    totalDownloads,
    chart2Data,
    Chart2Ref,
    setLoading,
    tabsData,
    activeRecruitersPage,
    handleRecruitersPaginationChange,
    getGraphData,
    getTransactionData,
    transactionData,
    pageNumber,
    handlePaginationChange,
    setPageNumber,
    showModalDeactivateUser,
    setShowModalDeactivateUser,
    deactivateUser,
    setDeactivateUser,
    targetDeactivateUser,
    setTargetDeactivateUser,
}) {
    return (
        <>
            {showConfetti ? (
                <Confetti width={window.innerWidth} height={window.innerHeight} />
            ) : null}
            <div className="company_users main-body">
                <div className="body_section tt-container">
                    <div className="row search mb-3">
                        <div className="col-md-6">
                            <Text type="h2" text="User Dashboard"></Text>
                        </div>
                        {activeTab === "transactions" ? (
                            <div className="col-6">
                                {!downloadXLSLoading ? (
                                    <button
                                        type="button"
                                        className="btn btn-download"
                                        onClick={() => {
                                            exportTransactions();
                                        }}
                                    >
                                        Download .xls
                                    </button>
                                ) : null}
                                <PuffLoader
                                    color="#000000"
                                    loading={downloadXLSLoading}
                                    cssOverride={override}
                                    size={30}
                                    height={30}
                                    width={30}
                                    radios={30}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className="search">
                        <Tabs tabs={tabsData} />
                    </div>

                    <ModalAddCompanyUser
                        showModal={showModalAddCompanyUser}
                        setShowModal={setShowModalAddCompanyUser}
                        editUserId={editUserId}
                        editUserFirstName={editUserFirstName}
                        editUserLastName={editUserLastName}
                        editUserEmail={editUserEmail}
                        editUserAllowedCash={editUserAllowedCash}
                        getUsers={getUsers}
                        availableCash={availableCash}
                    />
                    <ModalBuyCash
                        showModal={showModalBuyCash}
                        setShowModal={setShowModalBuyCash}
                        ttCashDollar={ttCashDollar}
                        companyId={companyId}
                        btClientToken={btClientToken}
                        getUsers={getUsers}
                    />
                    <ModalInformation
                        showModal={showInformationModal}
                        setShowModal={setShowInformationModal}
                        title={informationModalTitle}
                        text={informationModalText}
                        setShowConfetti={setShowConfetti}
                    />

                    {/* New View Transactions Modal */}
                    <ModalViewTransactionsPagination
                        loading={loading}
                        showModal={showModalViewTransactions}
                        setShowModal={setShowModalViewTransactions}
                        transactions={transactionData}
                        isCompanyTransactions={isCompanyTransactions}
                        headingText={headingText}
                        getTransactions={getTransactionData}
                        activePage={pageNumber}
                        handlePaginationChange={handlePaginationChange}
                        setPageNumber={setPageNumber}
                    />
                    <ModalDeactivateCompanyUser
                        showModal={showModalDeactivateUser}
                        setShowModal={setShowModalDeactivateUser}
                        user={deactivateUser}
                        users={allUsers}
                        targetDeactivateUser={targetDeactivateUser}
                        setTargetDeactivateUser={setTargetDeactivateUser}
                        setLoading={setLoading}
                        loading={loading}
                        getUsers={getUsers}
                    />

                    <div className="result_section">
                        <div className="main-div">
                            <div className="col">
                                <div
                                    className="tab-content position-relative"
                                    id="nav-tabContent"
                                >
                                    {loading ? (
                                        <Loader />
                                    ) : null}

                                    {isCompanyAdmin() || isPartnerAdmin() ? (
                                        <div
                                            className={
                                                activeTab === "recruiters"
                                                    ? "tab-pane fade show active"
                                                    : "tab-pane fade"
                                            }
                                            id="list-recruiters"
                                            role="tabpanel"
                                            aria-labelledby="list-recruiters-list"
                                        >
                                            {activeTab === "recruiters" ? (
                                                <Recruiters
                                                    availableCash={availableCash}
                                                    openTransactionModal={openTransactionModal}
                                                    getTransactionData={getTransactionData}
                                                    companyName={companyName}
                                                    companyTransactions={companyTransactions}
                                                    isPaymentMethodEnabled={isPaymentMethodEnabled}
                                                    openBuyCashModal={openBuyCashModal}
                                                    openAddEditUserModal={openAddEditUserModal}
                                                    users={users}
                                                    profile={profile}
                                                    updateUserStatus={updateUserStatus}
                                                    updateUserRole={updateUserRole}
                                                    activeRecruitersPage={activeRecruitersPage}
                                                    handleRecruitersPaginationChange={
                                                        handleRecruitersPaginationChange
                                                    }
                                                    loading={loading}
                                                    setLoading={setLoading}
                                                />
                                            ) : null}
                                        </div>
                                    ) : null}

                                    <div
                                        className={
                                            activeTab === "resumes"
                                                ? "tab-pane fade show active"
                                                : "tab-pane fade"
                                        }
                                        id="list-manage-resume"
                                        role="tabpanel"
                                        aria-labelledby="list-manage-resume-list"
                                    >
                                        {activeTab === "resumes" ? (
                                            <ResumeDashboard
                                                chart1Data={chart1Data}
                                                Chart1Ref={Chart1Ref}
                                                totalDownloads={totalDownloads}
                                                chart2Data={chart2Data}
                                                Chart2Ref={Chart2Ref}
                                                getGraphData={getGraphData}
                                            />
                                        ) : null}
                                    </div>
                                    {isCompany() ? (
                                        <>
                                            <div
                                                className={
                                                    activeTab === "featured-jobs"
                                                        ? "tab-pane fade show active"
                                                        : "tab-pane fade"
                                                }
                                                id="list-featured-jobs"
                                                role="tabpanel"
                                                aria-labelledby="list-featured-jobs-list"
                                            >
                                                {activeTab === "featured-jobs" ? (
                                                    <FeaturedJobs
                                                        loading={loading}
                                                        setLoading={setLoading}
                                                        openBuyCashModal={openBuyCashModal}
                                                        activeTab={activeTab}
                                                        setlastTab={setlastTab}
                                                        setActiveTab={setActiveTab}
                                                    />
                                                ) : null}
                                            </div>

                                            <div
                                                className={
                                                    activeTab === "featured-gigs"
                                                        ? "tab-pane fade show active"
                                                        : "tab-pane fade"
                                                }
                                                id="list-featured-gigs"
                                                role="tabpanel"
                                                aria-labelledby="list-featured-gigs-list"
                                            >
                                                {activeTab === "featured-gigs" ? (
                                                    <FeaturedGigs
                                                        loading={loading}
                                                        setLoading={setLoading}
                                                        openBuyCashModal={openBuyCashModal}
                                                        setActiveTab={setActiveTab}
                                                        setlastTab={setlastTab}
                                                    />
                                                ) : null}
                                            </div>

                                            <div
                                                className={
                                                    activeTab === "featured-job-slots-history"
                                                        ? "tab-pane fade show active"
                                                        : "tab-pane fade"
                                                }
                                                id="list-featured-job-slots-history"
                                                role="tabpanel"
                                                aria-labelledby="list-featured-job-slots-history-list"
                                            >
                                                {activeTab === "featured-job-slots-history" ? (
                                                    <FeaturedJobSlotsHistory
                                                        loading={loading}
                                                        setLoading={setLoading}
                                                        activeTab={activeTab}
                                                    />
                                                ) : null}
                                            </div>

                                            <div
                                                className={
                                                    activeTab === "featured-gig-slots-history"
                                                        ? "tab-pane fade show active"
                                                        : "tab-pane fade"
                                                }
                                                id="list-featured-gig-slots-history"
                                                role="tabpanel"
                                                aria-labelledby="list-featured-gig-slots-history-list"
                                            >
                                                {activeTab === "featured-gig-slots-history" ? (
                                                    <FeaturedGigSlotsHistory
                                                        loading={loading}
                                                        setLoading={setLoading}
                                                        activeTab={activeTab}
                                                    />
                                                ) : null}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyUsersLayout;
