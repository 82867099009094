import ClipLoader from "react-spinners/ClipLoader";
import * as commonServices from "@/services/common";
import emptyResult from "@/Assets/svgs/empty_result.svg";
import { addTabInHistory, getCurrentTab } from "@/utils/redirectUtil";
import { ACTIVE, CLOSED, DRAFT } from "@/constants/tabConstants";
import referredJobImg from "@/Assets/svgs/referredJob.svg";
import Text from "../_widgets/Text/Text";
import * as storageConstants from "@/constants/storageConstants";
import Button from "../_widgets/Button/Button";
import IconResource from "../_widgets/IconResource/IconResource";
import CustomTooltip from '../../../src/utils/CustomTooltip'
import Loader from "../_widgets/Loader/Loader";

function PostedJobsLayouts({
  override,
  activeJobs,
  draftJobs,
  closedJobs,
  type,
  setType,
  loading,
  color,
  setOrderBy,
  orderByText,
  setOrderByText,
  currentTab,
  navigate,
  handleJobClick,
  tab,
  onTabClick,
}) {
  const handleClickInChild = (e, job) => {
    // Perform some actions in the child component
    // Then call the parent's handleJobClick function if needed
    handleJobClick(e, job);
  };
  return (
    <div className="posted_jobs">
      {loading ? (
        <Loader />
      ) : null}
      <div className="body_section tt-container">
        <div className="header">
          <div className="d-flex header_left">
            <Text type={"h2"} text={"Jobs Panel"} />
            <Button
              buttonType="primary"
              onClick={() => {
                navigate("/job/add");
              }}
              text={"Post New Job"}
            />
          </div>
        </div>

        <div className="search_section">
          <div className="d-flex header_left toggle-header">
            <p className={type === "ALL_JOBS" ? "green-text f-700" : "f-700"}>
              Show company jobs
            </p>
            <label className="switch">
              <input
                type="checkbox"
                defaultChecked={type === "MY_JOBS" ? true : false}
                onChange={(e) => {
                  setType(e.target.checked === true ? "MY_JOBS" : "ALL_JOBS");
                }}
                value="1"
              />
              <span className="slider round"></span>
            </label>
            <p className={type === "MY_JOBS" ? "green-text f-700" : "f-700"}>
              Show my jobs only
            </p>
          </div>

          <div className="sort_by d-flex align-items-center">
            <div className="result_header_sort">
              <p>Sort by:</p>
              <div className="dropdown">
                <a
                  className="btn btn-secondary dropdown-toggle"
                  href={undefined}
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {orderByText}
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        setOrderBy("MOST_RECENT");
                        setOrderByText("Newest First");
                      }}
                      href={undefined}
                    >
                      Newest First
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        setOrderBy("POST_DATE");
                        setOrderByText("Oldest First");
                      }}
                      href={undefined}
                    >
                      Oldest First
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="filter_tab_header">
              <div className="list-group" id="list-tab-1" role="tablist">
                <a className="list-group-item list-group-item-action active" id="list_1" data-bs-toggle="list" href="#list_1_content" role="tab" aria-controls="list_1_content">
                  <CustomTooltip text="Grid View">
                    <div className='round'>
                      <img onError={commonServices.imgError} src="/Assets/svgs/list_view_icon.svg" alt="" className='ig' />
                    </div>
                  </CustomTooltip>
                </a>
                <a className="list-group-item list-group-item-action" id="list_2" data-bs-toggle="list" href="#list_2_content" role="tab" aria-controls="list_2_content">
                  <CustomTooltip text="Table View">
                    <div className='round'>
                      <img onError={commonServices.imgError} src="/Assets/svgs/grid_view_icon.svg" alt="" className='ig' />
                    </div>
                  </CustomTooltip>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="result_section">
          <div className="col">
            <div className="tab-content" id="nav_tabContent_1">
              <div
                className="tab-pane fade show active"
                id="list_1_content"
                role="tabpanel"
                aria-labelledby="list_1"
              >
                <div>
                  <div className="filter_tab ">
                    <div
                      className="list-group list-group-child"
                      id="list-tab"
                      role="tablist"
                    >
                      <a
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_1_active"
                        data-bs-toggle="list"
                        href="#list_1_active_content"
                        role="tab"
                        aria-controls="list_1_active_content"
                        onClick={() => onTabClick(ACTIVE)}
                      >
                        Active Jobs ({activeJobs ? activeJobs.length : "0"})
                      </a>
                      <a
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        id="list_1_closed"
                        data-bs-toggle="list"
                        href="#list_1_closed_content"
                        role="tab"
                        aria-controls="list_1_closed_content"
                        onClick={() => onTabClick(CLOSED)}
                      >
                        Closed Jobs ({closedJobs ? closedJobs.length : "0"})
                      </a>
                      <a
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_1_draft"
                        data-bs-toggle="list"
                        href="#list_1_draft_content"
                        role="tab"
                        aria-controls="list_1_draft_content"
                        onClick={() => onTabClick(DRAFT)}
                      >
                        Drafts ({draftJobs ? draftJobs.length : "0"})
                      </a>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="tab-content-child tab-content"
                      id="nav-tabContent_1"
                    >
                      <div
                        className={
                          "tab-pane fade show " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_1_active_content"
                        role="tabpanel"
                        aria-labelledby="list_1_active"
                      >
                        {activeJobs && activeJobs.length > 0 ? (
                          <div className="card_section">
                            <div
                              className="posted_card_container"
                              style={{
                                marginTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              {activeJobs &&
                                activeJobs.map((job, i) => {
                                  return (
                                    <div className="posted-card main-card">
                                      <div
                                        key={i}
                                        className="card"
                                        onClick={(e) => handleClickInChild(e, job)}>
                                        {job?.is_featured ?
                                          <div className="bookmark">
                                            <CustomTooltip text="Featured Job">
                                              <IconResource type="bookmark" color="var(--red)" />
                                            </CustomTooltip>
                                          </div>
                                          : null
                                        }
                                        <div className="card_main box-hover">
                                          <div className="card_header">
                                            <h3>ID-J0000{job.eid} </h3>
                                            {job.referral &&
                                              job.referral.length > 0 ? (
                                              job.referral[0].days_left > 0 &&
                                                job.referral[0].days_left <= 7 ? (
                                                <span className="days-left">
                                                  Refer and earn:{" "}
                                                  {job.referral[0].days_left}{" "}
                                                  days left
                                                </span>
                                              ) : (
                                                <img
                                                  title="This post is open for referrals"
                                                  src={referredJobImg}
                                                  alt=""
                                                ></img>
                                              )
                                            ) : null}
                                          </div>
                                          <div className="card_content">
                                            <Text
                                              type={"h2"}
                                              text={job.title}
                                            />
                                            <p>
                                              {job.job_skills !== null
                                                ? job.job_skills
                                                  .split(",")
                                                  .join(", ")
                                                : job.job_skills}
                                            </p>
                                            <span>
                                              {job.first_name +
                                                " " +
                                                job.last_name}{" "}
                                              | Posted {job.posted_date}
                                            </span>
                                          </div>
                                          <div className="card_buttons">
                                            <div>
                                              <p>{job.applicants} APPLICANTS</p>
                                            </div>
                                            <div>
                                              <p
                                                className={
                                                  storageConstants.APPLICANTS
                                                }
                                                onClick={(e) =>
                                                  handleClickInChild(e, job)
                                                }
                                              >
                                                {job.matching_count} MATCHING
                                                WORK PROFILES
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type={"h2"} text={"No active job found!"} />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "tab-pane fade show " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        id="list_1_closed_content"
                        role="tabpanel"
                        aria-labelledby="list_1_closed"
                      >
                        {closedJobs && closedJobs.length > 0 ? (
                          <div className="card_section">
                            <div
                              className="posted_card_container"
                              style={{
                                marginTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              {closedJobs &&
                                closedJobs.map((job, i) => {
                                  return (
                                    <div className="posted-card main-card">
                                      <div
                                        key={i}
                                        className="card"
                                        onClick={(e) => handleClickInChild(e, job)}>
                                        {job?.is_featured ?
                                          <div className="bookmark">
                                            <CustomTooltip text="Featured Job">
                                              <IconResource type="bookmark" color="var(--red)" />
                                            </CustomTooltip>
                                          </div>
                                          : null
                                        }
                                        <div className="card_main box-hover">
                                          <div className="card_header">
                                            <h3>
                                              ID-J0000{job.eid}{" "}
                                              {job.referral &&
                                                job.referral.length > 0 ? (
                                                job.referral[0].days_left > 0 &&
                                                  job.referral[0].days_left <=
                                                  7 ? (
                                                  <span className="days-left">
                                                    Refer and earn:{" "}
                                                    {job.referral[0].days_left}{" "}
                                                    days left
                                                  </span>
                                                ) : (
                                                  <img
                                                    src={referredJobImg}
                                                    alt=""
                                                  ></img>
                                                )
                                              ) : null}
                                            </h3>
                                          </div>
                                          <div className="card_content">
                                            <Text
                                              type={"h2"}
                                              text={job.title}
                                            />
                                            <p>
                                              {job.job_skills !== null
                                                ? job.job_skills
                                                  .split(",")
                                                  .join(", ")
                                                : job.job_skills}
                                            </p>
                                            <span>
                                              {job.first_name +
                                                " " +
                                                job.last_name}{" "}
                                              | Posted {job.posted_date}
                                            </span>
                                          </div>
                                          <div className="card_buttons">
                                            <div>
                                              <p>{job.applicants} APPLICANTS</p>
                                            </div>
                                            <div>
                                              <p
                                                className={
                                                  storageConstants.APPLICANTS
                                                }
                                                onClick={(e) =>
                                                  handleClickInChild(e, job)
                                                }
                                              >
                                                {job.matching_count} MATCHING
                                                WORK PROFILES
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type={"h2"} text={"No closed job found!"} />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "tab-pane fade show " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_1_draft_content"
                        role="tabpanel"
                        aria-labelledby="list_1_draft"
                      >
                        {draftJobs && draftJobs.length > 0 ? (
                          <div className="card_section">
                            <div
                              className="posted_card_container"
                              style={{
                                marginTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              {draftJobs &&
                                draftJobs.map((job, i) => {
                                  return (
                                    <div className="posted-card main-card">
                                      <div
                                        key={i}
                                        className="card"
                                        onClick={(e) => handleClickInChild(e, job)}>
                                        {job?.is_featured ?
                                          <div className="bookmark">
                                            <CustomTooltip text="Featured Job">
                                              <IconResource type="bookmark" color="var(--red)" />
                                            </CustomTooltip>
                                          </div>
                                          : null
                                        }
                                        <div className="card_main box-hover">
                                          <div className="card_header">
                                            <h3>
                                              ID-J0000{job.eid}{" "}
                                              {job.referral &&
                                                job.referral.length > 0 ? (
                                                job.referral[0].days_left > 0 &&
                                                  job.referral[0].days_left <=
                                                  7 ? (
                                                  <span className="days-left">
                                                    Refer and earn:{" "}
                                                    {job.referral[0].days_left}{" "}
                                                    days left
                                                  </span>
                                                ) : (
                                                  <img
                                                    src={referredJobImg}
                                                    alt=""
                                                  ></img>
                                                )
                                              ) : null}
                                            </h3>
                                          </div>
                                          <div className="card_content">
                                            <Text
                                              type={"h2"}
                                              text={job.title}
                                            />
                                            <p>
                                              {job.job_skills !== null
                                                ? job.job_skills
                                                  .split(",")
                                                  .join(", ")
                                                : job.job_skills}
                                            </p>
                                            <span>
                                              {job.first_name +
                                                " " +
                                                job.last_name}{" "}
                                              | Drafted {job.posted_date}
                                            </span>
                                          </div>
                                          <div className="card_buttons">
                                            <div>
                                              <p>{job.applicants} APPLICANTS</p>
                                            </div>
                                            <div>
                                              <p
                                                className={
                                                  storageConstants.APPLICANTS
                                                }
                                                onClick={(e) =>
                                                  handleClickInChild(e, job)
                                                }
                                              >
                                                {job.matching_count} MATCHING
                                                WORK PROFILES
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type={"h2"} text={"No draft job found!"} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="list_2_content"
                role="tabpanel"
                aria-labelledby="list_2"
              >
                <div>
                  <div className="filter_tab ">
                    <div
                      className="list-group list-group-child"
                      id="list-tab"
                      role="tablist"
                    >
                      <a
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_2_active"
                        data-bs-toggle="list"
                        href="#list_2_active_content"
                        role="tab"
                        aria-controls="list_2_active_content"
                        onClick={() => onTabClick(ACTIVE)}
                      >
                        Active Jobs ({activeJobs ? activeJobs.length : ""})
                      </a>
                      <a
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        data-bs-toggle="list"
                        href="#list_2_closed_content"
                        role="tab"
                        aria-controls="list_2_closed_content"
                        onClick={() => onTabClick(CLOSED)}
                      >
                        Closed Jobs ({closedJobs ? closedJobs.length : ""})
                      </a>
                      <a
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_2_draft"
                        data-bs-toggle="list"
                        href="#list_2_draft_content"
                        role="tab"
                        aria-controls="list_2_draft_content"
                        onClick={() => onTabClick(DRAFT)}
                      >
                        Drafts ({draftJobs ? draftJobs.length : "0"})
                      </a>
                    </div>
                  </div>
                  <div className="col">
                    <div className="tab-content" id="nav-tabContent_2">
                      <div
                        className={
                          "tab-pane fade show common_items " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_2_active_content"
                        role="tabpanel"
                        aria-labelledby="list_2_active"
                      >
                        {activeJobs && activeJobs.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Job ID</th>
                                <th scope="col">Job</th>
                                <th scope="col">Skills</th>
                                <th scope="col">No. of Applicants</th>
                                <th scope="col">Matching Work Profiles</th>
                                <th>Posted By</th>
                                <th>Posted On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {activeJobs &&
                                activeJobs?.map((job, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className="hovering position-relative"
                                      onClick={(e) =>
                                        handleClickInChild(e, job)
                                      }
                                    >
                                      <td>J0000{job.eid}</td>
                                      <td className="bold">{job.title}</td>
                                      <td>
                                        {job.job_skills !== null
                                          ? job.job_skills.split(",").join(", ")
                                          : job.job_skills}
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {job.applicants}
                                        </div>
                                      </td>
                                      <td
                                        className={storageConstants.APPLICANTS}
                                        onClick={(e) => handleClickInChild(e, job)}>
                                        <div className="number-div">
                                          {job.matching_count}
                                        </div>
                                      </td>
                                      <td>
                                        {job.first_name + " " + job.last_name}
                                      </td>
                                      <td>{job.posted_date}</td>
                                      {job?.is_featured ?
                                        (<td colSpan={2} className="bookmark" style={{ right: "-12px", top: "-10px" }}>
                                          <CustomTooltip id={i} text="Featured Job">
                                            <IconResource type="bookmark" color="var(--red)" />
                                          </CustomTooltip>
                                        </td>
                                        ) : null}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type={"h2"} text={"No active job found!"} />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "tab-pane fade show common_items " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        id="list_2_closed_content"
                        role="tabpanel"
                        aria-labelledby="list_2_closed"
                      >
                        {closedJobs && closedJobs.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Job ID</th>
                                <th scope="col">Job</th>
                                <th scope="col">Skills</th>
                                <th scope="col">No. of Applicants</th>
                                <th scope="col">Matching Work Profiles</th>
                                <th>Posted By</th>
                                <th>Posted On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {closedJobs &&
                                closedJobs.map((job, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className="Hovering position-relative"
                                      onClick={(e) =>
                                        handleClickInChild(e, job)
                                      }
                                    >
                                      <td>J0000{job.eid}</td>
                                      <td className="bold">{job.title}</td>
                                      <td>
                                        {job.job_skills !== null
                                          ? job.job_skills.split(",").join(", ")
                                          : job.job_skills}
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {job.applicants}
                                        </div>
                                      </td>
                                      <td
                                        className={storageConstants.APPLICANTS}
                                        onClick={(e) => handleClickInChild(e, job)}>
                                        <div className="number-div">
                                          {job.matching_count}
                                        </div>
                                      </td>
                                      <td>
                                        {job.first_name + " " + job.last_name}
                                      </td>
                                      <td>{job?.posted_date}</td>
                                      {job?.is_featured ?
                                        (<td colSpan={2} className="bookmark" style={{ right: "-12px", top: "-10px" }}>
                                          <CustomTooltip text="Featured Job">
                                            <IconResource type="bookmark" color="var(--red)" />
                                          </CustomTooltip>
                                        </td>
                                        ) : null}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type={"h2"} text={"No closed job found!"} />
                          </div>
                        )}{" "}
                      </div>
                      <div
                        className={
                          "tab-pane fade show common_items " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_2_draft_content"
                        role="tabpanel"
                        aria-labelledby="list_2_draft"
                      >
                        {draftJobs && draftJobs.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Job ID</th>
                                <th scope="col">Job</th>
                                <th scope="col">Skills</th>
                                <th scope="col">No. of Applicants</th>
                                <th scope="col">Matching Work Profiles</th>
                                <th>Drafted By</th>
                                <th>Drafted On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {draftJobs &&
                                draftJobs.map((job, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className="hovering position-relative"
                                      onClick={(e) =>
                                        handleClickInChild(e, job)
                                      }
                                    >
                                      <td>J0000{job.eid}</td>
                                      <td className="bold">{job.title}</td>
                                      <td>
                                        {job.job_skills !== null
                                          ? job.job_skills.split(",").join(", ")
                                          : job.job_skills}
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {job.applicants}
                                        </div>
                                      </td>
                                      <td
                                        className={storageConstants.APPLICANTS}
                                        onClick={(e) =>
                                          handleClickInChild(e, job)
                                        }
                                      >
                                        <div className="number-div">
                                          {job.matching_count}
                                        </div>
                                      </td>
                                      <td>
                                        {job.first_name + " " + job.last_name}
                                      </td>
                                      <td>{job.posted_date}</td>
                                      {job?.is_featured ?
                                        (<td colSpan={2} className="bookmark" style={{ right: "-12px", top: "-10px" }}>
                                          <CustomTooltip text="Featured Job">
                                            <IconResource type="bookmark" color="var(--red)" />
                                          </CustomTooltip>
                                        </td>
                                        ) : null}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type={"h2"} text={"No draft job found!"} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PostedJobsLayouts;
