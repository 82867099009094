/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./Referral.scss";

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import ModalInviteReferralUser from "@Components/Modals/ModalInviteReferralUser";
import { textCapitalise } from "@/utils/stringUtils";
import Tabs from "../_widgets/Tabs/Tabs";
import JobReferrals from "./TabContent/JobReferrals";
import ProgramReferrals from "./TabContent/ProgramReferrals";
import Loader from "../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ReferralLayout({
  loading,
  showModal,
  setShowModal,
  referralData,
  getReferrals,
  tabsData,
  currentTab,
  referrals,
  referralUsers,
  jobs,
  jobUsers,
  color,
  activeReferralIndex,
  setActiveReferralIndex,
  setActiveJobIndex,
  copyLink,
  copyCode,
  handlePaginationChange,
  handlePaginationJobChange,
  activePage,
  activeJobIndex,
  setReferralData,
  setJobData,
  activeJobPage,
}) {
  return (
    <>
      <div className="referrals">
        {loading ? (
          <Loader />
        ) : null}
        <div className="body_section tt-container">
          <ModalInviteReferralUser
            size={"md"}
            showModal={showModal}
            setShowModal={setShowModal}
            id={referralData?.id}
            url={referralData?.url}
            getReferrals={getReferrals}
            audience={textCapitalise(referralData?.target_audience)}
          />
          <div className="row mb-3">
            <div className="col-9 main-heading">
              <h2>Referrals</h2>
            </div>
          </div>

          <Tabs tabs={tabsData} />
          <div className="tab-content mt-3" id="nav-tabContent">
            <div
              className={
                currentTab === "programs"
                  ? "tab-pane fade show active"
                  : "tab-pane fade show"
              }
              id="list-programs"
              role="tabpanel"
              aria-labelledby="list-programs-list"
            >
              <ProgramReferrals
                referralUsers={referralUsers}
                handlePaginationChange={handlePaginationChange}
                referrals={referrals}
                setActiveReferralIndex={setActiveReferralIndex}
                activeReferralIndex={activeReferralIndex}
                setReferralData={setReferralData}
                referralData={referralData}
                activePage={activePage}
                copyLink={copyLink}
                copyCode={copyCode}
              />
            </div>

            <div
              className={
                currentTab === "job"
                  ? "tab-pane fade show active"
                  : "tab-pane fade show"
              }
              id="list-job"
              role="tabpanel"
              aria-labelledby="list-job-list"
            >
              <JobReferrals
                jobUsers={jobUsers}
                handlePaginationChange={handlePaginationJobChange}
                jobs={jobs}
                setActiveJobIndex={setActiveJobIndex}
                activeJobIndex={activeJobIndex}
                setJobData={setJobData}
                activeJobPage={activeJobPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReferralLayout;
