/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as commonServices from '@/services/common';
import emptyResult from '@/Assets/svgs/empty_result.svg';
import Table from '../../_widgets/Table/Table';
import { createSlugFromString } from '@/utils/stringUtils';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../../../services/common';

function SavedJobs({ savedJobs, activePage, setActivePage, handlePaginationChange }) {
    const navigate = useNavigate();
    var savedJobsColumns = ['Job ID', 'Job', 'Company', 'Job Type', 'Skills', 'Location', 'Date Saved'];
    const [savedJobsTableData, setSavedJobsTableData] = useState([]);
    useEffect(() => {

        var row = [];
        var row = savedJobs?.data && savedJobs?.data.map((job, i) => {
            var data = [];
            data.push("J0000" + job.eid);
            data.push(job.title);
            data.push(job.company_name);
            data.push(job.job_types);
            data.push(job.job_skills !== null ? job.job_skills.split(',').join(', ') : job.job_skills);
            data.push(job.job_locations);
            data.push(dateFormat(job.saved_at));
            return {
                rowClick: (data) => { navigate('/job/details/' + data['eid'] + '/' + createSlugFromString(data['title'])) },
                rowClickData: {
                    eid: job?.eid,
                    title: job?.title
                },
                data: data
            }
        }

        );

        setSavedJobsTableData(row)

    }, [savedJobs])
    return (
        <>
            {savedJobs?.data && savedJobs?.data.length > 0 ?
                (
                    <div className="common_items">
                        <Table columns={savedJobsColumns} data={savedJobsTableData} activePage={activePage} setActivePage={setActivePage} handlePaginationChange={handlePaginationChange} pagination={savedJobs} />
                    </div>)
                : <div className='no_jobs' ><img onError={commonServices.imgError} src={emptyResult} alt="" /><h2>No saved job found!</h2></div>}
        </>
    )
}

export default SavedJobs