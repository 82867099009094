import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './CompanyProfile.scss';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import * as commonServices from '@/services/common';
import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import Card from '../Common/Card';
import { createSlugFromString } from '@/utils/stringUtils';
import mapPin from "@/Assets/svgs/map-pin.svg";
import message_white from '@/Assets/svgs/message-white.svg';
import ModalUserMessage from '../Modals/ModalUserMessage';
import { getUserId } from '@/utils/authUtil';
import { MESSAGE_REQUIRED } from '@/constants/stringConstants';
import { getCandidateExperienceText } from '@/services/common';
import * as storageConstants from '@/constants/storageConstants';
import emptyResult from '@/Assets/svgs/empty_result.svg';
import Loader from '../_widgets/Loader/Loader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed;
`;

const RecruiterProfile = () => {

    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    const [showModalUserMessage, setShowModalUserMessage] = useState(false);
    let params = useParams();
    const recruiter_id = params.id;
    const currentUser = commonServices.getLocalData(storageConstants.PROFILE);

    const [profile, setProfile] = useState([]);
    const [currentTab, setCurrentTab] = useState('jobs');
    const [profileId, setProfileId] = useState(getUserId());

    const [chat, setChat] = useState({
        candidate_id: profileId,
        recruiter_id: recruiter_id,
        job_id: "",
        message: ""
    })

    const createChat = () => {


        if (!chat.message) {
            toast.warn(MESSAGE_REQUIRED);
            return;
        }

        const obj = { ...chat };

        if (!obj.job_id) {
            delete obj.job_id;
        }

        ApiHelper.createChat(obj).then((response) => {
            if (response.isSuccess === true) {
                navigate('/chat');
            }
            else {
                toast.warn(response.message);
            }
        }
        );
    }


    useEffect(() => {
        getLatestProfile();
    }, [])

    const getLatestProfile = async () => {
        setLoading(true);
        await ApiHelper.getRecruiterDetails(recruiter_id).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setProfile(response?.data?.recruiter_details);
            } else {
                toast.warn(response.message);
            }
        });
    }


    return (
        <>
            {loading ?
                <Loader /> : null}
            <div className='company-profile tt-container main-body'>
                <ModalUserMessage setChat={setChat} chat={chat} onSubmit={createChat} showModal={showModalUserMessage} setShowModal={setShowModalUserMessage} />
                <div className="banner" style={{ backgroundImage: `url(${profile?.company_details?.cover_pic})`, height: '200px', border: 'solid 1px var(--gray)', borderTopLeftRadius: '16px', borderTopRightRadius: "16px", position: 'relative' }}>
                    {/* <img style={{ height: '100%', width: '100%' }} src={profile?.cover_pic} ></img> */}
                    <div style={{ bottom: "-50px" }} className='profile_img img-border' ><img style={{ objectFit: 'cover', objectPosition: 'center' }} width={100} onError={commonServices.imgError} src={profile?.avatar_location ?? defaultAvatar} alt="" /></div>
                    {/* <span className='setDeleteClass'><DeleteIcon className='deleteIcon' /></span> */}
                </div>
                <div style={{ height: '100px', border: 'solid 1px var(--gray)', borderBottomLeftRadius: '16px', borderBottomRightRadius: "16px", border: 'solid 1px var(--gray)', borderTop: "0px", position: 'relative' }}>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-8'>
                            <h5 className='recruiter-name'>{profile?.full_name}</h5>
                        </div>
                        <div className='col-3'>
                            <button className='send-message'
                                onClick={() => {
                                    if (!currentUser) {
                                        navigate('/?auth=login&redirect=/company/recruiter/' + recruiter_id)
                                    } else {
                                        setShowModalUserMessage(true);
                                    }

                                }}
                            >
                                <img style={{ height: "1em", marginRight: "5px" }} src={message_white} alt='' />
                                Send a Message
                            </button>
                        </div>

                    </div>
                </div>

                <div className="tabs list-group" id="list-tab" role="tablist">

                    <div className="tab__list hand-hover active" id="list-jobs-list" data-bs-toggle="list" href="#list-jobs" role="tab" aria-controls="list-jobs" onClick={() => setCurrentTab('jobs')}>
                        <p>Jobs</p>
                    </div>

                    <div className="tab__list hand-hover" id="list-gigs-list" data-bs-toggle="list" href="#list-gigs" role="tab" aria-controls="list-gigs" onClick={() => setCurrentTab('gigs')}>
                        <p>Gigs</p>
                    </div>
                </div>

                <div className="tab-content" id="nav-tabContent">
                    {currentTab === 'jobs' ?
                        <div className="tab__pane tab_2 d-flex gap-3 fade show" id='list-jobs' role="tabpanel" aria-labelledby="list-jobs-list">
                            <div className="container" id="featured-3">
                                <div className="job__card__section row row-cols-1">
                                    {profile?.jobs && profile?.jobs.length > 0 ? profile?.jobs.map((jobData, key) => {
                                        return (<div onClick={() => window.open('/job/details/' + jobData.eid + '/' + createSlugFromString(jobData.title), '_blank')} className="col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }}>
                                            <div className='p-3 job__card shadow-sm recruiter-feature justify-content-between box-hover rounded-card white-background'>
                                                <div className="image_container">
                                                    <img className='featured__img' src={jobData?.company_details?.logo} alt="" />
                                                </div>

                                                <div className='job__content'>
                                                    <p className='paragraph_1 truncate-2' title={jobData.title}>{jobData.title}</p>
                                                    <p className='paragraph_2 truncate-1'>{jobData.job_types} {getCandidateExperienceText(jobData.minimum_experience_required)}-{getCandidateExperienceText(jobData.maximum_experience_required)}</p>
                                                    <p className='paragraph_3 d-flex gap-1'> <span><img src={mapPin} alt="" /></span> <span className='truncate-1' title={jobData?.locations.length > 0 ? jobData?.locations.map(function (i) { return i.name; }).join(", ") : "India"}>{jobData?.job_locations ? jobData.job_locations : "India"}</span></p>
                                                </div>

                                                <div className="footer" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                    <p className='paragraph_3_name truncate-1' title={jobData?.company_details?.name} style={{ fontWeight: 600 }}>{jobData?.company_details?.name}</p> <p style={{ color: '#9398A1' }} className='paragraph_4 truncate-1' title={jobData.posted_date}>{jobData.posted_date}</p>
                                                </div>
                                            </div>
                                        </div>)

                                    })
                                        :
                                        <div className='no_jobs'><img onError={commonServices.imgError} src={emptyResult} alt="" /><h2>No job found!</h2></div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="tab__pane tab_2 d-flex gap-3 fade show" id='list-gigs' role="tabpanel" aria-labelledby="list-gigs-list">
                            <div className="container" id="featured-3">
                                <div className="job__card__section row row-cols-1">
                                    {profile?.gigs && profile?.gigs.length > 0 ? profile?.gigs.map((gigData, key) => {
                                        return (<div onClick={() => window.open('/gig/details/' + gigData.eid + '/' + createSlugFromString(gigData?.title), '_blank')} className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }}>
                                            <Card key={gigData.id} data={gigData} type={'gig'} />
                                        </div>)

                                    })
                                        :
                                        <div className='no_jobs'><img onError={commonServices.imgError} src={emptyResult} alt="" /><h2>No gig found!</h2></div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>

        </>
    )
}

export default RecruiterProfile