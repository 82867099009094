import { memo, useCallback, useMemo } from 'react';
import * as commonServices from "@/services/common";
import { isCandidate, isCompany } from '../../../utils/authUtil';
import * as storageConstants from "@/constants/storageConstants";

const TipsComponent = memo(() => {
    const appName = process.env.REACT_APP_NAME;

    var masterData = commonServices.getLocalData(storageConstants.DATA);
    var systemConfig = {};
    if(masterData !== null && masterData.system_config !== null && masterData.system_config !== undefined){
        systemConfig = masterData.system_config;
    }
    var tips = "";
    if(isCompany()){
        tips = systemConfig?.loader_text_company_user?.value;
    }else if(isCandidate()){
        tips = systemConfig?.loader_text_candidate_user?.value;
    }else{
        tips = systemConfig?.loader_text_guest_user?.value;
    }
    const updatedTips = useMemo(() => {
        const safeTips = tips || "TrueTalent - The Ultimate Hiring Platform";
        return safeTips.split(",");
    }, [tips]);
    

    const getRandomTip = useCallback(() => {
        const randomIndex = Math.floor(Math.random() * updatedTips.length);
        return updatedTips[randomIndex];
    }, [updatedTips]);

    const randomTip = getRandomTip();

    return (
        <div>
            <p style={{fontSize:"18px"}}>{randomTip}</p>
        </div>
    );
});

export default TipsComponent;
