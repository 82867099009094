import { useEffect, useState, useRef } from 'react';
import * as ApiHelper from '@/services/apiHelper';
import * as storageConstants from '@/constants/storageConstants';
import './FilterBar.scss';
import * as commonServices from '@/services/common';
import { LOCATION_THRESHOLD } from '@/constants/storageConstants';
import * as toast from "@/wrapper/toast";
import xSvg from "../../../Assets/svgs/x.svg";
import searchSvg from "../../../Assets/svgs/search.svg";
import mapSvg from "../../../Assets/svgs/map_pin.svg";
import SearchType from '../../Common/SearchType';
import KeywordSearchTextbox from '../../Common/KeywordSearchTextbox';
import { isNullOrEmpty } from '../../../utils/stringUtils';
import { addSearchParamsInHistory, removeSearchParamsInHistory } from '../../../utils/redirectUtil';
import StickyFindFilter from '../../Common/StickyFIndFilter';
import { ADD_SKILL_SUGGESTION, KEYBOARD_SHORTCUT_TEXT, SKILLS_MAX_ERROR } from '../../../constants/stringConstants';

function FilterBar({ searchedSkills, setSearchedSkills, searchedLocations, setSearchedLocations, FindWork, searchType, setSearchType, keyword, setKeyword, searchBar, module, keywordSeachFilters, setKeywordSeachFilters, saveSearchCriteria, setPreferredSkillData, isStickySearch, activePage, isKeywordReset, setIsKeywordReset }) {

    const skillRef = useRef();
    const locationRef = useRef();

    const [needExtraActions, setNeedExtraActions] = useState(true);

    const [skillSuggestions, setSkillSuggestions] = useState([]);
    const [locationSuggestions, setLocationSuggestions] = useState([]);

    const [skills, setSkills] = useState([]);
    const [locations, setLocations] = useState([]);

    const [skillData, setSkillData] = useState([]);
    const [locationData, setLocationData] = useState([]);

    const href = window.location.href;

    useEffect(() => {
        if (searchedSkills.length < 1 && searchedLocations.length < 1) {
            setSkills([]);
            setLocations([]);
        }
    }, [searchedSkills.toString(), searchedLocations.toString()]);

    useEffect(() => {
        if (document.getElementById('f_t_box_skills')) {
            let skillElement1 = document.getElementById('f_t_box_skills');
            skillElement1.scrollLeft = skillElement1.scrollWidth;
        }

        if (document.getElementById('f_t_box_skills_sticky')) {
            let skillElement2 = document.getElementById('f_t_box_skills_sticky');
            skillElement2.scrollLeft = skillElement2.scrollWidth;
        }
    }, [searchedSkills.toString()]);

    useEffect(() => {
        if (document.getElementById('f_t_box_locations')) {
            let skillElement1 = document.getElementById('f_t_box_locations');
            skillElement1.scrollLeft = skillElement1.scrollWidth;
        }

        if (document.getElementById('f_t_box_locations_sticky')) {
            let skillElement2 = document.getElementById('f_t_box_locations_sticky');
            skillElement2.scrollLeft = skillElement2.scrollWidth;
        }
    }, [searchedLocations.toString()]);

    useEffect(() => {
        let tempUsedIn = '';

        if (href.includes('jobseeker/search')) {
            tempUsedIn = 'candidateSearch';
        } else if (href.includes('job/search')) {
            tempUsedIn = 'jobSearch';
        } else if (href.includes('gig/search')) {
            tempUsedIn = 'gigSearch';
        }

        setNeedExtraActions(tempUsedIn === 'jobSearch' || tempUsedIn === 'gigSearch' || tempUsedIn === 'candidateSearch' ? true : false);
    }, [href]);

    useEffect(() => {
        const handler = (event) => {
            if (!skillRef.current.contains(event.target)) {
                let element = document.getElementById('skill_search_textbox');
                if (skillSuggestions.length > 0) {
                    setSearchedSkills('skills', value => {
                        if (value.some(val => (needExtraActions ? val : val.id) === skillSuggestions[0].id)) {
                            return [...value];
                        }
                        return needExtraActions ? [...value, skillSuggestions[0].id] : [...value, skillSuggestions[0]];
                    });

                    setSkills(value => {
                        if (value.some(val => val.id === skillSuggestions[0].id)) {
                            return [...value];
                        }
                        return [...value, skillSuggestions[0]];
                    });

                    needExtraActions && addChips("skills", skillSuggestions[0].id);
                    setSkillSuggestions([]);
                } else {
                    if (element !== null)
                        addSkillCall(element.value);
                }
                if (element !== null)
                    element.value = '';
            }
            if (!locationRef.current.contains(event.target)) {
                if (locationSuggestions.length > 0) {
                    setSearchedLocations('locations', (value) => {
                        if (value.some(val => (needExtraActions ? val : val.id) === locationSuggestions[0].id)) {
                            return [...value];
                        }
                        return needExtraActions ? [...value, locationSuggestions[0].id] : [...value, locationSuggestions[0]];
                    });

                    setLocations(value => {
                        if (value.some(val => val.id === locationSuggestions[0].id)) {
                            return [...value];
                        }
                        return [...value, locationSuggestions[0]];
                    });

                    needExtraActions && addChips("locations", locationSuggestions[0].id);
                    setLocationSuggestions([]);
                }
                document.getElementById('location_search_textbox').value = '';
            }
        }

        document.addEventListener("mousedown", handler);

        return () => document.removeEventListener("mousedown", handler);
    });

    const addChips = (name, data) => {
        addSearchParamsInHistory(name, data);
    }

    useEffect(() => {
        needExtraActions && getSKills();
    }, [searchedSkills.toString()]);

    const getSKills = async () => {
        let skills = [...skillData];

        if (skills.length < 1) {
            skills = await commonServices.getSkills();
            setSkillData(skills);
        }


        const arr = searchedSkills.map(id => skills.find(s => s.id === id)).filter(s => s !== undefined);

        setSkills(arr);
    }

    const fetchSkillsByName = (text) => {
        return commonServices.getSkillByName(text);
    }

    const onSkillChange = async (text) => {
        if (text.length > storageConstants.SKILL_THRESHOLD) {
            const skills = await fetchSkillsByName(text);
            setSkillSuggestions(skills);
        } else {
            setSkillSuggestions([]);
        }
    }

    const addSkills = (event) => {
        if ((event.which === 13 || event.keyCode === 13) && skillSuggestions.length < 1) { // enter key
            addSkillCall(event.target.value);
            event.target.value = '';
        }
    }

    const onSkillSuggestionClick = (suggestion) => {
        if(skills.length >=4){
            toast.error(SKILLS_MAX_ERROR);
            return;
        }
        setSearchedSkills('skills', value => {
            if (value.some(val => (needExtraActions ? val : val.id) === suggestion.id)) {
                return [...value];
            }
            return needExtraActions ? [...value, suggestion.id] : [...value, suggestion];
        });

        setSkills(value => {
            if (value.some(val => val.id === suggestion.id)) {
                return [...value];
            }
            return [...value, suggestion];
        });

        setSkillSuggestions([]);
        document.getElementById('skill_search_textbox').value = '';

        needExtraActions && addChips("skills", suggestion.id);
    }

    const addSkillCall = (skillName) => {
        if (!isNullOrEmpty(skillName)) {
            const formData = new FormData();
            formData.append('name', skillName);

            ApiHelper.addSkill(formData).then(async (response) => {
                if (response.isSuccess === true) {
                    setPreferredSkillData && setPreferredSkillData([...response.data]);
                    setSkillData([...response.data]);

                    const skills = await fetchSkillsByName(skillName);
                    setSkills(prev => [...prev, skills[0]]);
                    setSearchedSkills('skills', prev => (needExtraActions ? [...prev, skills[0].id] : [...prev, skills[0]]));
                    needExtraActions && addChips("skills", skills[0].id);

                    document.getElementById('skill_search_textbox').value = '';
                }
                else {
                    toast.warn(response.message);
                }
            })
        }
    }

    const deleteSearchedSkill = (e, data) => {
        e.preventDefault();
        const arr = [...skills];

        for (var i = 0; i < arr.length; i++) {
            if (parseInt(arr[i]?.id) === data) {
                arr.splice(i, 1);
            }
        }

        setSkills(arr);
        if (needExtraActions) {
            setSearchedSkills('skills', [...arr.map(s => s.id)]);
            removeSearchParamsInHistory('skills', data);
        } else {
            setSearchedSkills('skills', arr);
        }
    }

    useEffect(() => {
        needExtraActions && getLocations();
    }, [searchedLocations.toString()]);

    const getLocations = async () => {
        let locations = [...locationData];

        if (locations.length < 1) {
            const resp = await ApiHelper.getLocations('JOB_LOCATIONS');
            locations = resp.isSuccess ? resp.data : [];
            setLocationData(locations);
        }

        const arr = searchedLocations.map(id => locations.find(s => s.id === id)).filter(s => s !== undefined);
        setLocations(arr);
    }

    const onLocationChange = (text, type = '') => {
        if (text.length > LOCATION_THRESHOLD) {
            commonServices.getLocations(text + '&type=' + type).then((locations) => {
                setLocationSuggestions(locations);
            });
        } else {
            setLocationSuggestions([]);
        }
    }

    const onLocationSuggestionClick = (suggestion) => {
        setSearchedLocations('locations', (value) => {
            if (value.some(val => (needExtraActions ? val : val.id) === suggestion.id)) {
                return [...value];
            }
            return needExtraActions ? [...value, suggestion.id] : [...value, suggestion];
        });

        document.getElementById('location_search_textbox').value = '';
        setLocationSuggestions([]);

        setLocations(value => {
            if (value.some(val => val.id === suggestion.id)) {
                return [...value];
            }
            return [...value, suggestion];
        });
        needExtraActions && addChips("locations", suggestion.id);
    }

    const deleteSearchedLocation = async (e, data) => {
        e.preventDefault();
        const arr = [...locations];

        for (var i = 0; i < arr.length; i++) {
            if (parseInt(arr[i]?.id) === data) {
                arr.splice(i, 1);
            }
        }

        setLocations(arr);
        if (needExtraActions) {
            setSearchedLocations('locations', [...arr.map(l => l.id)]);
            removeSearchParamsInHistory('locations', data);
        } else {
            setSearchedLocations('locations', arr);
        }
    }

    const triggerClickDown = (event) => {
        const list = [...document.querySelectorAll('.sugg_p')];
        const active = document.querySelector('.sugg_p.active');
        let i = list.indexOf(active);

        if (event.which === 40 || event.keyCode === 40) { // arrow-keydown
            if (active) {
                active.classList.remove('active')
            }

            const c = document.getElementsByClassName('sugg_p hand-hover');

            if (c.length > 0) {
                if (c[i + 1]) {
                    c[i + 1].classList.add('active');
                    c[i + 1].scrollIntoView({ block: "nearest", inline: "nearest" });
                }
            }
        } else if (event.which === 27 || event.keyCode === 27) { // escape key
            setSkillSuggestions([]);
            setLocationSuggestions([]);
        } else if (event.which === 9 || event.keyCode === 9) { // tab key
            if (event.target.name === 'skill') {
                if (skillSuggestions.length > 0) {
                    setSearchedSkills('skills', prev => {
                        return needExtraActions ? [...prev, skillSuggestions[0].id] : [...prev, skillSuggestions[0]]
                    });
                    setSkills(value => {
                        if (value.some(val => val.id === skillSuggestions[0].id)) {
                            return [...value];
                        }
                        return [...value, skillSuggestions[0]];
                    });
                    setSkillSuggestions([]);
                    event.target.value = '';
                    needExtraActions && addChips("skills", skillSuggestions[0].id);
                } else {
                    addSkillCall(event.target.value);
                }
            } else if (event.target.name === 'location') {
                setSearchedLocations('locations', prev => {
                    return needExtraActions ? [...prev, locationSuggestions[0].id] : [...prev, locationSuggestions[0]]
                });

                setLocations(value => {
                    if (value.some(val => val.id === locationSuggestions[0].id)) {
                        return [...value];
                    }
                    return [...value, locationSuggestions[0]];
                });
                setLocationSuggestions([]);
                event.target.value = '';
                needExtraActions && addChips("locations", locationSuggestions[0].id);
            }
        }
    }

    const triggerClickUp = (event) => {
        const list = [...document.querySelectorAll('.sugg_p')];
        const active = document.querySelector('.sugg_p.active');
        let i = list.indexOf(active);

        if (event.which === 38 || event.keyCode === 38) { // arrow-keyup
            if (active) {
                active.classList.remove('active')
            }
            const c = document.getElementsByClassName('sugg_p hand-hover');

            if (c.length > 0) {
                if (c[i - 1]) {
                    c[i - 1].classList.add('active');
                    c[i - 1].scrollIntoView({ block: "nearest", inline: "nearest" });
                }
            }
        }
    }

    const triggerSelect = (event) => {
        const active = document.querySelector('.sugg_p.active');

        if (event.which === 13 || event.keyCode === 13) { // enter key
            if (active) {
                active.click();
            }
        }
    }

    return (
        <div className='search-section__wrapper'>
            <div className="search_section my-3">
                <div className="job_search" ref={skillRef}>
                    <div className="search_box">
                        <img onError={commonServices.imgError} src={searchSvg} alt="" />
                        <div className='d-flex f_t_box' id='f_t_box_skills'>
                            {skills.length > 0 && skills.map((skill, k) => {
                                return <div className="f_t" key={k}>
                                    <p>{skill.name}</p>
                                    <button onClick={e => deleteSearchedSkill(e, skill.id)} className="del_jt"><img src={xSvg} alt="" /></button>
                                </div>
                            })}
                        </div>

                        {searchType === 'smart' ?
                            <input type="text" name='skill' placeholder={skills.length > 0 ? '' : 'Skills'} id='skill_search_textbox' onChange={e => onSkillChange(e.target.value)} onKeyPress={e => { addSkills(e); triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)} />
                            : <KeywordSearchTextbox keywordSearch={keyword} setKeywordSearch={setKeyword} FindWork={FindWork} searchBar={"normal"} />}
                    </div>

                    <div className={skillSuggestions.length > 0 ? 'suggestion_box active' : "suggestion_box"}>
                        <div>
                            {skillSuggestions.length > 0 && skillSuggestions.map((suggestion, i) =>
                                <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} onClick={() => onSkillSuggestionClick(suggestion)}>{suggestion.name}</p>)}
                        </div>

                        {skillSuggestions.length > 0 ? ((document.getElementById('skill_search_textbox') && document.getElementById('skill_search_textbox').value.length > 3) ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{ADD_SKILL_SUGGESTION}</div> : <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div>) : null}
                    </div>
                </div>

                <div className="location_search" ref={locationRef}>
                    <div className='search_box'>
                        <img onError={commonServices.imgError} src={mapSvg} alt="" />
                        <div className='d-flex f_t_box' id='f_t_box_locations'>
                            {locations.length > 0 && locations.map((data, k) => {
                                return <div className="f_t" key={k}>
                                    <p>{data.name}, {data.description}</p>
                                    <button onClick={(e) => { deleteSearchedLocation(e, data.id) }} className="del_jt"><img src={xSvg} alt="" /></button>
                                </div>
                            })}
                        </div>
                        <input type="text" name='location' autoComplete='off' placeholder={locations.length > 0 ? ' ' : "City"} id='location_search_textbox' onChange={(e) => onLocationChange(e.target.value, 'JOB_LOCATIONS')} onKeyPress={(e) => { triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)} />
                    </div>

                    <div className={locationSuggestions.length > 0 ? 'suggestion_box active' : "suggestion_box"}>
                        <div>
                            {locationSuggestions.length > 0 && locationSuggestions.map((suggestion, i) =>
                                <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} onClick={() => onLocationSuggestionClick(suggestion)}>{suggestion.name}, {suggestion.description}</p>)}
                        </div>

                        {locationSuggestions.length > 0 && <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div>}
                    </div>
                </div>

                <div onClick={() => FindWork()} className="search_button">
                    <p>Find {module}</p>
                </div>
            </div>

            <SearchType searchType={searchType} setSearchType={setSearchType} keywordSearch={keyword} setKeywordSearch={setKeyword} job_type={searchedSkills} setJob_type={setSearchedSkills} searchBar={searchBar} module={module} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} saveSearchCriteria={saveSearchCriteria} />

            {isStickySearch && <div className='sticky-search'>
                <StickyFindFilter
                    buttonName={module}
                    isStickySearch={isStickySearch}
                    skills={skills}
                    locations={locations}
                    onSkillChange={onSkillChange}
                    addSkills={addSkills}
                    onSkillSuggestionClick={onSkillSuggestionClick}
                    deleteSearchedSkill={deleteSearchedSkill}
                    onLocationChange={onLocationChange}
                    onLocationSuggestionClick={onLocationSuggestionClick}
                    deleteSearchedLocation={deleteSearchedLocation}
                    triggerClickDown={triggerClickDown}
                    triggerClickUp={triggerClickUp}
                    triggerSelect={triggerSelect}
                    searchType={searchType}
                    setSearchType={setSearchType}
                    searchedSkills={searchedSkills}
                    setSearchedSkills={setSearchedSkills}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    searchBar={searchBar}
                    keywordSeachFilters={keywordSeachFilters}
                    setKeywordSeachFilters={setKeywordSeachFilters}
                    activePage={activePage}
                    isKeywordReset={isKeywordReset}
                    setIsKeywordReset={setIsKeywordReset}
                    FindWork={FindWork}
                    skillSuggestions={skillSuggestions}
                    locationSuggestions={locationSuggestions}
                    saveSearchCriteria={saveSearchCriteria}
                    setSkills={setSkills}
                    setLocations={setLocations}
                    setSearchedLocations={setSearchedLocations}
                    setSkillSuggestions={setSkillSuggestions}
                    setLocationSuggestions={setLocationSuggestions}
                    needExtraActions={needExtraActions}
                    addSkillCall={addSkillCall}
                    addChips={addChips}
                />
            </div>}
        </div>
    )
}

export default FilterBar;
