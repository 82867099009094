import { memo } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import "./loader.scss";
import TipsComponent from "../Tips/Tips";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";

const Loader = memo(() => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: var(--green);
    left: calc(50% - 75px);
    position: fixed;
`;
  return (
    <>
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <div className="in_screen_loader loader">
          <SyncLoader
            color={"var(--green)"}
            cssOverride={override}
            size={20}
          />
          <TipsComponent />
        </div>
      </div>
    </>
  );
});

export default Loader;
