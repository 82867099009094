/* eslint-disable */
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import './ShowGigs.scss';
import FilterBar from '../_widgets/FIlterBar/FilterBar';
import Tabs from '../_widgets/Tabs/Tabs';
import AppliedGigs from './TabContent/AppliedGigs';
import SavedGigs from './TabContent/SavedGigs';
import Text from '../_widgets/Text/Text';
import Loader from "../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ShowGigsLayout({ loading, color, currentTab, APPLIED, SAVED, appliedGigs, savedGigs, activePage, setActivePage, handlePaginationChange, tabsData, FindWork, keywordSeachFilters, setKeywordSeachFilters, searchedLocations, setSearchedLocations, searchedSkills, setSearchedSkills, searchType, setSearchType, keyword, setKeyword }) {

    return (

        <div className="show_jobs main-body">
            <div className="body_section tt-container">
                <div className="search mb-3">
                    <Text type="h2" text="My Gigs">

                    </Text>
                    <FilterBar searchedSkills={searchedSkills} setSearchedSkills={setSearchedSkills} searchedLocations={searchedLocations} setSearchedLocations={setSearchedLocations} FindWork={FindWork} searchType={searchType} setSearchType={setSearchType} keyword={keyword} setKeyword={setKeyword} searchBar={"normal"} module={"Gigs"} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />
                </div>

                <div className="result_section">

                    <div>
                        <div className="filter_tab ">
                            <Tabs tabs={tabsData} />
                        </div>
                        <div className="col">
                            <div className="tab-content" id="nav-tabContent">
                                {loading ?
                                    <Loader /> : null}
                                <div className={"tab-pane fade show " + (currentTab === APPLIED ? 'active' : '')} id="list-home" role="tabpanel" aria-labelledby="list-applied-gigs">
                                    <AppliedGigs appliedGigs={appliedGigs} activePage={activePage} setActivePage={setActivePage} handlePaginationChange={handlePaginationChange} />
                                </div>
                                <div className={"tab-pane fade show " + (currentTab === SAVED ? 'active' : '')} id="list-profile" role="tabpanel" aria-labelledby="list-saved-gigs">
                                    <SavedGigs savedGigs={savedGigs} activePage={activePage} setActivePage={setActivePage} handlePaginationChange={handlePaginationChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ShowGigsLayout