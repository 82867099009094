import { ACTIVE, CLOSED } from "@/constants/tabConstants";
import ClipLoader from "react-spinners/ClipLoader";
import Text from "../_widgets/Text/Text";
import Link from "../_widgets/Link/Link";
import Button from "../_widgets/Button/Button";
import IconResource from "../_widgets/IconResource/IconResource";
import CustomTooltip from '../../../src/utils/CustomTooltip'
import Loader from "../_widgets/Loader/Loader";


function PostedGigsLayout({ color, gigData, onTabClick, override, emptyResult, closedGigs, navigate, currentTab, DRAFT, draftGigs, createSlugFromString, commonServices, activeGigs, storageConstants, handleGigClick, loading, type, setType, orderByText, setOrderBy, setOrderByText
}) {
  return (
    <div className="posted_jobs">
      {loading ? (
        <Loader />
      ) : null}
      <div className="body_section tt-container">
        <div className="header">
          <div className="d-flex header_left">
            <Text type="h2" text="Gigs Panel" />
            <Button
              buttonType={"primary"}
              onClick={() => {
                navigate("/gig/add");
              }}
              text={"Post New Gig"}
            />
          </div>

          {/* <div className="filter_tab_header">
            <div className="list-group" id="list-tab-1" role="tablist">
              <Link
                type={"a"}
                text={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <img
                      style={{
                        height: "30px",
                      }}
                      src="./Assets/svgs/list_view_icon.svg"
                      alt=""
                      className="ig"
                    />
                  </div>
                }
                className={"list-group-item list-group-item-action active"}
                dataBsToggle={"list"}
                role={"tab"}
                ariaControls={"list_1_content"}
                aHref={"#list_1_content"}
                id="list_1"
              />
              <Link
                type={"a"}
                className={"list-group-item list-group-item-action"}
                dataBsToggle={"list"}
                role={"tab"}
                ariaControls={"list_2_content"}
                aHref={"#list_2_content"}
                id="list_2"
                text={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <img
                      style={{
                        height: "28px",
                      }}
                      onError={commonServices.imgError}
                      src="./Assets/svgs/grid_view_icon.svg"
                      alt=""
                      className="ig"
                    />
                  </div>
                }
              />
            </div>
          </div> */}
        </div>

        <div className="search_section">
          <div className="d-flex header_left">
            <p className={type === "ALL_GIGS" ? "green-text f-700" : "f-700"}>
              Show company gigs
            </p>
            <label className="switch">
              <input
                type={"checkbox"}
                defaultChecked={type === "MY_GIGS" ? true : false}
                onChange={(e) => {
                  setType(e.target.checked === true ? "MY_GIGS" : "ALL_GIGS");
                }}
                value="1"
              />
              <span className="slider round"></span>
            </label>
            <p className={type === "MY_GIGS" ? "green-text f-700" : "f-700"}>
              Show my gigs only
            </p>
          </div>

          <div className="sort_by  d-flex align-items-center">
            <div className="result_header_sort">
              <p>Sort by:</p>
              <div className="dropdown">
                <Link
                  type={"a"}
                  className={"btn dropdown-toggle"}
                  aHref={"javascript:void(0)"}
                  role={"button"}
                  id="dropdownMenuLink"
                  dataBsToggle={"dropdown"}
                  ariaExpanded={"false"}
                  text={orderByText}
                />

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link
                      type={"a"}
                      className={"dropdown-item"}
                      onClick={() => {
                        setOrderBy("MOST_RECENT");
                        setOrderByText("Newest First");
                      }}
                      aHref={"javascript:void(0)"}
                      text={"Newest First"}
                    />
                  </li>
                  <li>
                    <Link
                      type={"a"}
                      className={"dropdown-item"}
                      onClick={() => {
                        setOrderBy("POST_DATE");
                        setOrderByText("Oldest First");
                      }}
                      aHref={"javascript:void(0)"}
                      text={"Oldest First"}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="filter_tab_header">
              <div className="list-group" id="list-tab-1" role="tablist">
                <a className="list-group-item list-group-item-action active" id="list_1" data-bs-toggle="list" href="#list_1_content" role="tab" aria-controls="list_1_content">
                  <CustomTooltip text="Grid View">
                    <div className='round'>
                      <img onError={commonServices.imgError} src="/Assets/svgs/list_view_icon.svg" alt="" className='ig' />
                    </div>
                  </CustomTooltip>
                </a>


                <a className="list-group-item list-group-item-action" id="list_2" data-bs-toggle="list" href="#list_2_content" role="tab" aria-controls="list_2_content">
                  <CustomTooltip text="Table View">
                    <div className='round'>
                      <img onError={commonServices.imgError} src="/Assets/svgs/grid_view_icon.svg" alt="" className='ig' />
                    </div>
                  </CustomTooltip>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="result_section">
          <div className="col">
            <div className="tab-content" id="nav_tabContent_1">
              <div
                className="tab-pane fade show active"
                id="list_1_content"
                role="tabpanel"
                aria-labelledby="list_1"
              >
                <div>
                  <div className="filter_tab ">
                    <div
                      className="list-group list-group-child"
                      id="list-tab"
                      role="tablist"
                    >
                      <Link
                        type={"a"}
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_1_active"
                        dataBsToggle={"list"}
                        aHref="#list_1_active_content"
                        role={"tab"}
                        ariaControls={"list_1_active_content"}
                        onClick={() => onTabClick(ACTIVE)}
                        text={`Active Gigs ${activeGigs ? `(${activeGigs.length})` : ""
                          }`}
                      />
                      <Link
                        type={"a"}
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        id="list_1_closed"
                        dataBsToggle={"list"}
                        aHref={"#list_1_closed_content"}
                        role={"tab"}
                        ariaControls={"list_1_closed_content"}
                        onClick={() => onTabClick(CLOSED)}
                        text={`Closed Gigs (${closedGigs ? closedGigs.length : ""
                          })`}
                      />
                      <Link
                        type={"a"}
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_1_draft"
                        dataBsToggle={"list"}
                        aHref={"#list_1_draft_content"}
                        role="tab"
                        ariaControls={"list_1_draft_content"}
                        onClick={() => onTabClick(DRAFT)}
                        text={`Drafts (${draftGigs ? draftGigs.length : ""})`}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="tab-content-child tab-content"
                      id="nav-tabContent_1"
                    >
                      <div
                        className={
                          "tab-pane fade show " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_1_active_content"
                        role="tabpanel"
                        aria-labelledby="list_1_active"
                      >
                        {activeGigs && activeGigs.length > 0 ? (
                          <div className="card_section">
                            <div
                              className="posted_card_container"
                              style={{
                                marginTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              {activeGigs &&
                                activeGigs.map((gig, i) => {
                                  return (
                                    <div className="posted-card main-card">
                                      <div
                                        className="card"
                                        onClick={(e) => handleGigClick(e, gig)}>
                                        {gig?.is_featured ?
                                          <div className="bookmark">
                                            <CustomTooltip text="Featured Gig">
                                              <IconResource type="bookmark" color="var(--red)" />
                                            </CustomTooltip>
                                          </div>
                                          : null
                                        }
                                        <div className="card_main box-hover">
                                          <div className="card_header">
                                            <Text
                                              type={"h3"}
                                              text={`ID-G0000${gig.eid}`}
                                            />

                                            {/* <span className="float-right">
                                                                        <p className="btn btn-secondary" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <MoreHorizIcon />
                                                                        </p>

                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                            <li><a className="dropdown-item" href="javascript:void(0)">Close Gig</a></li>
                                                                            <li><a className="dropdown-item" href="javascript:void(0)" style={{ color: 'red' }}>Delete</a></li>
                                                                        </ul>
                                                                    </span> */}
                                          </div>
                                          <div className="card_content">
                                            <Text type="h2" text={gig.title} />
                                            <p>
                                              {gig?.skills !== null &&
                                                gig?.skills !== undefined &&
                                                gig.skills.length > 0
                                                ? gig?.skills
                                                  .map((skill) => skill?.name)
                                                  .toString()
                                                  .split(",")
                                                  .join(", ")
                                                : "N/A"}
                                            </p>
                                            <span>
                                              {gig?.type?.name} | Posted{" "}
                                              {gig.posted_date}
                                            </span>
                                          </div>
                                          <div className="card_buttons">
                                            <div>
                                              <p>{gig.applicants} APPLICANTS</p>
                                            </div>
                                            <div>
                                              <p
                                                className={
                                                  storageConstants.APPLICANTS
                                                }
                                                onClick={(e) =>
                                                  handleGigClick(e, gig)
                                                }
                                              >
                                                {gig.matching_count} MATCHING
                                                WORK PROFILES
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type="h2" text="No active gigs found!" />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "tab-pane fade show " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        id="list_1_closed_content"
                        role="tabpanel"
                        aria-labelledby="list_1_closed"
                      >
                        {closedGigs && closedGigs.length > 0 ? (
                          <div className="card_section">
                            <div
                              className="posted_card_container"
                              style={{
                                marginTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              {closedGigs &&
                                closedGigs.map((gig, i) => {
                                  return (
                                    <div className="posted-card main-card">
                                      <div
                                        className="card"
                                        onClick={() =>
                                          navigate(
                                            "/gig/details/" +
                                            gig.eid +
                                            "/" +
                                            createSlugFromString(gig?.title)
                                          )
                                        }>
                                        {gig?.is_featured ?
                                          <div className="bookmark">
                                            <CustomTooltip text="Featured Gig">
                                              <IconResource type="bookmark" color="var(--red)" />
                                            </CustomTooltip>                                             </div>
                                          : null
                                        }
                                        <div className="card_main box-hover">
                                          <div className="card_header">
                                            <Text
                                              type={"h3"}
                                              text={`ID-G0000${gig.eid}`}
                                            />
                                            {/* <span className='tag-button'>{gig?.status}</span> */}
                                          </div>
                                          <div className="card_content">
                                            <Text type="h2" text={gig.title} />
                                            <p>
                                              {gig?.skills !== null &&
                                                gig?.skills !== undefined &&
                                                gig.skills.length > 0
                                                ? gig?.skills
                                                  .map((skill) => skill?.name)
                                                  .toString()
                                                  .split(",")
                                                  .join(", ")
                                                : "N/A"}
                                            </p>
                                            <span>
                                              {gig?.type?.name} | Posted{" "}
                                              {gig.posted_date}
                                            </span>
                                          </div>
                                          <div className="card_buttons">
                                            <div>
                                              <p>{gig.applicants} APPLICANTS</p>
                                            </div>
                                            <div>
                                              <p
                                                className={
                                                  storageConstants.APPLICANTS
                                                }
                                                onClick={(e) =>
                                                  handleGigClick(e, gig)
                                                }
                                              >
                                                {gig.matching_count} MATCHING
                                                WORK PROFILES
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type="h2" text="No closed gigs found!" />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "tab-pane fade show " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_1_draft_content"
                        role="tabpanel"
                        aria-labelledby="list_1_draft"
                      >
                        {draftGigs && draftGigs.length > 0 ? (
                          <div className="card_section">
                            <div
                              className="posted_card_container"
                              style={{
                                marginTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              {draftGigs &&
                                draftGigs.map((gig, i) => {
                                  return (
                                    <div className="posted-card main-card">
                                      <div
                                        className="card"
                                        onClick={(e) => handleGigClick(e, gig)}
                                      >
                                        <div className="card_main box-hover">
                                          <div className="card_header">
                                            <Text
                                              type={"h3"}
                                              text={`ID-G0000${gig.eid}`}
                                            />
                                          </div>
                                          <div className="card_content">
                                            <Text type="h2" text={gig.title} />
                                            <p>
                                              {gig?.skills !== null &&
                                                gig?.skills !== undefined &&
                                                gig.skills.length > 0
                                                ? gig?.skills
                                                  .map((skill) => skill?.name)
                                                  .toString()
                                                  .split(",")
                                                  .join(", ")
                                                : "N/A"}
                                            </p>
                                            <span>
                                              {gig?.type?.name} | Posted{" "}
                                              {gig.posted_date}
                                            </span>
                                          </div>
                                          <div className="card_buttons">
                                            <div>
                                              <p>{gig.applicants} APPLICANTS</p>
                                            </div>
                                            <div>
                                              <p
                                                className={
                                                  storageConstants.APPLICANTS
                                                }
                                                onClick={(e) =>
                                                  handleGigClick(e, gig)
                                                }
                                              >
                                                {gig.matching_count} MATCHING
                                                WORK PROFILES
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type="h2" text="No draft gigs found!" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="list_2_content"
                role="tabpanel"
                aria-labelledby="list_2"
              >
                <div>
                  <div className="filter_tab ">
                    <div
                      className="list-group list-group-child"
                      id="list-tab"
                      role="tablist"
                    >
                      <Link
                        type={"a"}
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_2_active"
                        dataBsToggle={"list"}
                        aHref={"#list_2_active_content"}
                        role={"tab"}
                        ariaControls={"list_2_active_content"}
                        onClick={() => onTabClick(ACTIVE)}
                        text={`Active Gigs ${activeGigs ? `(${activeGigs.length})` : ""
                          }`}
                      />
                      <Link
                        type={"a"}
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        id="list_2_closed"
                        dataBsToggle={"list"}
                        aHref={"#list_2_closed_content"}
                        onClick={() => onTabClick(CLOSED)}
                        role={"tab"}
                        ariaControls={"list_2_closed_content"}
                        text={`Closed Gigs (${closedGigs ? closedGigs.length : ""
                          })`}
                      />
                      <Link
                        type={"a"}
                        className={
                          "list-group-item list-group-item-child list-group-item-action " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_2_draft"
                        dataBsToggle={"list"}
                        aHref={"#list_2_draft_content"}
                        onClick={() => onTabClick(DRAFT)}
                        role={"tab"}
                        ariaControls={"list_2_draft_content"}
                        text={`Drafts (${draftGigs ? draftGigs.length : ""})`}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="tab-content" id="nav-tabContent_2">
                      <div
                        className={
                          "tab-pane fade show common_items " +
                          (currentTab === ACTIVE ? "active" : "")
                        }
                        id="list_2_active_content"
                        role="tabpanel"
                        aria-labelledby="list_2_active"
                      >
                        {activeGigs && activeGigs.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Gig ID</th>
                                <th scope="col">Gig</th>
                                <th scope="col">Skills</th>
                                <th scope="col">No. of Applicants</th>
                                <th scope="col">Matching Work Profiles</th>
                                <th>Posted By</th>
                                <th>Posted On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {activeGigs &&
                                activeGigs.map((gig, i) => {
                                  return (
                                    <tr
                                      className="hovering position-relative"
                                      onClick={() =>
                                        navigate(
                                          "/gig/details/" +
                                          gig.eid +
                                          "/" +
                                          createSlugFromString(gig?.title)
                                        )
                                      }
                                    >
                                      <td>G0000{gig.eid}</td>
                                      <td className="bold">{gig.title}</td>
                                      <td>
                                        {gig?.skills !== null &&
                                          gig?.skills !== undefined &&
                                          gig.skills.length > 0
                                          ? gig?.skills
                                            .map((skill) => skill?.name)
                                            .toString()
                                            .split(",")
                                            .join(", ")
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {gig.applicants}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {gig.matching_count !== null
                                            ? gig.matching_count
                                            : "0"}
                                        </div>
                                      </td>
                                      <td>{gig?.posted_by}</td>
                                      <td>{gig.posted_date}</td>
                                      {gig?.is_featured ?
                                        (<td colSpan={2} className="bookmark" style={{ right: "-12px", top: "-10px" }}>
                                          <CustomTooltip text="Featured Gig">
                                            <IconResource type="bookmark" color="var(--red)" />
                                          </CustomTooltip>
                                        </td>
                                        ) : null}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type="h2" text="No active gig found!" />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "tab-pane fade show common_items " +
                          (currentTab === CLOSED ? "active" : "")
                        }
                        id="list_2_closed_content"
                        role="tabpanel"
                        aria-labelledby="list_2_closed"
                      >
                        {closedGigs && closedGigs.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Gig ID</th>
                                <th scope="col">Gig</th>
                                <th scope="col">Skills</th>
                                <th scope="col">No. of Applicants</th>
                                <th scope="col">Matching Work Profiles</th>
                                <th>Posted By</th>
                                <th>Posted On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {closedGigs &&
                                closedGigs.map((gig, i) => {
                                  return (
                                    <tr
                                      className="Hovering position-relative"
                                      onClick={() =>
                                        navigate(
                                          "/gig/details/" +
                                          gig.eid +
                                          "/" +
                                          createSlugFromString(gig?.title)
                                        )
                                      }
                                    >
                                      <td>G0000{gig.eid}</td>
                                      <td className="bold">{gig.title}</td>
                                      <td>
                                        {gig?.skills !== null &&
                                          gig?.skills !== undefined &&
                                          gig.skills.length > 0
                                          ? gig?.skills
                                            .map((skill) => skill?.name)
                                            .toString()
                                            .split(",")
                                            .join(", ")
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {gig.applicants}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {gig.matching_count !== null
                                            ? gig.matching_count
                                            : "0"}
                                        </div>
                                      </td>
                                      <td>{gig?.posted_by}</td>
                                      <td>{gig.posted_date}</td>
                                      {gig?.is_featured ?
                                        (<td colSpan={2} className="bookmark" style={{ right: "-12px", top: "-10px" }}>
                                          <CustomTooltip text="Featured Gig">
                                            <IconResource type="bookmark" color="var(--red)" />
                                          </CustomTooltip>
                                        </td>
                                        ) : null}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type="h2" text="No closed gigs found!" />
                          </div>
                        )}{" "}
                      </div>
                      <div
                        className={
                          "tab-pane fade show common_items " +
                          (currentTab === DRAFT ? "active" : "")
                        }
                        id="list_2_draft_content"
                        role="tabpanel"
                        aria-labelledby="list_2_draft"
                      >
                        {draftGigs && draftGigs.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Gig ID</th>
                                <th scope="col">Gig</th>
                                <th scope="col">Skills</th>
                                <th scope="col">No. of Applicants</th>
                                <th scope="col">Matching Work Profiles</th>
                                <th>Posted by</th>
                                <th>Posted On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {draftGigs &&
                                draftGigs.map((gig, i) => {
                                  return (
                                    <tr
                                      className="hovering"
                                      onClick={() =>
                                        navigate(
                                          "/gig/details/" +
                                          gig.eid +
                                          "/" +
                                          createSlugFromString(gig?.title)
                                        )
                                      }
                                    >
                                      <td>G0000{gig.eid}</td>
                                      <td className="bold">{gig.title}</td>
                                      <td>
                                        {gig?.skills !== null &&
                                          gig?.skills !== undefined &&
                                          gig.skills.length > 0
                                          ? gig?.skills
                                            .map((skill) => skill?.name)
                                            .toString()
                                            .split(",")
                                            .join(", ")
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {gig.applicants}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="number-div">
                                          {gig.matching_count !== null
                                            ? gig.matching_count
                                            : "0"}
                                        </div>
                                      </td>
                                      <td>{gig?.posted_by}</td>
                                      <td>{gig.posted_date}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="no_jobs">
                            <img
                              onError={commonServices.imgError}
                              src={emptyResult}
                              alt=""
                            />
                            <Text type="h2" text="No draft gigs found!" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostedGigsLayout;
