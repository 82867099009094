/* eslint-disable */

import React from "react";

import "./MyAccount.scss";
import ModalAddComp from "../Modals/ModalAddComp";

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as storageConstants from "@/constants/storageConstants";
import * as commonServices from "@/services/common";
import defaultAvatar from "@/Assets/svgs/default_avatar.svg";
import { APP_NAME } from "@/constants/storageConstants";
import InputMask from "react-input-mask";
import ModalDeleteAccount from "../Modals/ModalDeleteAccount";
import ModalContactDetails from "../Modals/ModalContactDetails";
import { getSkillsString, textCapitalise } from "@/utils/stringUtils";
import BecomePro from "../BecomePro/BecomePro";
import BecomeProEvaluated from "../BecomePro/BecomeProEvaluated";
import {
  BsFillPersonPlusFill,
  BsCalendar,
  BsFillQuestionSquareFill,
} from "react-icons/bs";
import { isCandidate } from "@/utils/authUtil";
import Text from "../_widgets/Text/Text";

import BlinkingPointer from "@/Assets/gifs/pointer.gif";

import { FiInfo } from "react-icons/fi";

// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from "react-confetti";
import Input from "../_widgets/Input/Input";

import deleteIcon from "../../Assets/images/deleteIcon.png";
import {
  canCancel,
  canJoin,
  isCancelled,
  isPostInterview,
  isRescheduled,
} from "../../utils/interview";
import DatePickerWrapper from "../Common/DatePicker";
import ModalBuyCash from "../Modals/ModalBuyCash";
import ModalSmartApply from "../Modals/ModalSmartApply";
import ModalInformation from "../Modals/ModalInformation";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import Button from "../_widgets/Button/Button";
import Checkbox from "../_widgets/Checkbox/Checkbox";
import MyText from "../_widgets/Text/Text";
import ModalViewTransactionsPagination from "../Modals/ModalViewTransactionsPagination";
import AppModal from "../Modals/AppModal";
import { changeDateFormat } from "../../utils/dateUtil";
import { Badge, Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { COUNTRY } from "../../constants/storageConstants";
import Link from "../_widgets/Link/Link";
import Loader from "../_widgets/Loader/Loader";
import { genderOptions } from "../../utils/dropdownUtils";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function MyAccountLayout({
  profile,
  setProfile,
  freeTTCash,
  showConfetti,
  setShowConfetti,
  loading,
  showModal,
  setShowModal,
  companySearch,
  loadCompany,
  showModalDeleteAccount,
  setShowModalDeleteAccount,
  showModalError,
  setShowModalError,
  modalErrorData,
  setModalErrorData,
  showModalViewTransactions,
  setShowModalViewTransactions,
  isCompanyTransactions,
  headingText,
  showInformationModal,
  setShowInformationModal,
  informationModalTitle,
  informationModalText,
  showModalBuyCash,
  setShowModalBuyCash,
  ttCashDollar,
  getLatestProfile,
  fileRef,
  currentTab,
  userRole,
  datePickerRef,
  updateProfile,
  isFullTimeChecked,
  masterData,
  isGigChecked,
  gigType,
  locationRef,
  locSuggestion,
  preferredLocationName,
  preferred_location,
  skillRef,
  skillSuggestion,
  preferredSkillName,
  preferredSkills,
  updatePreferences,
  openModal,
  suggestions,
  company,
  oldPasswordShown,
  old_password,
  newPasswordShown,
  new_password,
  confirmNewPasswordShown,
  confirm_password,
  updatePass,
  candidateInterview,
  userTransactions,
  isDeleteOpen,
  onDeleteClose,
  reason,
  isActive,
  color,
  blocked_count,
  setAdd_picture,
  normalizePhoneNumber,
  onChange,
  dropdownGenderOptions,
  setIsFullTimeChecked,
  setGigType,
  setIsGigChecked,
  onJobTypeChange,
  onGigTypeChange,
  onChangeHandler_location,
  setPreferred_location,
  setPreferredLocationName,
  setPreferredSkills,
  setPreferredSkillName,
  onChangeHandlerSkill,
  del_skill,
  openBuyCashModal,
  del_location,
  setMinSalaryValue,
  onChangeHandler,
  BlockCompany,
  UnBlockCompany,
  setSuggestions,
  updateInterviewStatus,
  triggerClickDown,
  triggerSelect,
  addSkill,
  setOld_password,
  setNew_password,
  setConfirm_password,
  becomePro,
  setRefresh,
  selectedInterview,
  setSelectedInterview,
  isNoShowOpen,
  onNoShowClose,
  setReason,
  onNoShowOpen,
  onDeleteOpen,
  canInterviewReschedule,
  getTransactionData,
  transactionData,
  handlePaginationChange,
  pageNumber,
  smartApply,
  autoApply,
  setAutoApply,
  minMatchPercentage,
  setMinMatchPercentage,
  showSmartApplyModal,
  setShowSmartApplyModal,
  navigate,
  smartApplySubscriptionInfo,
  todayDate,
  oneMonthFromToday,
  isSubscriptionActive,
  setIsSubscriptionActive,
}) {
  let selectedDate = "";
  const dateOfBirth = profile?.date_of_birth;
  if (dateOfBirth !== null && dateOfBirth !== undefined) {
    selectedDate = new Date(dateOfBirth);
  }

  if (currentTab === "preferences") {
    document.getElementById("list-profile-list").click();
  }
  return (
    <>
      {(freeTTCash.state && freeTTCash.success) || showConfetti ? (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      ) : null}
      {loading ? (
        <Loader />
      ) : null}
      <div
        className="my-account__wrapper"
        style={{
          minHeight: "97vh",
        }}
      >
        <ModalAddComp
          showModal={showModal}
          setShowModal={setShowModal}
          companySearch={companySearch}
          loadCompany={loadCompany}
        />
        <ModalDeleteAccount
          showModal={showModalDeleteAccount}
          setShowModal={setShowModalDeleteAccount}
          showModalError={showModalError}
          setShowModalError={setShowModalError}
          modalErrorData={modalErrorData}
          setModalErrorData={setModalErrorData}
        />
        <ModalContactDetails
          showModal={showModalError}
          setShowModal={setShowModalError}
          modalData={modalErrorData}
        />

        {/* New View-Transactions Modal */}
        <ModalViewTransactionsPagination
          loading={loading}
          showModal={showModalViewTransactions}
          setShowModal={setShowModalViewTransactions}
          transactions={transactionData}
          isCompanyTransactions={isCompanyTransactions}
          headingText={headingText}
          getTransactions={getTransactionData}
          activePage={pageNumber}
          handlePaginationChange={handlePaginationChange}
        />

        <ModalInformation
          showModal={showInformationModal}
          setShowModal={setShowInformationModal}
          title={informationModalTitle}
          text={informationModalText}
          setShowConfetti={setShowConfetti}
        />
        <ModalBuyCash
          showModal={showModalBuyCash}
          setShowModal={setShowModalBuyCash}
          ttCashDollar={ttCashDollar}
          companyId={profile?.id}
          getUsers={getLatestProfile}
        />

        {/* MODAL SMARTAPPLY */}
        <ModalSmartApply
          showModal={freeTTCash?.showModal}
          setShowModal={(state) =>
            setFreeTTCash({ ...freeTTCash, success: false, showModal: state })
          }
        />

        <div className="my-account__container tt-container">
          <div className="my-account__info">
            <h2>My Account</h2>
            {profile && (
              <div className="my-account__info_left">
                <div className="round_profile_body img-border">
                  <img
                    onError={commonServices.imgError}
                    src={profile?.avatar_location ?? defaultAvatar}
                    alt=""
                  />
                </div>
                <button id="add_img">
                  <input
                    ref={fileRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      setAdd_picture(e.target.files[0]);
                    }}
                  />
                  <img src="../../Assets/svgs/add_image.svg" alt="" />
                </button>

                <div className="my-account__info_right">
                  <h3>{profile?.full_name}</h3>
                  <p>{profile?.email}</p>
                </div>
              </div>
            )}
          </div>

          <div className="my-account__detail">
            {/* Tab List -- Starts  */}
            <div className="list-group" id="list-tab" role="tablist">
              <a
                className={
                  "list-group-item list-group-item-action " +
                  (currentTab === "profile" ? "active" : "")
                }
                id="list-home-list"
                data-bs-toggle="list"
                href="#list-home"
                role="tab"
                aria-controls="list-home"
              >
                Profile
              </a>
              {userRole === "CD" ? (
                <>
                  <a
                    className={
                      "list-group-item list-group-item-action " +
                      (currentTab === "preferences" ? "active" : "")
                    }
                    id="list-profile-list"
                    data-bs-toggle="list"
                    href="#list-profile"
                    role="tab"
                    aria-controls="list-profile"
                    aria-selected={currentTab === "preferences"}
                  >
                    Preferences
                  </a>

                  <a
                    className={
                      "list-group-item list-group-item-action " +
                      (currentTab === "smart_apply" ? "active" : "")
                    }
                    id="list-auto-apply-list"
                    data-bs-toggle="list"
                    href="#list-auto-apply"
                    role="tab"
                    aria-controls="list-auto-apply"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    SmartApply
                  </a>

                  <a
                    className="list-group-item list-group-item-action"
                    id="list-messages-list"
                    data-bs-toggle="list"
                    href="#list-messages"
                    role="tab"
                    aria-controls="list-messages"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Work Profile Settings
                  </a>
                </>
              ) : null}
              <a
                className="list-group-item list-group-item-action"
                id="list-settings-list"
                data-bs-toggle="list"
                href="#list-settings"
                role="tab"
                aria-controls="list-settings"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Change Password
              </a>
              {isCandidate() ? (
                <a
                  className={
                    "list-group-item list-group-item-action " +
                    (currentTab === "interview" ? "active" : "")
                  }
                  id="list-interview-list"
                  data-bs-toggle="list"
                  href="#list-interview"
                  role="tab"
                  aria-controls="list-interview"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Get Evaluated
                </a>
              ) : null}
              {/* {userRole && userRole === 'CU' ? (<a className="list-group-item list-group-item-action" id="user-transactions-list" data-bs-toggle="list" href="#user-transactions" role="tab" aria-controls="user-transactions">TT Cash</a>) : null} */}
            </div>
            {/* Tab List -- Ends  */}

            {/* Tab-Pane Container -- Starts  */}
            <div className="tab-content" id="nav-tabContent">
              {/* Profile Tab __ Pane -- Ends  */}
              <div
                className={
                  "tab-pane fade profile__tab-pane show " +
                  (currentTab === "profile" ? "active" : "")
                }
                id="list-home"
                role="tabpanel"
                aria-labelledby="list-home-list"
              >
                <form>
                  <div className="row row-gap-2">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <Input
                        type="text"
                        label="First Name"
                        value={profile?.first_name}
                        id="first_name"
                        isRequired
                        name="first_name"
                        placeholder="Enter Name"
                        onChange={(e) => onChange(e)}
                      />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <Input
                        type="text"
                        label="Last Name"
                        value={profile?.last_name}
                        id="last_name"
                        placeholder="Last Name"
                        isRequired
                        name="last_name"
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <Input
                        type="phone"
                        label="Phone"
                        id="contact_number"
                        isRequired
                        name="contact_number"
                        placeholder="Enter Phone"
                        defaultCountry={COUNTRY}
                        value={
                          profile?.alternate_phone_number
                            ? normalizePhoneNumber(profile?.alternate_phone_number)
                            : ""
                        }
                        onChange={(phoneNumber) => {
                          setProfile({ ...profile, alternate_phone_number: phoneNumber });
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <DatePickerWrapper
                        label="Date of birth"
                        inputClassName="mb-0"
                        ref={datePickerRef}
                        customInput={<InputMask mask="99/99/9999" />}
                        name="date_of_birth"
                        selected={selectedDate ? selectedDate : null}
                        onChange={(date) => {
                          datePickerRef?.current?.setOpen(false);
                          setProfile({ ...profile, date_of_birth: date });
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                        scrollableYearDropdown
                      />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <Dropdown
                        label={"Gender"}
                        id="gender"
                        name="gender"
                        value={profile?.gender}
                        onChange={(e) => onChange(e)}
                        select={genderOptions}
                      />
                    </div>
                    {isCandidate() && (
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <div className="d-flex gap-3 align-items-center justify-content-center">
                            <div>
                              <Input
                                type="text"
                                label="TT Cash"
                                id="TT"
                                value={
                                  commonServices.currencyFormatter(
                                    profile?.remaining_views
                                  ) || 0
                                }
                                disabled
                                onClickIconSuff={() => {
                                  getTransactionData();
                                  setShowModalViewTransactions(true);
                                }}
                                suffixIcon={"info"}
                              />
                            </div>
                            <Text
                              style={{ color: "var(--black2)" }}
                              type="t-icon"
                              tIcon={
                                <FiInfo
                                  size={14}
                                  style={{
                                    marginLeft: "10px",
                                    color: "var(--gray7)",
                                    marginTop: "30px"
                                  }}
                                />
                              }
                              className="flex-row-reverse"
                              cursor={"pointer"}
                              fontSize={"sm"}
                              fontWeight={500}
                              onClick={() => {
                                getTransactionData();
                                setShowModalViewTransactions(true);
                              }}
                              display={"flex"}
                              alignItems={"center"}
                            />
                            <Text
                              text="Buy More"
                              type=""
                              style={{
                                cursor: "pointer",
                                color: "var(--green)",
                                fontSize: "var(--p)",
                                fontWeight: 500,
                                marginTop: "30px"
                              }}
                              onClick={() => {
                                openBuyCashModal();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="button-group justify-content-sm-end">
                    <Button
                      buttonType="danger"
                      text="Delete Account"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModalDeleteAccount(!showModalDeleteAccount);
                      }}
                    />
                    <Button
                      buttonType="primary"
                      text="Save Changes"
                      onClick={updateProfile}
                    />
                  </div>
                </form>
              </div>
              {/* Profile Tab __ Pane -- Ends  */}

              {/* Preferences Tab __ Pane -- Starts  */}
              <div
                className={
                  "tab-pane fade show preferences__tab-pane" +
                  (currentTab === "preferences" ? "active" : "")
                }
                id="list-profile"
                role="tabpanel"
                aria-labelledby="list-profile-list"
              >
                <div className="row row-gap-2 mb-3">
                  <div className="col-lg-4 col-md-4 col-sm-12 list_profile_heading">
                    <h2>Job Preferences</h2>
                    <p>You can select multiple job types</p>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className="row">
                      <div className="form-check col-6">
                        <Checkbox
                          id={"full_time" + 1}
                          label={"Full Time"}
                          checked={isFullTimeChecked}
                          onChange={() => {
                            setProfile({ ...profile, job_type: [] });
                            setIsFullTimeChecked(!isFullTimeChecked);
                          }}
                        />

                        <br />
                        <div className="mt-3">
                          <h2
                            style={{
                              marginLeft: "-1.25em",
                            }}
                            className={isFullTimeChecked ? "required" : ""}
                          >
                            Employment Type
                          </h2>
                          {masterData?.job_types &&
                            masterData?.job_types.map((type, i) => (
                              <div
                                className={
                                  isFullTimeChecked
                                    ? "mt-2 mb-3"
                                    : "mt-2 mb-3 fade-btn"
                                }
                              >
                                <Checkbox
                                  label={type?.name}
                                  value={type?.id}
                                  disabled={!isFullTimeChecked}
                                  id={"employment-type" + i}
                                  checked={profile?.job_type?.includes(type.id)}
                                  onChange={(e) => {
                                    onJobTypeChange(e);
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="form-check col-6">
                        <Checkbox
                          label={"Gig"}
                          id={"gigcheck" + 2}
                          checked={isGigChecked}
                          onChange={() => {
                            setGigType([]);
                            setIsGigChecked(!isGigChecked);
                          }}
                        />
                        <br />
                        <div className="mt-3">
                          <h2
                            style={{
                              marginLeft: "-1.25em",
                            }}
                            className={isGigChecked ? "required" : ""}
                          >
                            Gig Type
                          </h2>
                          {masterData?.engagement_mode &&
                            masterData?.engagement_mode.map(
                              (engagement_mode, i) => (
                                <div
                                  className={
                                    isGigChecked
                                      ? "mt-2 mb-3"
                                      : "mt-2 mb-3 fade-btn"
                                  }
                                >
                                  <Checkbox
                                    label={engagement_mode.name}
                                    value={engagement_mode.id}
                                    disabled={!isGigChecked}
                                    id={"gigtypecheck" + i}
                                    checked={gigType.includes(
                                      engagement_mode.id
                                    )}
                                    onChange={(e) => {
                                      onGigTypeChange(e);
                                    }}
                                  />
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-gap-2 mb-3">
                  <div className="col-lg-4 col-md-4 col-sm-12 list_profile_heading">
                    <h2>Preferred Location</h2>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <div ref={locationRef}>
                      <Dropdown
                        type="suggestonWithIcon"
                        suggestionArray={locSuggestion}
                        onSuggestionClick={(suggestion) => {
                          if (
                            ![...preferred_location].includes(suggestion.id)
                          ) {
                            setPreferred_location([
                              ...preferred_location,
                              suggestion.id,
                            ]);
                          }

                          if (
                            ![...preferred_location].includes(
                              parseInt(suggestion.id)
                            )
                          ) {
                            setPreferredLocationName([
                              ...preferredLocationName,
                              suggestion,
                            ]);
                          }
                          document.getElementById("search_location").value = "";
                          onChangeHandler_location("");
                        }}
                        placeholder={"Type to search for location"}
                        icon="mapPin"
                        id="search_location"
                        onChange={(e) =>
                          onChangeHandler_location(e.target.value)
                        }
                        name="locations"
                        onKeyPress={(e) => {
                          triggerSelect(e);
                        }}
                        onKeyDown={(e) => triggerClickDown(e)}
                      />
                    </div>
                    <div className="d-flex f_t_box">
                      {preferredLocationName ? (
                        <>
                          {preferredLocationName.map((data, k) => {
                            if (preferred_location.includes(data.id))
                              return (
                                <div className="f_t" key={k}>
                                  <p>
                                    {data.name}, {data.description}
                                  </p>
                                  <button
                                    onClick={(e) => {
                                      console.log("delete location");
                                      del_location(e, data.id);
                                    }}
                                    className="del_jt"
                                  >
                                    <img src="../../Assets/svgs/x.svg" alt="" />
                                  </button>
                                </div>
                              );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row row-gap-2 mb-3">
                  <div className="col-lg-4 col-md-4 col-sm-12 list_profile_heading">
                    <h2>Preferred Skills</h2>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <p>Set your preferred skills</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={
                          profile?.is_preferred_skills === "1" ? true : false
                        }
                        onChange={(e) => {
                          setProfile({
                            ...profile,
                            is_preferred_skills:
                              e.target.checked === true ? "1" : "0",
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <div ref={skillRef}>
                      {profile?.is_preferred_skills === "1" ? (
                        <Dropdown
                          type="suggestonWithIcon"
                          suggestionArray={skillSuggestion}
                          onSuggestionClick={(suggestion) => {
                            if (![...preferredSkills].includes(suggestion.id)) {
                              setPreferredSkills([
                                ...preferredSkills,
                                suggestion.id,
                              ]);
                              setPreferredSkillName([
                                ...preferredSkillName,
                                suggestion,
                              ]);
                            }
                            document.getElementById("search_skills").value = "";
                            onChangeHandlerSkill("");
                          }}
                          id="search_skills"
                          autoComplete="off"
                          placeholder="Type to search for skills"
                          icon="search"
                          onChange={(e) =>
                            onChangeHandlerSkill(e.target.value, e)
                          }
                          onKeyPress={(e) => {
                            addSkill(e);
                            triggerSelect(e);
                          }}
                          name="skills"
                          onKeyDown={(e) => triggerClickDown(e)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex f_t_box">
                      {profile?.is_preferred_skills === "1" &&
                        preferredSkillName ? (
                        <>
                          {preferredSkillName.map((data, k) => {
                            if (preferredSkills.includes(data.id))
                              return (
                                <div className="f_t" key={k}>
                                  <p>{data.name}</p>
                                  <button
                                    onClick={(e) => {
                                      del_skill(e, data.id);
                                    }}
                                    className="del_jt"
                                  >
                                    <img src="../../Assets/svgs/x.svg" alt="" />
                                  </button>
                                </div>
                              );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row row-gap-2">
                  <div className="col-lg-4 col-md-4 col-sm-12 list_profile_heading">
                    <h2>Expected Salary</h2>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <Input
                      type="iconLabelTextBox"
                      label={"Minimum Expected Salary"}
                      placeholder="XXXXXX"
                      id="min_salary"
                      value={
                        profile?.min_salary
                          ? commonServices.currencyFormatter(
                            profile?.min_salary
                          )
                          : ""
                      }
                      autoComplete="off"
                      name="min_salary"
                      icon="rupee"
                      onChange={(e) => setMinSalaryValue(e)}
                    />
                  </div>
                </div>

                <hr />

                <div className="row row-gap-2">
                  <div className="col-lg-4 col-md-4 col-sm-12 list_profile_heading">
                    <h2>Notification Preferences</h2>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <Checkbox
                      label={"Get emails for new matching jobs posted"}
                      id={"job_post_notify"}
                      defaultChecked={
                        profile?.notification_new_jobs !== null &&
                          profile?.notification_new_jobs.toString() === "1"
                          ? true
                          : false
                      }
                      value="1"
                      onChange={(e) => handleNotificationNewJob(e)}
                    />

                    <Checkbox
                      label={"Get emails when recruiters view my Work Profile"}
                      id={"workprofile_view_notify"}
                      defaultChecked={
                        profile?.notification_profile_viewed !== null &&
                          profile?.notification_profile_viewed.toString() === "1"
                          ? true
                          : false
                      }
                      value="1"
                      onChange={(e) => handleNotificationProfileViewed(e)}
                    />
                  </div>
                  <div className="col-12">
                    <div className="button-group justify-content-end">
                      <Button
                        buttonType="primary"
                        type="submit"
                        onClick={updatePreferences}
                        text="Save Changes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Preferences Tab __ Pane -- Ends  */}

              {/* SmartApply Tab __ Pane -- Starts  */}
              <div
                className={
                  "tab-pane fade smart-apply__tab-pane show " +
                  (currentTab === "smart_apply" ? "active" : "")
                }
                id="list-auto-apply"
                role="tabpanel"
                aria-labelledby="list-auto-apply-list"
              >
                {/* SmartApply Info Modal */}
                <AppModal
                  title="SmartApply Subscription"
                  isOpen={showSmartApplyModal}
                  isFooter={true}
                  closeModal={() => {
                    setShowSmartApplyModal(false);
                    setAutoApply(false);
                  }}
                  submitText="Enable"
                  submit={smartApply}
                >
                  <div className="modal-body">
                    <MyText
                      type="h6"
                      className="mb-3"
                      text={`The Monthly Subscription to TT SmartApply is ${masterData?.system_config?.smart_apply_monthly_charge?.value} TT Cash`}
                    />
                    <h6>
                      The effective date of Subscription would be from{" "}
                      {todayDate()} to {oneMonthFromToday()}
                    </h6>
                    <div className="d-flex align-items-center gap-2 mt-3">
                      <Badge style={{ background: "var(--light-blue) !important", color: "var(--green)", border: "1px dashed var(--green)" }} className="py-3 px-2">
                        Total Balance: {
                                  commonServices.currencyFormatter(
                                    profile?.remaining_views
                                  ) || 0
                                } TT Cash
                      </Badge>
                      <a
                        href="javascript:void(0)"
                        style={{
                          fontSize: "0.94em",
                          fontWeight: "500",
                          color: "var(--green)",
                          textDecoration: "underline",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          openBuyCashModal();
                        }}
                      >
                        Buy/Allocate
                      </a>
                    </div>
                  </div>
                </AppModal>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <form className="account-form__wrapper">
                      <div className="d-flex position-relative gap-3">
                        <label
                          className="account-form__label label-lg"
                          for="auto-apply"
                        >
                          Enable SmartApply?
                        </label>
                        <Form>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="auto-apply"
                            checked={autoApply}
                            value={autoApply}
                            onChange={(e) => {
                              if (e.target.checked) {
                                if (!isSubscriptionActive) {
                                  setShowSmartApplyModal(true);
                                }
                                setAutoApply(true);
                              } else {
                                setAutoApply(false);
                              }
                            }}
                          />
                        </Form>

                        {freeTTCash.state && freeTTCash.step === 1 && (
                          <img
                            src={BlinkingPointer}
                            width={"30px"}
                            alt={"free cash"}
                            style={{
                              position: "absolute",
                              right: "-14px",
                              bottom: "-14px",
                            }}
                          />
                        )}
                      </div>

                      {isSubscriptionActive ? (
                        <div className="green-text mt-3 font-11">
                          <MyText
                            type="h2"
                            className="mb-1"
                            text={`Your Current TT SmartApply Subscription is Active:`}
                          />
                          <MyText
                            type="h2"
                            className="mb-1"
                            text={`Subscription Start Date: ${changeDateFormat(
                              smartApplySubscriptionInfo?.from_date
                            )}`}
                          />
                          <MyText
                            type="h2"
                            text={`Subscription Active Till: ${changeDateFormat(
                              smartApplySubscriptionInfo?.to_date
                            )}`}
                          />
                        </div>
                      ) : (
                        <div
                          className="mt-3 fst-italic"
                          style={{ color: "var(--gray8)" }}
                        >
                          <MyText type="h2" text="No Active Subscription" />
                        </div>
                      )}

                      <h3 className="account-form__title mt-3">
                        SmartApply Preferences
                      </h3>

                      <div className="w-100 mb-3">
                        <div className="d-flex align-items-center position-relative">
                          <Input
                            w={"100%"}
                            type={"number"}
                            fontSize={"sm"}
                            label="SmartApply when your profile matches the job
                              opening by"
                            placeholder={"Enter Percentage"}
                            value={minMatchPercentage ?? 60}
                            max={100}
                            min={0}
                            disabled={!autoApply}
                            onChange={(e) =>
                              setMinMatchPercentage(parseInt(e.target.value))
                            }
                          />

                          <Text
                            text="%"
                            type=""
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "16px",
                              color: "var(--gray4)", // Assuming you have a CSS variable for gray.500
                            }}
                          />
                        </div>
                      </div>

                      <div className="button-group justify-content-end mt-4 mb-5">
                        <Button
                          disabled={!isSubscriptionActive}
                          onClick={(e) => {
                            e.preventDefault();
                            smartApply();
                          }}
                          className="btn tt-btn__primary position-relative"
                          text="Save"
                        >
                          {freeTTCash.state && freeTTCash.step === 2 && (
                            <img
                              src={BlinkingPointer}
                              width={"30px"}
                              alt={"free cash"}
                              style={{
                                position: "absolute",
                                right: "-30px",
                                bottom: "-20px",
                              }}
                            />
                          )}
                        </Button>
                      </div>
                    </form>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="info-box info-box--grey-100">
                      <h4 className="info-box__title">How it works?</h4>

                      <p className="info-box__details">
                        TT SmartApply - The future of AI-driven job hunting is
                        here! <br />
                        <br />
                        TT SmartApply is your personalized job application
                        assistant. It utilizes advanced algorithms and your
                        profile data to automatically apply to job postings that
                        perfectly match your qualifications and preferences.
                        Once a suitable job is detected, SmartApply takes the
                        initiative and applies to it on your behalf and notifies
                        you.
                        <br />
                        <br />
                        <strong>Benefits of TT SmartApply:</strong>
                        <ul>
                          <li>
                            Time-Saving: Spend less time on the application
                            process and more time preparing for interviews.
                          </li>
                          <li>
                            Increased Visibility: Never miss out on relevant job
                            opportunities.
                          </li>
                          <li>
                            Efficiency: No need to manually search and apply for
                            jobs; let SmartApply do the heavy lifting.
                          </li>
                          <li>
                            Confidentiality: Rest assured, your data is secure,
                            and applications are submitted discreetly.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* SmartApply Tab __ Pane -- Ends  */}

              {/* Work Profile Preferences Tab __ Pane -- Starts  */}
              <div
                className="tab-pane work-profile__tab-pane fade common_items"
                id="list-messages"
                role="tabpanel"
                aria-labelledby="list-messages-list"
              >
                <div className="row mb-3">
                  <div className="col-8">
                    <h2>Work Profile Preferences</h2>
                    <p className="mb-3">
                      Restrict the following companies from viewing my Work
                      Profile:
                    </p>
                    <p id="addNewC" className="d-none">
                      Looks like this company is not yet available on {APP_NAME}
                      ! Click
                      <button className="addComp" onClick={openModal}>
                        HERE
                      </button>
                      to add it? (Doing this will still block your profile as
                      and when it is available)
                    </p>

                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <img
                          className="input-icon"
                          onError={commonServices.imgError}
                          src="./Assets/svgs/search.svg"
                          alt=""
                        />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="search_job"
                        placeholder="Type to search"
                        onChange={(e) => onChangeHandler(e.target.value)}
                      />
                    </div>
                    <div className="suggestion_box">
                      {suggestions &&
                        suggestions.map((suggestion, i) => (
                          <p
                            key={i}
                            onClick={() => {
                              BlockCompany(suggestion.id);
                              setSuggestions();
                              document.getElementById("search_job").value = "";
                            }}
                          >
                            {suggestion.name}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Company Name</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {company &&
                      company.map((cmp, i) => {
                        if (!showModal)
                          if (cmp.is_blocked === 1) {
                            blocked_count++;
                            return (
                              <tr key={i}>
                                <td>{blocked_count}</td>
                                <td className="bold">{cmp.name}</td>
                                <td className="red">
                                  <button
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={(e) => UnBlockCompany(cmp.id)}
                                  >
                                    <img
                                      onError={commonServices.imgError}
                                      src="./Assets/svgs/trash.svg"
                                      alt=""
                                    />
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        return null;
                      })}
                  </tbody>
                </table>
              </div>
              {/* Work Profile Preferences Tab __ Pane -- Ends  */}

              {/* Change Password Tab __ Pane -- Starts  */}
              <div
                className="tab-pane change-pass__tab-pane fade"
                id="list-settings"
                role="tabpanel"
                aria-labelledby="list-settings-list"
              >
                <form>
                  <div className="row row-gap-2">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <Input
                            type={"password"}
                            label="Old Password"
                            value={old_password}
                            placeholder="Enter old Password"
                            onChange={(e) => setOld_password(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <Input
                            type={"password"}
                            label="New Password"
                            value={new_password}
                            placeholder="Enter New Password"
                            onChange={(e) => setNew_password(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <Input
                            type={"password"}
                            label="Confirm New Password"
                            placeholder="Enter new Password again"
                            value={confirm_password}
                            onChange={(e) =>
                              setConfirm_password(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="button-group justify-content-end">
                      <Button
                        buttonType="primary"
                        type="submit"
                        text="Submit"
                        onClick={updatePass}
                      />
                    </div>
                  </div>
                </form>
              </div>
              {/* Change Password Tab __ Pane -- Ends  */}

              {/* Get Evaluated Tab __ Pane -- Starts */}
              <div
                className={
                  "tab-pane fade evaluated__tab-pane show " +
                  (currentTab === "interview" ? "active" : "")
                }
                id="list-interview"
                role="tabpanel"
                aria-labelledby="list-interview-list"
              >
                {candidateInterview !== null ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "1rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #CBD5E0",
                        padding: "24px",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="mb-3 d-flex align-items-center justify-content-between flex-wrap">
                        <div>
                          <Text
                            text={candidateInterview?.meeting_title}
                            style={{
                              fontSize: "var(--h5)", // Assuming you have a CSS variable for font size sm
                              fontWeight: 600,
                            }}
                          />
                        </div>
                        <div>
                          <Text
                            text={textCapitalise(
                              candidateInterview?.status?.replace("_s", " S")
                            )}
                            type=""
                            style={{
                              fontSize: "var(--p2)",
                              textAlign: "center",
                              borderRadius: "5px",
                              fontWeight: 500,
                              paddingInline: "15px",
                              color:
                                candidateInterview?.status === "pending" ||
                                  candidateInterview?.status === "cancelled" ||
                                  candidateInterview?.status === "rescheduled"
                                  ? "#EA3E3E"
                                  : "#14BC9A",
                              backgroundColor:
                                candidateInterview?.status === "pending" ||
                                  candidateInterview?.status === "cancelled" ||
                                  candidateInterview?.status === "rescheduled"
                                  ? "#f7e1e0"
                                  : "#dff3ef",
                            }}
                          />
                        </div>
                      </div>

                      <div className="row row-gap-2">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                          <div className="d-flex align-items-center gap-2">
                            <div
                              className="d-flex"
                              style={{
                                width: "20px",
                                height: "20px",
                                alignItems: "center",
                              }}
                            >
                              <BsFillPersonPlusFill color={"#9398A1"} />
                            </div>
                            <Text
                              text="Skills"
                              type=""
                              style={{
                                fontSize: "var(--h7)", // Assuming you have a CSS variable for font size sm
                                width: "100%",
                                fontWeight: 500,
                                color: "#9398A1",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                          <div className="d-flex justify-content-between align-items-center">
                            <Text
                              text={
                                candidateInterview?.skills !== null &&
                                  candidateInterview?.skills !== undefined &&
                                  candidateInterview.skills.length > 0
                                  ? getSkillsString(candidateInterview?.skills)
                                  : "N/A"
                              }
                              type=""
                              style={{
                                fontSize: "var(--h7)", // Assuming you have a CSS variable for font size sm
                                fontWeight: 400,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                          <div className="d-flex align-items-center gap-2">
                            <div
                              className="d-flex"
                              style={{
                                width: "20px",
                                height: "20px",
                                alignItems: "center",
                              }}
                            >
                              <BsCalendar color={"#9398A1"} />
                            </div>
                            <Text
                              text="Date"
                              type=""
                              style={{
                                fontSize: "var(--h7)",
                                width: "100%",
                                fontWeight: 500,
                                color: "#9398A1",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                          <div className="d-flex align-items-center justify-content-between">
                            <Text
                              text={candidateInterview?.meeting_date_time}
                              type=""
                              style={{
                                fontSize: "var(--h7)", // Assuming you have a CSS variable for font size sm
                                fontWeight: 400,
                              }}
                            />
                          </div>
                        </div>

                        {isCancelled(candidateInterview) ||
                          isRescheduled(candidateInterview) ? (
                          <>
                            <div className="col-lg-5 col-md-5 col-sm-12">
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="d-flex"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    alignItems: "center",
                                  }}
                                >
                                  <BsFillQuestionSquareFill color={"#9398A1"} />
                                </div>
                                <Text
                                  text="Reason"
                                  type=""
                                  style={{
                                    fontSize: "var(--h7)",
                                    width: "100%",
                                    fontWeight: 500,
                                    color: "#9398A1",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div className="d-flex align-items-center justify-content-between">
                                <Text
                                  text={candidateInterview?.reason}
                                  type=""
                                  fontSize="sm"
                                  style={{ fontSize: "var(--h7)", wordBreak: "break-all" }}
                                  fontWeight={500}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}

                        {isCancelled(candidateInterview) &&
                          !canInterviewReschedule ? (
                          <>
                            <div style={{ gridColumn: "span 6" }} colSpan={6}>
                              <div className="gap-2 d-flex align-items-center">
                                <Text
                                  text="TT team will contact you."
                                  type=""
                                  style={{
                                    fontSize: "var(--font-size-sm)",
                                    width: "100%",
                                    fontWeight: 500,
                                    color: "#9398A1",
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="button-group">
                        {isCancelled(candidateInterview) &&
                          canInterviewReschedule ? (
                          <Button
                            buttonType="primary"
                            onClick={() => {
                              navigate(
                                "/candidate-appointment?id=" +
                                candidateInterview?.id
                              );
                            }}
                            text="Reschedule"
                          ></Button>
                        ) : null}

                        {canCancel(candidateInterview) ? (
                          <Button
                            buttonType="primary"
                            onClick={() => {
                              onDeleteOpen();
                              setSelectedInterview(candidateInterview);
                            }}
                            text="Cancel"
                          />
                        ) : null}

                        {isRescheduled(candidateInterview) ? (
                          <Button
                            buttonType="primary"
                            onClick={() =>
                              updateInterviewStatus(
                                candidateInterview?.id,
                                "accepted"
                              )
                            }
                            text="I'm ok"
                          />
                        ) : null}

                        {canJoin(candidateInterview) ? (
                          <Button
                            buttonType="primary"
                            onClick={() =>
                              navigate(
                                "/evaluation/feedback/" + candidateInterview?.id
                              )
                            }
                            text="Join Now"
                          />
                        ) : null}

                        {isPostInterview(candidateInterview) ? (
                          <>
                            <Button
                              buttonType="secondary"
                              onClick={() => {
                                // updateInterviewStatus(candidateInterview?.id, "no_show")
                                onNoShowOpen();
                              }}
                              text="No Show"
                            />

                            <Button
                              buttonType="primary"
                              onClick={() =>
                                updateInterviewStatus(
                                  candidateInterview?.id,
                                  "completed"
                                )
                              }
                              text="Completed"
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : isActive ? (
                  <BecomePro
                    becomePro={becomePro}
                    setRefresh={setRefresh}
                    isShowTitle={false}
                  />
                ) : (
                  <BecomeProEvaluated isShowTitle={false} />
                )}
              </div>

              {/* No Show Modal */}
              <Modal
                show={isNoShowOpen}
                onHide={onNoShowClose}
                centered
                size="md"
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <img
                      src={deleteIcon}
                      alt="delete"
                      style={{ maxWidth: "50px" }}
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Text
                    text="No Show Meeting"
                    type=""
                    style={{
                      fontSize: "var(--p2)",
                      fontWeight: "bold",
                    }}
                  />
                  <Text
                    text="Are you sure you want to mark meeting as no show?"
                    type=""
                    style={{
                      fontSize: "var(--p1)",
                    }}
                  />

                  <div className="mt-2 gap-2 d-flex flex-column">
                    <Text
                      text="Reason"
                      type=""
                      fontWeight={600}
                      style={{
                        fontSize: "var(--p1)",
                        fontWeight: 600,
                      }}
                    />

                    <Input
                      placeholder="Enter Reason"
                      fontSize={"sm"}
                      value={reason}
                      maxLength={200}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="button-group justify-content-end mt-0">
                    <Button
                      buttonType="secondary"
                      text="No"
                      onClick={onNoShowClose}
                    />
                    <Button
                      buttonType="primary"
                      text="Yes"
                      onClick={() =>
                        updateInterviewStatus(candidateInterview?.id, "no_show")
                      }
                    />
                  </div>
                </Modal.Footer>
              </Modal>

              {/* Get Evaluated Tab __ Pane -- Ends */}

              <div
                className="tab-pane fade common_items"
                id="user-transactions"
                role="tabpanel"
                aria-labelledby="list-user-transactions"
              >
                {userTransactions && userTransactions.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Candidate ID</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Transaction Type</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Date/time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userTransactions &&
                        userTransactions.map((transaction, i) => {
                          return (
                            <tr key={i} className="results">
                              <td>
                                {transaction.candidate_id === 0
                                  ? "N/A"
                                  : storageConstants.PREFIX_CANDIDATE_ID +
                                  transaction.candidate_id}
                              </td>
                              <td>{transaction.amount}</td>
                              <td>{transaction.type}</td>
                              <td>{transaction.remaining}</td>
                              <td>{transaction.created_at}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  <div className="no_jobs">
                    <img
                      onError={commonServices.imgError}
                      src="../Assets/svgs/search_no_result.svg"
                      alt=""
                    />
                    <h2>No History Of Used TT Cash!</h2>
                  </div>
                )}
              </div>
            </div>
            {/* Tab-Pane Container -- Ends  */}
          </div>
        </div>

        {/* Cancel Modal --Starts */}
        <Modal backdrop="static" show={isDeleteOpen} onHide={onDeleteClose} centered size="md">
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={deleteIcon} alt="delete" style={{ maxWidth: "50px" }} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Text
              text="Cancel Meeting"
              type=""
              style={{
                fontSize: "var(--p2)",
                fontWeight: "bold",
              }}
            />
            <Text
              text="Are you sure you want to cancel the meeting?"
              type=""
              style={{
                fontSize: "var(--p)",
              }}
            />

            <div
              style={{
                marginTop: "10px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                text="Reason"
                type=""
                fontWeight={600}
                style={{
                  fontSize: "var(--p)",
                  fontWeight: 600,
                }}
              />

              <Input
                placeholder="Enter Reason"
                fontSize={"sm"}
                value={reason}
                maxLength={200}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex align-items-center gap-2">
              <Button
                buttonType="secondary"
                text="No"
                onClick={onDeleteClose}
              ></Button>
              <Button
                buttonType="primary"
                text="Yes"
                onClick={() => {
                  updateInterviewStatus(selectedInterview.id, "cancelled");
                }}
              ></Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div >
    </>
  );
}

export default MyAccountLayout;
