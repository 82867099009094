import React, { useState } from 'react'
import './Forgot.scss'
import Footer from '../Footer/Footer'
import { useNavigate, Navigate } from "react-router-dom";


import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as erros from '@/constants/stringConstants';
import * as storageConstants from '@/constants/storageConstants';
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import { APP_NAME } from '@/constants/storageConstants';
import { MaxWidthContainer } from '../Common/MaxWidthContainer';
import * as toast from "@/wrapper/toast";
import Loader from '../_widgets/Loader/Loader';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;


function Forgot() {

  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");


  const [passwordShown, setPasswordShown] = useState(false);
  const [visibility, setVisibility] = useState('visibility');
  const [sendOtpBtnvisibility, setSendOtpBtnvisibility] = useState('');
  const [email, setEmail] = useState();
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const formData = new FormData();

  formData.append('email', email);
  formData.append('otp', otp);
  formData.append('new_password', password);
  formData.append('confirm_password', confirmPassword);


  const navigate = useNavigate();
  const changeLogin = () => {
    navigate("/login");
  }


  const forgotPasswordSendOtp = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.warn(erros.EMAIL_REQUIRED);
    }
    else {
      setLoading(true);
      await ApiHelper.forgotPasswordSendOtp(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {

          toast.success(response.message);
          // navigate('/');
          setSendOtpBtnvisibility('visibility');
          setVisibility('');

        }
        else {
          toast.warn(response.message);
        }
      });

    }



  }

  const resetPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.warn(erros.EMAIL_REQUIRED);
    } else if (!otp) {
      toast.warn(erros.OTP_REQUIRED);
    } else if (!password) {
      toast.warn(erros.NEW_PASSWORD_REQUIRED);
    } else if (!commonServices.isValidPassword(password)) {
      toast.warn(erros.INVALID_PASSWORD);
    } else if (!confirmPassword) {
      toast.warn(erros.NEW_CONFIRM_PASSWORD_REQUIRED);
    }
    else {
      setLoading(true);
      await ApiHelper.resetPassword(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {

          toast.success(response.message);
          changeLogin();

        }
        else {
          toast.warn(response.message);
        }
      });
    }



  }

  var auth = commonServices.getLocalData(storageConstants.AUTH);

  return (
    <>
      {loading ?
       <Loader /> : null}
      <>

        {auth ? <Navigate replace to="/" /> : null}

        <MaxWidthContainer>


          <div className="Forget_page">
            <div className="main">
              <div className="left">
                <div className="left_content">
                  <img onError={commonServices.imgError} className="cover_image" src="./Assets/images/login_banner.png?1" alt="" />
                  <h2 className="content"><span>Your Dream job</span> at best locations is just a click away!</h2>
                  <button className="btn content-button" type="button" onClick={() => { navigate('/about'); }}>Learn more about {APP_NAME}</button>
                </div>
              </div>
              <div className="right">
                <div className="right_content">
                  <h2 className="content_heading text-center mt-3">Reset Password</h2>
                  <form action="">
                    <div className="input_section">
                      <div className="input_icon"><img onError={commonServices.imgError} src="./Assets/images/mail.png?1" alt="" /></div>

                      <div className=" form-floating">
                        <input type="email" className="form-control" id="LoginEmail" placeholder="name@example.com" onChange={(e) => { setEmail(e.target.value) }} />
                        <label htmlFor="LoginPassword" >Email ID</label>
                      </div>
                    </div>

                    <div className={visibility}>
                      <div className="input_section">
                        <div className=" form-floating">
                          <input type="number" className="form-control" id="OTP" placeholder="XXX" onChange={(e) => { setOtp(e.target.value) }} />
                          <label htmlFor="LoginPassword">Enter OTP</label>
                        </div>
                      </div>
                      <div className="input_section">
                        <div className=" form-floating">
                          <input type={passwordShown ? "text" : "password"} className="form-control" placeholder="" onChange={(e) => { setPassword(e.target.value) }} />
                          <label htmlFor="LoginPassword">Password</label>
                        </div>
                        <div onClick={() => setPasswordShown(!passwordShown)} className="input_icon"><img onError={commonServices.imgError} src="./Assets/images/eye.png?1" alt="" /></div>
                      </div>
                      <div className="input_section">
                        <div className=" form-floating">
                          <input type="password" className="form-control" id="LoginEmail" placeholder="" onChange={(e) => { setConfirmPassword(e.target.value) }} />
                          <label htmlFor="LoginPassword">Confirm Password</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col mb-4">
                          <button className="btn form_button" onClick={resetPassword}>Reset Password</button>
                        </div>
                      </div>
                    </div>

                    <div className={sendOtpBtnvisibility}>
                      <div className="row mb-4 ">
                        <div className="col" id="checkbox_div">
                          <div className="d-flex align-items-center">
                          <img onError={commonServices.imgError} src="./Assets/svgs/info.svg" alt="" /><p>Reset password link will be sent to the registered email address.</p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col mb-4">
                          <button className="btn form_button" onClick={forgotPasswordSendOtp}>Send</button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="row">
                    <div className="col b_t_l">
                      <p style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px"
                      }} className="register_txt"><img onError={commonServices.imgError} src="./Assets/svgs/corner_down_left.svg" alt="" /><a className="body-links" onClick={changeLogin}>  Back to login</a> </p>
                    </div>
                  </div>
                  <div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </MaxWidthContainer>
      </>
    </>
  )
}

export default Forgot
