import React, { useState, useEffect } from 'react'
// import './Forgot.scss'
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";

import logo from "@/Assets/svgs/logo.svg"

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as erros from '@/constants/stringConstants';
import * as storageConstants from '@/constants/storageConstants';
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import * as toast from "@/wrapper/toast";
import "./Auth.scss"
import Input from '../_widgets/Input/Input';
import { decryptBase64String } from '../../utils/stringUtils';
import Loader from '../_widgets/Loader/Loader';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;


function Forgot() {

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");


    const [passwordShown, setPasswordShown] = useState(false);
    const [visibility, setVisibility] = useState('visibility');
    const [sendOtpBtnvisibility, setSendOtpBtnvisibility] = useState('');
    const [email, setEmail] = useState();
    const [otp, setOtp] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const formData = new FormData();

    formData.append('email', email);
    formData.append('otp', otp);
    formData.append('new_password', password);
    formData.append('confirm_password', confirmPassword);


    const navigate = useNavigate();



    const forgotPasswordSendOtp = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.warn(erros.EMAIL_REQUIRED);
        }
        else {
            setLoading(true);
            await ApiHelper.forgotPasswordSendOtp(formData).then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {

                    toast.success(response.message);
                    // navigate('/');
                    setSendOtpBtnvisibility('visibility');
                    setVisibility('');

                }
                else {
                    toast.warn(response.message);
                }
            });

        }



    }

    const resetPassword = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.warn(erros.EMAIL_REQUIRED);
        } else if (!otp) {
            toast.warn(erros.OTP_REQUIRED);
        } else if (!password) {
            toast.warn(erros.NEW_PASSWORD_REQUIRED);
        } else if (!commonServices.isValidPassword(password)) {
            toast.warn(erros.INVALID_PASSWORD);
        } else if (!confirmPassword) {
            toast.warn(erros.NEW_CONFIRM_PASSWORD_REQUIRED);
        }
        else {
            setLoading(true);
            await ApiHelper.resetPassword(formData).then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {

                    toast.success(response.message);
                    changeLogin();

                }
                else {
                    toast.warn(response.message);
                }
            });
        }



    }

    var auth = commonServices.getLocalData(storageConstants.AUTH);

    const [searchParams, setSearchParams] = useSearchParams();

    const changeLogin = () => {
        setSearchParams({ "auth": "login" })
    }

    useEffect(() => {
        var params = searchParams.get("q");
        params = decryptBase64String(params);

        var urlParams = new URLSearchParams(params);
        var emailParam = urlParams.get("profile");
        var otpParam = urlParams.get("q");
        if (emailParam !== undefined) {
            setEmail(emailParam)
        }

        if (otpParam !== undefined) {
            setOtp(otpParam);
        }

        if (emailParam !== undefined && otpParam !== undefined && emailParam !== null && otpParam !== null) {
            setSendOtpBtnvisibility('visibility');
            setVisibility('');
        }


    }, [searchParams]);


    return (
        <>
            {loading ?
                <Loader /> : null}
            <>

                {auth ? <Navigate replace to="/" /> : null}


                <div className='auth-container-content-wrapper d-flex align-items-center justify-content-center'
                >

                    <img src={logo} className='logo' />

                    <p className='title'
                    >
                        Reset Password
                    </p>

                    <div className="forgot_container">
                        <div className="main">
                            <div className="form-wrapper">
                                {/* <h2 className="content_heading text-center mt-3">Reset Password</h2> */}
                                <form action="">

                                    {visibility &&
                                        <Input type="email" isRequired={true} label="Email Id" placeholder="Email Address" id="LoginEmail" onChange={(e) => setEmail(e.target.value)}
                                            value={email} />
                                    }



                                    {!visibility &&
                                        <div className='form-wrapper'>
                                            <div className='row row-gap-2'>
                                                <div className="col-md-6">
                                                    <Input type="email" isRequired={true} label="Email Id" placeholder="Email Address" id="LoginEmail" onChange={(e) => setEmail(e.target.value)}
                                                        value={email} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="number" isRequired={true} label="Email OTP" placeholder="XXX" value={otp} id="OTP" onChange={(e) => { setOtp(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type={passwordShown ? "text" : "password"} isRequired={true} label="Password" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type={passwordShown ? "text" : "password"} isRequired={true} label="Confirm Password" placeholder="Confirm Password" onChange={(e) => { setConfirmPassword(e.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col mt-2">
                                                    <button className="btn form_button" onClick={resetPassword}>Reset Password</button>
                                                </div>
                                            </div>
                                        </div>}

                                    {
                                        visibility &&
                                        <div>
                                            <div className="row mb-4 ">
                                                <div className="col" id="checkbox_div">
                                                    <div className="d-flex align-items-center">
                                                        <img onError={commonServices.imgError} src="./Assets/svgs/info.svg" alt="" /><p>Reset password link will be sent to the registered email address.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <button className="btn form_button" onClick={forgotPasswordSendOtp}>Send</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </form>

                                <div className="row">
                                    <div className="col b_t_l">
                                        <p
                                            onClick={changeLogin}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "10px"
                                            }} className="register_txt"><img onError={commonServices.imgError} src="./Assets/svgs/corner_down_left.svg" alt="" /><a className="body-links" onClick={changeLogin}>  Back to login</a> </p>
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>

                            {/* <div className="right">
                            </div> */}

                        </div>
                    </div>

                </div>

            </>
        </>
    )
}

export default Forgot
