import React, { useEffect, useState } from "react";
import { BsCalendar, BsCircleFill, BsPlusCircle } from "react-icons/bs";
import { FiClock, FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdClear } from "react-icons/md";
import { BiPlus, BiEditAlt } from "react-icons/bi";
import Text from "../../_widgets/Text/Text";

import Dropdown from "../../_widgets/Dropdown/Dropdown";

import { Modal } from "react-bootstrap";
import * as toast from "../../../wrapper/toast";
import Button from "../../_widgets/Button/Button";

import {
  getEvaluatorSlots,
  updateEvaluatorSlots,
} from "../../../services/apiHelper";

import {
  isBetween,
  momentDuration,
  timeConstants,
  timeFormat,
} from "../../../utils/dateUtil";
import { Form } from "react-bootstrap";
import Loader from "../../_widgets/Loader/Loader";

const instruction = [
  "Add real-time availability each week",
  "Appear more often in searches",
  "Get relevant requests directly from candidates",
];

const mergeTimeSlots = (timeSlots) => {
  if (timeSlots.length === 0) {
    return "Use + button to add";
  }

  const sortedTimeSlots = timeSlots
    .map((slot) => {
      const [start, end] = slot.split(" - ");
      return { start, end };
    })
    .sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });

  const mergedTimeSlots = [sortedTimeSlots[0]];

  for (let i = 1; i < sortedTimeSlots.length; i++) {
    const currentSlot = mergedTimeSlots[mergedTimeSlots.length - 1];
    const nextSlot = sortedTimeSlots[i];
    const currentEndTime = currentSlot.end;
    const nextStartTime = nextSlot.start;

    if (currentEndTime === nextStartTime) {
      currentSlot.end = nextSlot.end;
    } else {
      mergedTimeSlots.push(nextSlot);
    }
  }
  const csvRows = mergedTimeSlots.map((slot) => `${slot.start} - ${slot.end}`);

  return csvRows.join(", ");
};

const Slot = ({ day, slot }) => {
  const timArr = [
    ...slot?.map((item) => {
      const [start_time, end_time] = item.split("-");
      return {
        start_time: start_time.trim(),
        end_time: end_time.trim(),
      };
    }),
  ];

  timArr.forEach((item, index) => {
    if (
      timArr.findIndex((item2) => item2.end_time === item.start_time) !== -1
    ) {
      const index2 = timArr.findIndex(
        (item2) => item2.end_time === item.start_time
      );
      timArr[index2] = {
        start_time: timArr[index2].start_time,
        end_time: item.end_time,
      };
      timArr.splice(index, 1);
    }
  });

  const newSlot = timArr.map(
    (item) => `${item?.start_time} - ${item?.end_time}`
  );

  return (
    <div
      style={{
        width: "100%", // w: "100%"
        display: "flex", // display: "flex"
        borderRadius: "10px", // borderRadius: "10px"
        marginRight: "auto", // mr: "auto"
        alignItems: "center", // alignItems: "center"
      }}
    >
      <Text
        type=""
        text={day?.substring(0, 3)}
        style={{ width: "60px", marginRight: "20px" }}
      />
      <div
        style={{
          background: slot.length > 0 ? "#D6FFE7" : "#F5F5F5",
          padding: "15px 20px", // p: "15px 20px"
          cursor: "pointer",
          borderRadius: "10px", // borderRadius: "10px"
          width: "100%", // w: "100%"
          display: "flex", // display: "flex"
          justifyContent: "space-between", // justifyContent: "space-between"
          alignItems: "center", // alignItems: "center"
          color: slot.length > 0 ? "#0EAD69" : "#9393AA",
          transition: "background-color 0.3s, color 0.3s",
        }}
        onMouseOver={(event) => {
          event.currentTarget.style.backgroundColor = "#D6FFE7";
          event.currentTarget.style.color = "#0EAD69";
        }}
        onMouseOut={(event) => {
          event.currentTarget.style.backgroundColor =
            slot.length > 0 ? "#D6FFE7" : "#F5F5F5";
          event.currentTarget.style.color =
            slot.length > 0 ? "#0EAD69" : "#9393AA";
        }}
      >
        <div>
          <Text
            type=""
            text={slot.length > 0 ? "Available" : "No availability added"}
            fontSize={"0.875"}
            fontWeight={500}
          />
          {console.log("slot", slot.length)}
          <Text
            type=""
            text={mergeTimeSlots(newSlot)}
            fontSize={"1em"}
            fontWeight={400}
          />
        </div>
        <Button
          buttonType="secondary"
          buttonIcon={
            slot.length > 0 ? (
              <BiEditAlt size={20} color={"#000"} />
            ) : (
              <BiPlus size={20} color={"#000"} />
            )
          }
        ></Button>
      </div>
    </div>
  );
};

const ManageAvailabilty = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  const [loading, setLoading] = useState(false);

  const [slotData, setSlotData] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const [selectedSlot, setSelectedSlot] = useState("");

  const [formInput, setFormInput] = useState({
    day: "",
    start_time: "",
    end_time: "",
    time_slots: [],
    is_recurring: false,
    is_recurring_1: false,
    is_recurring_2: false,
  });

  const checkDate = () => {
    var beginTime = timeFormat(formInput.start_time.trim(), "h:mm a");
    var endTimeInput = timeFormat(formInput.end_time.trim(), "h:mm a");

    if (beginTime.isAfter(endTimeInput)) {
      toast.warn("Start time should be less than end time");
      return false;
    }
    var duration = momentDuration(endTimeInput.diff(beginTime));
    var hours = duration.asHours();

    if (hours < 1) {
      toast.warn("Time slot should have minimum 1 hour");
      return false;
    }

    let flag = true;

    try {
      formInput.time_slots.forEach((item) => {
        const startTime = timeFormat(item.start_time.trim(), "h:mm a");
        const endTime = timeFormat(item.end_time.trim(), "h:mm a");

        // if (isBetween(beginTime, startTime, endTime) || isBetween(endTimeInput, startTime, endTime) || isBetween(startTime, beginTime, endTimeInput) || isBetween(endTime, beginTime, endTimeInput)) {
        //     throw new Error("Time slot already added")
        // }
        if (
          isBetween(beginTime, startTime, endTime) ||
          isBetween(startTime, beginTime, endTimeInput)
        ) {
          throw new Error("Time slot already added");
        }
      });
    } catch (err) {
      console.log(err);
      toast.warn(err.message);
      flag = false;
    } finally {
      console.log(flag);
      return flag;
    }
  };

  const addTimeSlot = () => {
    if (!formInput.start_time || !formInput.end_time) {
      toast.warn("Please select start and end time");
      return;
    }

    if (formInput.start_time && formInput.end_time) {
      if (!checkDate()) {
        return;
      }

      setFormInput({
        ...formInput,
        time_slots: [
          ...formInput.time_slots,
          {
            start_time: formInput.start_time,
            end_time: formInput.end_time,
          },
        ],
        start_time: "",
        end_time: "",
      });
    }
  };

  const closeModal = () => {
    setFormInput({
      day: "",
      start_time: "",
      end_time: "",
      time_slots: [],
      is_recurring: false,
    });
    onClose();
  };

  const submitForm = async (e) => {
    // const newSlots =
    e.preventDefault();
    // if (formInput?.time_slots.length < 1) {
    //     toast.warn(NO_TIME_SLOT_ERROR_MESSAGE);
    //     return;
    // } else {

    const newSlot = [
      ...formInput?.time_slots?.map(
        (item) => `${item?.start_time} - ${item?.end_time}`
      ),
    ];

    if (formInput?.start_time && formInput?.end_time) {
      if (!checkDate()) {
        return;
      }
      newSlot.push(`${formInput?.start_time} - ${formInput?.end_time}`);
    }

    let obj = {
      ...slotData,
      [selectedSlot]: newSlot,
    };

    if (formInput?.is_recurring && formInput?.is_recurring_2) {
      let recurring_slot = obj[selectedSlot];
      obj = {
        Monday: recurring_slot,
        Tuesday: recurring_slot,
        Wednesday: recurring_slot,
        Thursday: recurring_slot,
        Friday: recurring_slot,
        Saturday: recurring_slot,
        Sunday: recurring_slot,
      };
    } else if (formInput?.is_recurring && formInput?.is_recurring_1) {
      let recurring_slot = obj[selectedSlot];
      let saturday_slots = obj["Saturday"];
      let sunday_slots = obj["Sunday"];
      obj = {
        Monday: recurring_slot,
        Tuesday: recurring_slot,
        Wednesday: recurring_slot,
        Thursday: recurring_slot,
        Friday: recurring_slot,
        Saturday: saturday_slots,
        Sunday: sunday_slots,
      };
    }

    // setSlotData(obj)

    try {
      setLoading(true);
      const res = await updateEvaluatorSlots({ slots: obj });

      if (res.isSuccess) {
        toast.success(res.message);
        getSlots();
      }
    } catch (err) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.warn(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }

    closeModal();
    // }
  };

  const getSlots = async () => {
    try {
      setLoading(true);
      const res = await getEvaluatorSlots();

      if (res.isSuccess) {
        if (res?.data?.slots !== null) {
          const slotsData = res?.data?.slots?.slots;

          Object.keys(slotsData).forEach((item) => {
            const newSlot = [
              ...slotsData[item]?.map((item2) => {
                const [start_time, end_time] = item2.split("-");
                return {
                  start_time,
                  end_time,
                };
              }),
            ];

            newSlot.sort((a, b) => {
              const [start_time, end_time] = timeFormat(a.start_time, "hh:mm a")
                .format("HH:mm")
                .split(":");
              const [start_time1, end_time1] = timeFormat(
                b.start_time,
                "hh:mm a"
              )
                .format("HH:mm")
                .split(":");
              return start_time - start_time1;
            });

            const newSlot1 = newSlot.map(
              (item) => `${item?.start_time} - ${item?.end_time}`
            );
            slotsData[item] = newSlot1;
          });

          setSlotData(slotsData);
        }
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.warn(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSlots();
  }, []);

  return (
    <>
      {loading && <Loader />}

      <div className="mt-3">
        <div
          style={{
            width: "100%",
            background: "#F5F5F5",
            borderRadius: "10px",
            padding: "15px",
            marginRight: "auto",
          }}
        >
          <Text
            type=""
            text="Add availabilities on your schedule"
            className="mb-4"
            fontSize={"1em"}
            fontWeight={500}
            mb={"20px"}
            color={"#1B2124"}
          />
          <div
            className="d-flex flex-wrap gap-2"
            flexWrap={"wrap"}
            gap={"10px"}
          >
            {instruction.map((item) => (
              <div className="d-flex align-items-center gap-3">
                <BsCircleFill size={6} color="#000" />
                <Text type="" text={item} fontSize={".9em"} fontWeight={400} />
              </div>
            ))}
          </div>
        </div>

        <div style={{ width: "100%", paddingTop: "45px" }}>
          <Text
            type="h2"
            text="Monday-Sunday"
            fontSize={"1em"}
            fontWeight={500}
            color={"#272755"}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              columnGap: "40px",
              rowGap: "20px",
              marginTop: "20px",
            }}
          >
            {Object.keys(slotData).map((item) => (
              <div
                style={{ gridColumn: "span 1" }}
                onClick={() => {
                  onOpen();
                  setSelectedSlot(item);
                  setFormInput({
                    ...formInput,
                    time_slots: slotData[item]?.map((item) => {
                      const [start_time, end_time] = item.split("-");
                      return {
                        start_time,
                        end_time,
                      };
                    }),
                  });
                }}
              >
                <Slot day={item} slot={slotData[item]} />
              </div>
            ))}
          </div>
        </div>

        <Modal backdrop="static" show={isOpen} onHide={closeModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Set Availability</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "75vh", overflowY: "scroll" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
                padding: ["10px", "20px"],
              }}
            >
              <div style={{ gridColumn: "span 1" }}>
                <div>
                  <Text
                    type="h2"
                    text="Available Date"
                    fontWeight={500}
                    style={{ color: "#9393AA", marginBottom: "1rem" }}
                  />
                  <div
                    style={{
                      position: "relative",
                      borderBottom: "2px solid #F0F0F0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                      paddingBottom: "8px", // pb: "8px"
                    }}
                  >
                    <BsCalendar
                      size={20}
                      color={"#1B2124"}
                      position={"absolute"}
                      bottom={"0"}
                      left={"10px"}
                    />
                    <Dropdown
                      value={selectedSlot ? selectedSlot : "Select Day"}
                      disabled={true}
                      className="disabled-white"
                      style={{
                        color: "#000",
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                        background: "transparent !important",
                      }}
                      select={[
                        {
                          id: "Monday",
                          name: "Monday",
                        },
                        {
                          id: "Tuesday",
                          name: "Tuesday",
                        },
                        {
                          id: "Wednesday",
                          name: "Wednesday",
                        },
                        {
                          id: "Thursday",
                          name: "Thursday",
                        },
                        {
                          id: "Friday",
                          name: "Friday",
                        },
                        {
                          id: "Saturday",
                          name: "Saturday",
                        },
                        {
                          id: "Sunday",
                          name: "Sunday",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ gridColumn: "span 1" }}
                className="d-none d-md-block"
                display={["none", "block"]}
                colSpan={[2, 1]}
              />

              <div style={{ gridColumn: "span 2" }} colSpan={2}>
                {formInput.time_slots.length > 0 &&
                  formInput?.time_slots?.map((item, index) => (
                    <div
                      className="row"
                      style={{
                        position: "relative",
                        marginY: "10px",
                      }}
                    >
                      <div className="col-md-5">
                        <div>
                          <Text
                            type=""
                            text="Start Time"
                            fontWeight={500}
                            style={{ color: "#9393AA" }}
                          />
                          <div
                            style={{
                              position: "relative",
                              borderBottom: "2px solid #F0F0F0",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <FiClock
                              size={20}
                              color={"#1B2124"}
                              position={"absolute"}
                              top={"10px"}
                              left={"10px"}
                            />
                            <Dropdown
                              style={{
                                width: "100%",
                                border: "none !important",
                              }}
                              place_holder="Start time"
                              className="disabled-white"
                              disabled={true}
                              select={[...timeConstants]}
                              value={item.start_time.trim()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div>
                          <Text
                            type=""
                            text="End Time"
                            fontWeight={500}
                            style={{ color: "#9393AA" }}
                          />
                          <div
                            style={{
                              position: "relative",
                              borderBottom: "2px solid #F0F0F0",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <FiClock
                              size={20}
                              color={"#1B2124"}
                              position={"absolute"}
                              top={"10px"}
                              left={"10px"}
                            />
                            <Dropdown
                              style={{
                                width: "100%",
                                border: "none !important",
                              }}
                              className="disabled-white"
                              place_holder="End time"
                              showDefaultOption={true}
                              disabled={true}
                              select={[...timeConstants]}
                              value={item.end_time.trim()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="d-flex gap-3 h-100 align-items-center justify-content-end">
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const newSlots = [...formInput.time_slots];
                              newSlots.splice(index, 1);
                              setFormInput({
                                ...formInput,
                                time_slots: newSlots,
                                start_time: item?.start_time,
                                end_time: item?.end_time,
                              });
                            }}
                          >
                            <FiEdit3 size={20} color={"#000"} />
                          </div>
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const newSlots = [...formInput.time_slots];
                              newSlots.splice(index, 1);
                              setFormInput({
                                ...formInput,
                                time_slots: newSlots,
                              });
                            }}
                          >
                            <RiDeleteBin6Line size={20} color={"#FD815E"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div style={{ gridColumn: "span 2" }}>
                <div className="row">
                  <div className="position-relative col-md-5">
                    <div>
                      <Text
                        type=""
                        text="Start Time"
                        fontWeight={500}
                        style={{ color: "#9393AA" }}
                      />
                      <div
                        style={{
                          position: "relative",
                          borderBottom: "2px solid #F0F0F0",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <FiClock
                          size={20}
                          color={"#1B2124"}
                          position={"absolute"}
                          top={"10px"}
                          left={"10px"}
                        />
                        <Dropdown
                          style={{
                            width: "100%",
                            border: "none !important",
                          }}
                          bordered={false}
                          showSearch
                          className="active-white"
                          place_holder="Start time"
                          optionFilterProp="children"
                          onChange={(e) => {
                            setFormInput({
                              ...formInput,
                              start_time: e.target.value,
                              end_time: timeFormat(e.target.value, "hh:mm a")
                                .add(1, "hours")
                                ?.format("hh:mm a"),
                            });
                          }}
                          notFoundContent="No available timeslot"
                          suffixIcon={null}
                          showDefaultOption={true}
                          select={[...timeConstants]}
                          value={formInput.start_time ?? "Time Slot"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="position-relative col-md-5">
                    <div>
                      <Text
                        type=""
                        text="End Time"
                        fontWeight={500}
                        style={{ color: "#9393AA" }}
                      />
                      <div
                        style={{
                          position: "relative",
                          borderBottom: "2px solid #F0F0F0",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <FiClock
                          size={20}
                          color={"#1B2124"}
                          position={"absolute"}
                          top={"10px"}
                          left={"10px"}
                        />
                        <Dropdown
                          style={{
                            width: "100%",
                            border: "none !important",
                          }}
                          className="active-white"
                          place_holder="End time"
                          onChange={(e) => {
                            setFormInput({
                              ...formInput,
                              end_time: e.target.value,
                            });
                          }}
                          showDefaultOption={true}
                          select={[...timeConstants]}
                          value={formInput.end_time ?? "Time Slot"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex gap-3 w-100 h-100 align-items-end justify-content-end">
                      <Button
                        buttonType="light"
                        text=""
                        className="d-flex justify-content-end align-items-center btn tt-btn__light pe-0"
                        onClick={() => {
                          setFormInput({
                            ...formInput,
                            start_time: "",
                            end_time: "",
                          });
                        }}
                        buttonIcon={<MdClear size={20} color={"#FD815E"} />}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>

              {/* start time */}

              {/* end time */}

              <div style={{ gridColumn: "span 2" }} colSpan={2}>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    addTimeSlot();
                  }}
                >
                  <BsPlusCircle size={20} color={"#14BC9A"} />
                  <Text
                    type=""
                    className="green-text"
                    text="Add Another Slot"
                    fontWeight={700}
                    color={"#14BC9A"}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "30px",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Form.Group
                    controlId="email-alerts"
                    className="mb-2 d-flex align-items-center gap-2 w-100 "
                  >
                    <Form.Label style={{ fontWeight: 400, marginBottom: 0 }}>
                      Repeat this time block
                    </Form.Label>
                    <Form.Check
                      type="switch"
                      label=""
                      checked={formInput?.is_recurring}
                      onChange={(e) => {
                        setFormInput({
                          ...formInput,
                          is_recurring: e.target.checked,
                          is_recurring_2: e.target.checked,
                        });
                      }}
                    />
                  </Form.Group>
                </div>

                <Form.Group>
                  <Form.Check
                    type="radio"
                    id="recurring1"
                    label="Every weekday (Mon to Fri)"
                    checked={formInput?.is_recurring_1}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        is_recurring_1: e.target.checked,
                        is_recurring_2: !e.target.checked,
                      });
                    }}
                    disabled={!formInput?.is_recurring}
                  />
                  <Form.Check
                    type="radio"
                    id="recurring2"
                    label="Every day (Mon to Sun)"
                    checked={formInput?.is_recurring_2}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        is_recurring_1: !e.target.checked,
                        is_recurring_2: e.target.checked,
                      });
                    }}
                    disabled={!formInput?.is_recurring}
                  />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              buttonType="secondary"
              text="Cancel"
              style={{ marginRight: "10px" }}
              onClick={closeModal}
            ></Button>
            <Button
              buttonType="primary"
              text="Save"
              onClick={submitForm}
            ></Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ManageAvailabilty;
