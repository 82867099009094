import React, { useEffect, useState } from "react";

import { BsCalendar, BsFillQuestionSquareFill } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Text as CText } from "../_widgets/Text/Text";

import * as ApiHelper from "@/services/apiHelper";

import { BsFillPersonPlusFill } from "react-icons/bs";
import { getSkillsString } from "@/utils/stringUtils";
import NoDataView from "../NoDataView/NoDataView";
import AppModal from "@Components/Modals/AppModal";
import BookTimeSlot from "../BecomePro/CandidateScheduleAppointment/BookTimeSlot/BookTimeSlot";
import * as toast from "@/wrapper/toast";
import Text from "../_widgets/Text/Text";

import { Modal } from "react-bootstrap";

import deleteIcon from "@/Assets/images/deleteIcon.png";
import { MaxWidthContainer } from "../Common/MaxWidthContainer";
import {
  canAccept,
  canCancel,
  canJoin,
  canReschedule,
} from "@/utils/interview";
import { changeDateFormat } from "../../utils/dateUtil";
import Tabs from "../_widgets/Tabs/Tabs";
import Button from "../_widgets/Button/Button";
import Loader from "../_widgets/Loader/Loader";

const EvaluatorInterviewLayout = ({
  isOpen,
  onOpen,
  onClose,
  isDeleteOpen,
  onDeleteOpen,
  onDeleteClose,
  isNoShowOpen,
  onNoShowOpen,
  onNoShowClose,
  date,
  setDate,
  selectedSlot,
  setSelectedSlot,
  loading,
  selectedTab,
  selectedInterview,
  setSelectedInterview,
  reason,
  setReason,
  candidateInterview,
  updateInterviewStatus,
  joinMeeting,
  tabsData,
}) => {
  const gridStyles = {
    display: "grid",
    gap: "20px",
    marginTop: "2rem",
    gridTemplateColumns: "1fr",  // Default to one column for mobile screens
  };
  return (

    <MaxWidthContainer pageTitle={"Evaluator Interviews"} className="main-body">
      <div
        className="tt-container"
        style={{ fontSize: "13px", minHeight: "72vh" }}
      >
        {loading ? <Loader /> : null}

        <div className="w-100 overflow-auto gap-4 justify-content-start d-flex align-items-start">
          <Tabs tabs={tabsData} />
        </div>

        <div
          className="interview_card_container"
        >
          {candidateInterview[selectedTab]?.length > 0 ? (
            candidateInterview[selectedTab]?.map((item, index) => (
              <div
                key={index}
                className="border border-1 p-3 p-md-6 rounded-3"
                style={{ borderColor: "var(--border)" }}
              >
                <Text
                  type="h2"
                  text={item["meeting_title"]}
                  style={{ fontSize: "var(--h5)" }}
                  fontWeight={600}
                />

                <div
                  style={{
                    display: "grid",
                    gap: "10px",
                    marginTop: "20px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <div style={{ gridColumn: "span 1" }}>
                    <div
                      style={{
                        gap: "9px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "20px",
                          height: "20px",
                          alignItems: "center",
                        }}
                      >
                        <BsFillPersonPlusFill color={"#9398A1"} />
                      </div>
                      <Text
                        type=""
                        text="Skills"
                        style={{
                          fontSize: "var(--h7)",
                          color: "#9398A1",
                          fontWeight: 500,
                        }}
                        w={"100%"}
                        fontWeight={500}
                        color={"#9398A1"}
                      />
                    </div>
                  </div>
                  <div style={{ gridColumn: "span 1" }}>
                    <div className="d-flex align-items-center">
                      <Text
                        type=""
                        text={getSkillsString(item?.skills) || "-"}
                        style={{ fontSize: "var(--h7)" }}
                        fontWeight={500}
                      />
                    </div>
                  </div>
                  <div style={{ gridColumn: "span 1" }}>
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "20px", height: "20px" }}
                      >
                        <BsCalendar color={"#9398A1"} />
                      </div>
                      <Text
                        type=""
                        text="Date"
                        style={{
                          fontSize: "var(--h7)",
                          color: "#9398A1",
                          fontWeight: 500,
                        }}
                        fontWeight={500}
                        color={"#9398A1"}
                      />
                    </div>
                  </div>
                  <div style={{ gridColumn: "span 1" }}>
                    <div className="d-flex align-items-center">
                      <Text
                        type=""
                        text={item?.meeting_date_time}
                        style={{ fontSize: "var(--h7)" }}
                        fontWeight={500}
                      />
                    </div>
                  </div>
                  {["no_show", "cancelled", "rescheduled"].includes(
                    item?.status
                  ) ? (
                    <>
                      <div style={{ gridColumn: "span 1" }}>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            className="d-flex align-items-center gap-5"
                            style={{ width: "20px", height: "20px" }}
                          >
                            <BsFillQuestionSquareFill color={"#9398A1"} />
                          </div>
                          <Text
                            type=""
                            text="Reason"
                            style={{
                              fontSize: "var(--h7)",
                              color: "#9398A1",
                              fontWeight: 500,
                            }}
                            fontWeight={500}
                            color={"#9398A1"}
                          />
                        </div>
                      </div>
                      <div style={{ gridColumn: "span 1" }}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Text
                            type=""
                            text={item?.reason}
                            style={{ fontSize: "var(--h7)", wordBreak: "break-all" }}

                            fontSize="sm"
                            fontWeight={500}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div
                  className="d-flex mt-5 gap-4 flex-wrap"
                  mt={10}
                  gap="20px"
                  flexWrap={"wrap"}
                >
                  {canAccept(item) ? (
                    <Button
                      buttonType="primary"
                      text="Accept"
                      onClick={() =>
                        updateInterviewStatus(item?.id, "accepted")
                      }
                    ></Button>
                  ) : null}

                  {![
                    "cancelled",
                    "no_show",
                    "completed",
                    "in_progress",
                  ].includes(item?.status) && canReschedule(item) ? (
                    <Button
                      buttonType="secondary"
                      text="Reshedule"
                      onClick={() => {
                        onOpen();
                        setSelectedInterview(item);
                      }}
                    ></Button>
                  ) : null}
                  {![
                    "cancelled",
                    "no_show",
                    "completed",
                    "in_progress",
                  ].includes(item?.status) && canCancel(item) ? (
                    <Button
                      buttonType="secondary"
                      text="Cancel Meeting"
                      onClick={() => {
                        onDeleteOpen();
                        setSelectedInterview(item);
                      }}
                    ></Button>
                  ) : null}

                  {item?.status === "completed" ? (
                    <Button
                      buttonType="secondary"
                      text="View Feedback"
                      onClick={() => joinMeeting(item?.id)}
                    ></Button>
                  ) : null}

                  {item?.status === "in_progress" ? (
                    <>
                      {canJoin(item) ? (
                        <Button
                          buttonType="primary"
                          text="Join Now"
                          onClick={() => joinMeeting(item?.id)}
                        ></Button>
                      ) : null}
                      <Button
                        buttonType="secondary"
                        text="Submit Feedback"
                        onClick={() => joinMeeting(item?.id)}
                      ></Button>
                    </>
                  ) : null}
                  {item?.status === "accepted" ? (
                    <>
                      <Button
                        buttonType="primary"
                        text="Join Now"
                        onClick={() => joinMeeting(item?.id)}
                      ></Button>
                    </>
                  ) : null}

                  {item?.status === "in_progress" ? (
                    <Button
                      buttonType="secondary"
                      text="No Show"
                      onClick={() => {
                        setSelectedInterview(item);
                        onNoShowOpen();
                      }}
                    ></Button>
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <div style={{ gridColumn: "span 1" }}>
              <NoDataView text={"No Interview found !"} showIcon={false} />
            </div>
          )}
        </div>
        <AppModal
          title={"Reschedule Interview"}
          isOpen={isOpen}
          closeModal={onClose}
          submit={() => {
            updateInterviewStatus(selectedInterview?.id, "rescheduled");
          }}
          size={"xl"}
          isFooter={true}
        >
          <div className="p-4">
            <BookTimeSlot
              date={date}
              setDate={setDate}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
            />
            <div
              style={{
                marginTop: "30px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                type=""
                text="Reason"
                fontWeight={600}
                style={{ fontSize: "var(--p1)", fontWeight: 600 }}
              />

              <textarea
                className="form-input"
                placeholder="Enter Reason"
                maxLength={200}
                style={{ fontSize: "var(--p1)" }}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                rows={1}
              />
            </div>
          </div>
        </AppModal>

        {/* Cancel Modal */}
        <Modal backdrop="static" show={isDeleteOpen} onHide={onDeleteClose} centered size="md">
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <img src={deleteIcon} alt="" width={50} height={50} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Text
              type="h2"
              text="Cancel Meeting"
              style={{ fontSize: "var(--p2)" }}
              fontWeight={"bold"}
            />

            <Text
              type=""
              text="Are you sure you want to cancel the meeting?"
              style={{ fontSize: "var(--p1)" }}
            />

            <div
              style={{
                marginTop: "10px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                type=""
                text="Reason"
                style={{ fontSize: "var(--p1)", fontWeight: 600 }}
              />
              <textarea
                placeholder="Enter Reason"
                style={{ fontSize: "var(--p1)" }}
                value={reason}
                maxLength={200}
                rows={1}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              buttonType="secondary"
              text="No"
              onClick={onDeleteClose}
            ></Button>
            <Button
              buttonType="primary"
              text="Yes"
              onClick={() => {
                updateInterviewStatus(selectedInterview.id, "cancelled");
              }}
            ></Button>
          </Modal.Footer>
        </Modal>

        {/* NO SHOW */}
        <Modal backdrop="static" show={isNoShowOpen} onHide={onNoShowClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>No Show Meeting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Text
              type=""
              text="No Show Meeting"
              fontSize={"md"}
              fontWeight={"bold"}
            />

            <Text
              type=""
              text="Are you sure you want to mark meeting as no show?"
              style={{ fontSize: "var(--p1)" }}
            />

            <div
              style={{
                marginTop: "10px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                type=""
                text="Reason"
                fontWeight={600}
                style={{ fontSize: "var(--p1)" }}
              />
              <input
                placeholder="Enter Reason"
                style={{ fontSize: "var(--p1)" }}
                value={reason}
                maxLength={200}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button text="No" buttonType="secondary" onClick={onNoShowClose}>
              No
            </Button>
            <Button
              text="Yes"
              buttonType="primary"
              onClick={() => {
                updateInterviewStatus(selectedInterview.id, "no_show");
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </MaxWidthContainer>
  );
};

export default EvaluatorInterviewLayout;
