import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './AddJobReferral.scss'
import * as toast from "@/wrapper/toast";
import * as erros from '@/constants/stringConstants';
import * as ApiHelper from '@/services/apiHelper';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import ModalAddJobReferralSuccess from '@/components/Modals/ModalAddJobReferralSuccess';
import ModalReferralConfirm from '@/components/Modals/ModalReferralConfirm';
import Button from '../_widgets/Button/Button';
import Text from '../_widgets/Text/Text';
import * as commonServices from '@/services/common';
import Dropdown from '../_widgets/Dropdown/Dropdown';
import * as storageConstants from "@/constants/storageConstants";
import { isCompanyAdmin } from '../../utils/authUtil';
import Loader from '../_widgets/Loader/Loader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function AddJobReferral() {
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");

    let amountArray = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];

    const [referralNumber, setReferralNumber] = useState('');
    const [referralAmount, setReferralAmount] = useState(amountArray[0]);
    const [referralDuration, setReferralDuration] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState({});
    const [confirmModalInputs, setConfirmModalInputs] = useState({
        "isSkill": true,
        "isLocation": true,
        "isExperience": true,
        "otherRequirements": '',
    });

    let params = useParams();
    const job_id = params.job_id;

    const [masterData, setMasterData] = useState();

    useEffect(() => {
        const masterData = commonServices.getLocalData(storageConstants.DATA);
        setMasterData(masterData);
    }, [])

    const referralDayOptions = masterData?.referral_days?.map(referralDay => ({
        name: referralDay.name,
        id: parseInt(referralDay.value),
    })) || [{
        name: "1 Week",
        id: 7
    }];

    useEffect(() => {
        jobDetail();
    }, [])

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('action') === 'buy') {
            buyMoreCash();
        }
    }, []);

    const jobDetail = async () => {
        setLoading(true);
        await ApiHelper.getJobDetail(job_id).then((response) => {
            if (response.isSuccess === true) {
                setConfirmModalData(response.data);
            }
            else if (response.code === 400) {
                toast.warn(response.message);
                navigate('/access-denied');
            }
            else {
                toast.warn(response.message);
            }
            setLoading(false);
        });
    }

    const validateData = () => {
        let status = true;
        if (typeof job_id === 'undefined') {
            toast.warn(erros.SOMETHING_WENT_WRONG);
            status = false;
        } else if (referralNumber === '') {
            toast.warn(erros.REFERRAL_NUMBER_REQUIRED);
            status = false;
        } else if (referralNumber <= 0) {
            setReferralNumber(0);
            toast.warn(erros.REFERRAL_NUMBER_INVALID);
            status = false;
        } else if (referralNumber > 100) {
            toast.warn(erros.REFERRAL_NUMBER_LIMIT);
            status = false;
        } else if (referralAmount === '') {
            toast.warn(erros.REFERRAL_AMOUNT_REQUIRED);
            status = false;
        } else if (referralDuration === '') {
            toast.warn(erros.REFERRAL_DURATION_REQUIRED);
            status = false;
        }
        return status;
    }

    const boostJob = (e, action = 'confirm') => {
        e.preventDefault();
        if (validateData()) {
            if (action === 'confirm') {
                setShowConfirmModal(true);
                return;
            } else {
                let formData = new FormData();
                formData.append('job_id', job_id);
                formData.append('referral_number', referralNumber);
                formData.append('amount', referralAmount);
                formData.append('duration', referralDuration);
                formData.append('referralData', JSON.stringify(confirmModalInputs));

                setLoading(true);

                ApiHelper.boostJob(formData).then((response) => {
                    setLoading(false);
                    if (response.isSuccess === true) {
                        setShowSuccessModal(!showSuccessModal)
                    }
                    else {
                        toast.warn(response.message);
                    }
                })
            }
        }
    }

    const buyMoreCash = () => {
        if (isCompanyAdmin()) {
            navigate('/company/users', {
                state: {
                    showPaymentGateay: true
                }
            });
        } else {
            toast.warn("Ask your Company Admin for more TT Cash");
        }
    }

    const isButtonDisabled = () => {
        return confirmModalData?.user_remaining_amount < referralAmount * referralNumber
    }

    return (
        <div className="posted_jobs">
            <ModalAddJobReferralSuccess showModal={showSuccessModal} setShowModal={setShowSuccessModal} />
            <ModalReferralConfirm showModal={showConfirmModal} setShowModal={setShowConfirmModal} modalData={confirmModalData} boostJob={boostJob} confirmModalInputs={confirmModalInputs} setConfirmModalInputs={setConfirmModalInputs} />
            {loading ?
                <Loader /> : null}
            <div className="body_section tt-container">
                <div className='header'>
                    <div className='d-flex header_left'>
                        <Text type="h2" text=" Get Referrals"></Text>
                    </div>
                </div>
                <div className='row boost-job result_section mt-3'>
                    <div className='col-8 left'>
                        <form action="">
                            <div className="row mt-3">
                                <div className="col">
                                    <label className='required' htmlFor="">Number of referrals required</label>
                                    <div className="mb-3">
                                        <div className="input-group-prepend">
                                        </div>
                                        <input type="text" className="form-control" placeholder="Number of referrals required"
                                            value={commonServices.currencyFormatter(referralNumber)}
                                            name="referralNumber" onChange={(e) => {
                                                if (commonServices.isValidCurrency(commonServices.removeCommaFromAmount(e.target.value))) {
                                                    setReferralNumber(commonServices.removeCommaFromAmount(e.target.value))
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div className="col">
                                    <label className='required' htmlFor="">Amount for each successful referral</label>
                                    <select className="form-select form-select-lg gender-dropdown" name='duration' value={referralAmount} onChange={(e) => setReferralAmount(e.target.value)} >
                                        {amountArray !== null ? (
                                            <>
                                                {amountArray.map(
                                                    (amount, i) => {
                                                        return (
                                                            <option key={"amount_" + i} value={amount}>{amount}</option>
                                                        );
                                                    }
                                                )}
                                            </>
                                        ) : null}
                                    </select>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col">
                                    <Dropdown
                                        label='Referral Duration'
                                        name="duration"
                                        id="duration"
                                        isRequired={true}
                                        showDefaultOption={true}
                                        onChange={e => setReferralDuration(e.target.value)}
                                        select={referralDayOptions}
                                    />
                                </div>
                                <div className="col">
                                </div>
                            </div>
                            {isButtonDisabled() ?
                                <div className='cash-summary my-4 ps-0'>
                                    <h6 className='available-cash'>Total Available TT Cash: {commonServices.currencyFormatter(confirmModalData?.user_remaining_amount)}</h6>
                                    <div className='d-flex align-items-center gap-2'>
                                        <div className='w-auto unavailable-cash'>
                                            <h6 className='red-text'>You need {commonServices.currencyFormatter(referralAmount * referralNumber)} TT cash for boosting this job</h6>
                                        </div>
                                        <div>
                                            <a href="?action=buy" style={{ fontSize: "var(--link)", fontWeight: "500", color: "var(--green)", textDecoration: "underline", marginLeft: "10px" }}>Buy/Allocate</a>
                                            <a href={window.location.href} style={{ fontSize: "var(--link)", fontWeight: "500", color: "var(--green)", textDecoration: "underline", marginLeft: "10px" }}>Refresh</a>
                                        </div>
                                    </div>
                                </div>
                                : <div className='cash-summary my-4'>
                                    <div className='row'>
                                        <h6 className='available-cash'>Total Available TT Cash: {commonServices.currencyFormatter(confirmModalData?.user_remaining_amount)}</h6>
                                    </div>
                                    <div className="row">
                                        <h6 className='available-cash'>TT Cash you will spend in this Job Referral Boost: {commonServices.currencyFormatter(Number(referralNumber || 0) * Number(referralAmount || 0))}&nbsp;  </h6>
                                    </div>
                                </div>}
                            <div className="form_buttons mt-5 d-flex justify-content-end pe-4">
                                <Button buttonType="primary" text="Boost Now" disabled={isButtonDisabled()} type="submit" value="Boost Now" onClick={(e) => boostJob(e, 'confirm')} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddJobReferral;
