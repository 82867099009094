import React, { useEffect } from "react";
import "./BookTimeSlot.scss";
import TTCalendar from "@/components/Common/calendar/Calendar";
import { useState } from "react";
import { getEvaluationSlots } from "@/services/apiHelper";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as toast from "@/wrapper/toast";
import { changeDateFormat } from "@/utils/dateUtil";
import infoGreyIcon from "@/Assets/svgs/infoGreyIcon.svg";
import Loader from "../../../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

const BookTimeSlot = ({
  interviewSkill,
  isGetSlots,
  date,
  setDate,
  selectedSlot,
  setSelectedSlot,
}) => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);

  let [color] = useState("#000000");

  const getTimeSlots = async () => {
    try {
      let reqObj = {
        date: changeDateFormat(date, "YYYY-MM-DD"),
        skills: interviewSkill,
      };
      setLoading(true);
      const res = await getEvaluationSlots(reqObj);
      setTimeSlots([]);
      if (res.isSuccess) {
        setTimeSlots(res.data);
      } else {
        setTimeSlots([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTimeSlots();
  }, [date, isGetSlots]);

  const addSlot = (slot) => {
    setSelectedSlot(slot);
  };

  return (
    <>
      {loading ? (
       <Loader />
      ) : null}
      <div className="time_slot_wrapper d-flex flex-column flex-md-row ">
        <div className="calender">
          <TTCalendar date={date} onChange={setDate} />
        </div>
        <div className="available_time_slots">
          <h3 className="book_slot_heading mb-4">Available Time Slot</h3>
          {/* <ul
            class="pills-wrapper nav nav-pills mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item tab-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Morning slots
              </button>
            </li>
            <li class="nav-item tab-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Afternoon slots
              </button>
            </li>
          </ul> */}
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active available-slots-tab"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <div className="times_wrapper">
                {timeSlots && timeSlots?.slots ? (
                  [
                    ...timeSlots?.slots?.morning_slots,
                    ...timeSlots?.slots?.afternoon_slots,
                  ].map((slot, k) => {
                    return (
                      <div
                        key={"morning" + k}
                        className={
                          selectedSlot?.slot === slot?.slot
                            ? "time_label hand-hover active-slot"
                            : "time_label hand-hover"
                        }
                        onClick={() => addSlot(slot)}
                      >
                        {slot?.slot}
                      </div>
                    );
                  })
                ) : (
                  <div className="no-slot">
                    <img src={infoGreyIcon} alt="info" />
                    <p>
                      There is no availability on this date. Please choose
                      another date.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div className="times_wrapper">
                {timeSlots && timeSlots?.slots && timeSlots?.slots?.afternoon_slots.length > 0 ? timeSlots?.slots?.afternoon_slots.map((slot, k) => {

                  return <div key={"afternoon" + k} className={selectedSlot?.slot === slot?.slot ? "time_label hand-hover active-slot" : "time_label hand-hover"} onClick={() => addSlot(slot)}>{slot?.slot}</div>

                })
                  :
                  <div className="no-slot">
                    <img src={infoGreyIcon} alt="info" />
                    <p>There is no availability on this date. Please choose another date.</p>
                  </div>
                }
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BookTimeSlot;
