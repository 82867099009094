import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Text from "../_widgets/Text/Text";
import Button from "../_widgets/Button/Button";
import left_arrow from "@/Assets/images/arrow-left.svg";
import thank_you from "@/Assets/svgs/Thank_you.svg";
import become_front_page from "@/Assets/svgs/Becom_front_page.svg";
import { sendProRequest } from "@/services/apiHelper";
import * as toast from "@/wrapper/toast";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import doneCircle from "@/Assets/svgs/doneCircle.svg";
import { redirectBack } from "@/utils/redirectUtil";
import Loader from "../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

const BecomePro = ({ becomePro, setRefresh, isShowTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  let [color] = useState("#000000");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const skipWaitList = async () => {
    try {
      setLoading(true);
      const res = await sendProRequest();

      if (res.isSuccess) {
        onOpen();
        setRefresh((prev) => !prev);
      }
      if (res?.error) {
        toast.warn(res?.error);
      }
    } catch (error) {
      console.log(error.response);
      const errMsg = error?.response?.data?.message || "Something went wrong";
      toast.warn(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const backHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="get-evaluated_page tt-container">
        {loading ? (
          <Loader />
        ) : null}
        <div style={{ minHeight: "92vh" }}>
          <div
            style={{
              marginBottom: "2rem",
              padding: "0",
            }}
          >
            {isShowTitle === undefined || isShowTitle ? (
              <div className="d-flex align-items-center">
                <img
                  src={left_arrow}
                  alt=""
                  className="hand-hover"
                  onClick={() => redirectBack()}
                />
                <Text
                  type="h2"
                  text="Get Evaluated"
                  style={{
                    fontSize: "18px",
                    marginLeft: "10px",
                    fontWeight: 600,
                    marginBottom: "0",
                  }}
                />
              </div>
            ) : null}
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <div className="mt-5">
                <img src={become_front_page} alt="image" />
              </div>
              <Text
                type="h2"
                text="You are currently on our waitlist"
                style={{
                  padding: "1rem",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              />

              <Text
                type=""
                text="But you can skip the queue and get evaluated by using XX TT Cash"
                style={{
                  padding: "1rem",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#9398A1",
                }}
              />
            </div>
          </div>
          {isShowTitle === undefined || isShowTitle ? (
            <div
              style={{
                marginBottom: "2rem",
                padding: "12px",
                backgroundColor: "#F9F9F9",
              }}
            >
              <Text
                type="h2"
                text="Why you should get evaluated?"
                style={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  marginBottom: "14px",
                }}
              />

              <Text
                type=""
                text="Do you often find yourself engaging in multiple interview cycles, experiencing rejections, and receiving inadequate/constructive feedback from the interviewers?

  Undergo video assessment on TrueTalent conducted by an industry specialist. Gain valuable insights into your current strengths and growth areas through detailed feedback. Sidestep the initial rounds of interviews across multiple companies and instead, secure direct interviews with the decision-makers within these firms"
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#9398A1",
                }}
              />
            </div>
          ) : null}
          <div className="d-flex justify-content-center">
            <div>
              {becomePro?.invite === null ? (
                <>
                  <Button
                    buttonType="primary"
                    style={{ padding: "0.8rem 2rem", fontSize: "var(--p2)" }}
                    onClick={skipWaitList}
                    text="Skip the waitlist"
                  ></Button>
                  <Button
                    buttonType="primary"
                    style={{
                      padding: "0.8rem 2rem",
                      fontSize: "var(--p2)",
                      marginLeft: "10px",
                    }}
                    marginRight={"10px"}
                    onClick={() => backHome()}
                    text="Keep me in waitlist"
                  ></Button>
                </>
              ) : (
                <Button
                  buttonType="secondary"
                  className="btn tt-btn__secondary d-flex gap-1 align-items-center flex-row-reverse"
                  text="Request Sent"
                  buttonIcon={<img src={doneCircle} alt="image" />}
                ></Button>
              )}
            </div>
          </div>
          <Modal backdrop="static" show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="d-flex flex-column align-items-center gap-4">
                <img src={thank_you} alt="image" />
                <Text
                  type="h2"
                  text="Thank you for showing interest! "
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    marginTop: "6px",
                  }}
                />

                <Text
                  type=""
                  text="Our team will call you shortly"
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#9398A1",
                    marginTop: "6px",
                  }}
                />

                <Button
                  buttonType="primary"
                  style={{ padding: "1rem 3 rem" }}
                  onClick={() => backHome()}
                  text="Back to home"
                ></Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default BecomePro;
